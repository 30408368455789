import React, {useState} from "react";
import {createStructuredSelector} from "reselect";
import {connect} from "react-redux";
import FormInput from "../../component/form-input/form-input.component";
import {selectCurrentUser} from "../../redux/user/user.selector";
import {firestore} from "../../firebase/firebase.utils";
import Button from "@material-ui/core/Button";
import {isSuperAdmin} from "../../redux/user/user.utils";

const CreateTeam = ({currentUser}) => {
    const [ newTeamValues, setNewTeamValues ] = useState({
        name: '',
        fullName: '',
        city: '',
        sport: 'American Football',
        league: 'NFL',
        stadium: '',
        conference: '',
        conferenceDivision: '',
        colors: [],
    });


    const handleChange = (event) => {
        const { name, value } = event.target;
        setNewTeamValues({ ...newTeamValues, [name]: value});
    };

    const createTeam = async () => {
        let colors = newTeamValues.colors.split(",");
        let team = {
            ...newTeamValues,
            colors: colors,
        };
        const teamsRef = firestore.collection("teams");

       return teamsRef.add(team)
            // .then(docRef => {
            // console.log("team created with id: ", docRef.id);
            // console.log(docRef);
            // })
           .catch(error => {
            console.error("Error adding document: ", error);
        });
    };



    if(!currentUser){
        return (<div>
            <p>You have to log in first</p>
        </div>)
    }

    if(!isSuperAdmin(currentUser)){
        return (<div>
            <p>Unauthorized</p>
        </div>)
    }

    return (<div className="create-grid-form">
            <h2>Create a new team</h2>
            <div >
                <form onSubmit={createTeam}>
                    <FormInput label="team name" type="text" name="name" value={newTeamValues.name} handleChange={handleChange}/>
                    <FormInput label="team full name" type="text" name="fullName" value={newTeamValues.fullName} handleChange={handleChange}/>
                    <FormInput label="city" type="text" name="city" value={newTeamValues.city} handleChange={handleChange}/>
                    <FormInput label="stadium" type="text" name="stadium" value={newTeamValues.stadium} handleChange={handleChange}/>
                    <FormInput label="conference" type="text" name="conference" value={newTeamValues.conference} handleChange={handleChange}/>
                    <FormInput label="conferenceDivision" type="text" name="conferenceDivision" value={newTeamValues.conferenceDivision} handleChange={handleChange}/>
                    <FormInput label="colors" type="text" name="colors" value={newTeamValues.colors} handleChange={handleChange}/>

                </form>
                <Button color="inherit" style={{'background-color': 'lightslategrey'}} onClick={() => {createTeam().catch(error => console.error("error while creating a team", error));}}>Create Team</Button>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(CreateTeam);