import React, {useEffect, useState} from "react";
import {getTeams} from "../../firebase/firebase.utils";
import {GiAmericanFootballHelmet} from "react-icons/gi";

const Teams = () => {

    const [teams, setTeams] = useState([]);
    const [team1ColorIndex, setTeam1ColorIndex] = useState(1);
    const [team2ColorIndex, setTeam2ColorIndex] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            return await getTeams();
        };
        fetchData().then(teams => setTeams(teams));
    }, []);

    return (<div>
        <h1>Teams</h1>
        <div>
            Teams Go Here
            <div>
                {
                    teams.map((team) => {
                        return (<div>

                            <div>
                                {team.colors.filter(color => color.toLowerCase() !== "#ffffff").map(color => {
                                    return <GiAmericanFootballHelmet className="icon"
                                                              size="50px"
                                                              color={color}
                                                             />
                                })}
                                <h2><GiAmericanFootballHelmet className="icon"
                                                              size="50px"
                                                              color={team.colors[team1ColorIndex]}
                                                              onClick={() => {
                                                                  setTeam1ColorIndex((prevColorIndex)=> (prevColorIndex + 1)%team.colors.length);}}
                                                              />
                                    {team.fullName}
                                    <GiAmericanFootballHelmet className="icon"
                                                              style={{transform: "scaleX(-1)"}}
                                                              size="50px"
                                                              color={team.colors[team2ColorIndex]}
                                                              onClick={() => {
                                                                  setTeam2ColorIndex((prevColorIndex)=> (prevColorIndex + 1)%team.colors.length);}}
                                                              />
                                </h2>

                            </div>


                        <div>
                            <span>city: </span>
                            <span><strong>{team.city}</strong></span>
                        </div>
                        <div>
                            <span>stadium: </span>
                            <span><strong>{team.stadium}</strong></span>
                        </div>
                    </div>)})
                }
            </div>
        </div>
    </div>);
};

export default Teams;
