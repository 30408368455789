import UserActionTypes from "./user.types";

export const setCurrentUser = (user) => ({
    type: UserActionTypes.SET_CURRENT_USER,
    payload: user
});

export const googleSignInStart = () =>  ({
    type: UserActionTypes.GOOGLE_SIGN_IN_START,
});

export const googleSignInSuccess = user => ({
    type: UserActionTypes.GOOGLE_SIGN_IN_SUCCESS,
    payload: user,
});

export const googleSignInFailure = error => ({
    type: UserActionTypes.GOOGLE_SIGN_IN_FAILURE,
    payload: error
});


export const emailSignInStart = emailAndPassword =>  ({
    type: UserActionTypes.EMAIL_SIGN_IN_START,
    payload: emailAndPassword,
});

export const signInSuccess = user => ({
    type: UserActionTypes.SIGN_IN_SUCCESS,
    payload: user,
});

export const signInFailure = error => ({
    type: UserActionTypes.SIGN_IN_FAILURE,
    payload: error
});

export const sendResetPasswordEmail = userEmail => ({
    type: UserActionTypes.SEND_RESET_PASSWORD_EMAIL,
    payload: userEmail
});

export const sendResetPasswordEmailSuccess = () => ({
    type: UserActionTypes.SEND_RESET_PASSWORD_EMAIL_SUCCESS
});

export const sendResetPasswordEmailFailure = error => ({
    type: UserActionTypes.SEND_RESET_PASSWORD_EMAIL_FAILURE,
    payload: error,
});

export const checkUserSession = () => ({
    type: UserActionTypes.CHECK_USER_SESSION,
});


export const signOutStart = () => ({
    type: UserActionTypes.SIGN_OUT_START,
});

export const signOutSuccess = () => ({
    type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutFailure = (error) => ({
    type: UserActionTypes.SIGN_OUT_FAILURE,
    payload: error,
});

export const signUpStart = (userCredentials) => ({
    type: UserActionTypes.SIGN_UP_START,
    payload: userCredentials
});

export const signUpSuccess = ({user, additionalData}) => ({
    type: UserActionTypes.SIGN_UP_SUCCESS,
    payload: {user, additionalData}
});

export const signUpFailure = (error) => ({
    type: UserActionTypes.SIGN_UP_FAILURE,
    payload: error,
});

export const updateUserDetails = (currentUser, updateData) => ({
    type: UserActionTypes.UPDATE_USER_DETAILS,
    payload: {currentUser, updateData},
});

export const updateUserDetailsSuccess = () => ({
    type: UserActionTypes.UPDATE_USER_DETAILS_SUCCESS,
});

export const updateUserDetailsFailure = (errorMessage) => ({
    type: UserActionTypes.UPDATE_USER_DETAILS_FAILURE,
    payload: errorMessage,
});