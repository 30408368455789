import {createSelector} from "reselect";

export const selectGameReduxStore = state => state.game;

export const selectCurrentGame = createSelector(
    [selectGameReduxStore],
    game => game.currentGame
);

export const selectHomePageGrids = createSelector(
    [selectGameReduxStore],
    game => game.homePageGrids
);

export const selectHomePageGames = createSelector(
    [selectGameReduxStore],
    game => game.homePageGames
);

