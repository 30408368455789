import React, {useEffect} from 'react';
import './App.css';
import Header from "./component/header/header.component";
import {Redirect, Route, Switch} from "react-router";
import HomePage from "./pages/home/home.component";
import {connect} from "react-redux";
import GridPage from "./pages/grid/grid.component";
import SignInAndSignUpPage from "./pages/sign-in-and-sign-up/sign-in-and-sign-up.component";
import {checkUserSession} from "./redux/user/user.actions";
import MyAccountPage from "./pages/my-account/my-account.component";
import GridEditPage from "./pages/grid-edit/grid-edit.component";
import CreateGrid from "./pages/create-grid/create-grid.component";
import GridPlayersPage from "./pages/grid-players/grid-players";
import Container from "@material-ui/core/Container";
import CreateTeam from "./pages/create-team/create-team.component";
import Teams from "./pages/teams/teams.component";
import CreateGame from "./pages/create-game/create-game.component";
import SignUpPage from "./pages/sign-up/sign-up-page.component";
import SignInPage from "./pages/sign-in/sign-in-page.component";
import WeekGamesPage from "./pages/week-games/week-games.component";
import MyGridsPage from "./pages/my-grids/my-grids-page.component";
import GamePage from "./pages/game-page/game-page.component";
import ResetPasswordPage from "./pages/reset-password/reset-password";
import Pricing from "./pages/pricing/pricing";
import ContactUsPage from "./pages/contact-us/contact-us-page.component";

const App = ({currentUser, checkUserSession}) => {

    useEffect(() => {
        checkUserSession();
    }, [checkUserSession]);

    return (
    <div className="App">
      <Header/>
      <Switch>
          <Container maxWidth={false}>
              <Route exact path="/" component={HomePage} />
              {/*<Route exact path="/dashboard" component={Dashboard} />*/}
              <Route exact path="/pricing" component={Pricing} />
              <Route exact path="/contact" component={ContactUsPage} />
              <Route path="/sign-in" render={() => currentUser ? <Redirect to="/"/> : <SignInPage/>} />
              <Route path="/sign-up" render={() => currentUser ? <Redirect to="/"/> : <SignUpPage/>} />
              <Route path="/signin" render={() => currentUser ? <Redirect to="/"/> : <SignInAndSignUpPage/>} />
              <Route path="/reset-password" render={ () => currentUser ? <Redirect to={"/"}/> : <ResetPasswordPage/>} />
              <Route exact path={`/grid/:gridId`} component={GridPage} />
              {/*<Route exact path={`/grid/:gridId`} component={() => currentUser ? GridPage : <Redirect to="/sign-up"/>}/>*/}
              <Route exact path={`/grid/:gridId/edit`} component={GridEditPage}/>
              <Route exact path={`/grid/:gridId/players`} component={GridPlayersPage}/>
              <Route exact path={`/nfl/:year/week/:week`} component={WeekGamesPage}/>
              <Route exact path="/create-grid">
                  {currentUser ? <CreateGrid/> : <Redirect to={"/sign-up"}/>}
              </Route>
              <Route exact path="/teams" component={Teams}/>
              <Route exact path="/create-team" component={CreateTeam}/>
              <Route exact path={`/game/:gameId`} component={GamePage} />
              <Route exact path="/create-game">
                  <CreateGame/>
                  {/*    {currentUser ? <CreateGame/> : <Redirect to={"/sign-up"} />}*/}
              </Route>
              <Route exact path="/account" render={() => currentUser ? <MyAccountPage/> : <Redirect to="/sign-in"/>}/>
              <Route exact path="/my-grids" render={() => currentUser ? <MyGridsPage/> : <Redirect to="/sign-in"/>}/>
          </Container>
      </Switch>
    </div>
  );
};

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});

const mapDispatchToProps = dispatch => ({
    checkUserSession: () => dispatch(checkUserSession())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
