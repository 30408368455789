import React, {useState} from "react";
import FormInput from "../../component/form-input/form-input.component";
import CustomButton from "../../component/custom-button/custom-button.component";
import {sendResetPasswordEmail} from "../../firebase/firebase.utils";
import './reset-password.styles.scss';

const ResetPasswordPage = () => {

    const [email, setEmail] = useState('');
    // const [alertMessage, setAlertMessage] = useState('');
    const [resetPasswordEmailResponse, setResetPasswordEmailResponse] = useState({success: false, errorMessage: null});

    const handleSubmit = async (event) => {
        event.preventDefault();
        const {success, errorMessage} = await sendResetPasswordEmail(email);
        console.log("success: " +success);
        console.log("errorMessage: " +errorMessage);
        // setAlertMessage('An email has been sent to provided address with a link to reset your password');
        setResetPasswordEmailResponse({success: success, errorMessage: success ? 'An email has been sent to provided address with a link to reset your password' : errorMessage});
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEmail(value);
    };

    return (<div className="reset-password">
        <h2>I forgot my password</h2>
        <span>Enter your account's email address</span>
        <div className="reset-password-form">
            <form onSubmit={handleSubmit}>
                <FormInput label="email" type="email" name="email" value={email} handleChange={handleChange}/>

                <div className="buttons">
                    <CustomButton type="submit">
                        Reset Password
                    </CustomButton>
                </div>
        {resetPasswordEmailResponse.errorMessage != null ? <div className={`alert ${resetPasswordEmailResponse.success ? 'alert-success' : 'alert-danger'}`}><span className="closebtn"
                                                                                      onClick={() => setResetPasswordEmailResponse({success: true, errorMessage: null})}>&times;</span>{resetPasswordEmailResponse.errorMessage}</div> : null}
            </form>
        </div>
    </div>)
};

// const mapDispatchToProps = dispatch => ({
//     sendPasswordResetEmail: (email) => dispatch(sendResetPasswordEmail(email))
// });

export default (ResetPasswordPage);