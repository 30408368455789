import React, {useEffect, useState} from "react";
import FormInput from "../form-input/form-input.component";
// import {updateUserDetails} from "../../redux/user/user.actions";

import './account-settings.styles.scss';
import Button from "@material-ui/core/Button";
import {updateUserDetails} from "../../firebase/firebase.utils";

const AccountSettings = ({currentUser}) => {

    const [accountSettingsValues, setAccountSettingsValues] = useState({
        displayName: '',
    });

    const [isEditing, setIsEditing] = useState(false);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setAccountSettingsValues({...accountSettingsValues, [name]: value});
    };

    useEffect(() => {
        if(currentUser) {
            setAccountSettingsValues({
                // ...accountSettingsValues,
                displayName: currentUser.displayName
            });
        }
    }, [currentUser]);

    return (
        <div className='account-settings'>
            <h2>Settings</h2>
            {
                currentUser
                    ? (
                        <div>
                            <FormInput label="display name" type="text" name="displayName" disabled={!isEditing} value={accountSettingsValues.displayName} handleChange={handleChange}/>
                            <FormInput label="email" type="text" name="email" disabled={true} value={currentUser.email} handleChange={handleChange}/>
                        </div>
                    )
                    : null
            }
            {
                isEditing
                ? (<div className="update-cancel-buttons">
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    setAccountSettingsValues({...accountSettingsValues, displayName: currentUser.displayName});
                                    setIsEditing(false)
                                }
                            }>Cancel</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    const updateSuccessful = updateUserDetails(currentUser, accountSettingsValues);
                                    if(!updateSuccessful) setAccountSettingsValues({...accountSettingsValues, displayName: currentUser.displayName});
                                    setIsEditing(false);
                            }}>Update</Button>
                    </div>
                    )
                : <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsEditing(true)}>Edit</Button>
            }
        </div>
    )
};

// const mapDispatchToProps = dispatch => ({
//     updateUserDetails: (currentUser, updateData) => dispatch(updateUserDetails(currentUser, updateData)),
// });

// export default connect(null, mapDispatchToProps)(AccountSettings);
export default AccountSettings;