import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {assert} from "@firebase/util";

const config = {
    apiKey: "AIzaSyBqs5-DpzbCgqUSRhE1FdkUDGUoHJmHMoQ",
    authDomain: "super-bowl-squared.firebaseapp.com",
    databaseURL: "https://super-bowl-squared.firebaseio.com",
    projectId: "super-bowl-squared",
    storageBucket: "super-bowl-squared.appspot.com",
    messagingSenderId: "13972576773",
    appId: "1:13972576773:web:0e988249296441fdd4da6c",
    measurementId: "G-G3YVHQDGQ5"
};


firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const createUserProfileDocument = async (userAuth, additionalData) => {
    // return if no user authenticated
    if(!userAuth) {
        return;
    }
    // query firebase for the user object
    // get a query reference if asking for info or snapshot when asking for the data
    const userRef = firestore.doc(`users/${userAuth.uid}`);
    const snapshot = await userRef.get();
    // save the user to the db if it doesn't exist yet
    if(!snapshot.exists){
        const { displayName, email} = userAuth;
        const createdAt = new Date();
        // save
        try{
            await userRef.set({
                displayName,
                email,
                createdAt,
                ...additionalData
            });
        }catch (error) {
            console.error("an error occurred while saving the user " + userAuth.uid + " to the database", error.message);
        }
    }
    return userRef;
};

export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(userAuth => {
            unsubscribe();
            resolve(userAuth);
        }, reject);
    });
};

export const sendResetPasswordEmail = async (userEmail) => {
    try{
        console.log("sending password reset email for " + userEmail);
        await auth.sendPasswordResetEmail(userEmail);
        return {success:true, errorMessage: null};
    }catch(error){
        return {success:false, errorMessage: error.message};
    }
};

//todo replace using this method with 2 actions? (getGrid + updateGrid)
export const fetchAndUpdateGridById = (gridId, updateGrid) => {
    // console.log("getting grid");
    return firestore.collection("grids").doc(gridId).onSnapshot(async snapshot => {
        const gridWithId = snapshot.data();
        // console.log("got the grid:");
        // console.log(gridWithId);
        if(!gridWithId){
            return gridWithId;
        }
        gridWithId.id = gridId;
        updateGrid(gridWithId);
        return gridWithId;
    });
};

export const getGridById = async (gridId) => {
    // console.log(`getting grid ${gridId}`);
    return firestore.collection("grids").doc(gridId).get().then(async snapshot => {
        const gridWithId = snapshot.data();
        // console.log("got the grid:");
        // console.log(gridWithId);
        if (!gridWithId) {
            return gridWithId;
        }
        gridWithId.id = gridId;
        return gridWithId;
    });
};

export const updateGrid = async (gridId, newValues) => {
    return firestore.collection("grids").doc(gridId).update(newValues);
};

export const getUserPayments = (userId) => {
    return firestore.collection("users")
        .doc(userId)
        .collection("payments")
        .get()
        .then(snapshot => {
            return snapshot.docs.map(doc => {
                // console.log(documentData);
                return doc.data();
            });
        });
};

export const addInvitedPlayerToGrid = (gridId, invitedPlayer) => {
    console.log(`inviting player name: ${invitedPlayer.displayName} email: ${invitedPlayer.email}`);
    firestore.collection("grids").doc(gridId)
        .update({
            invitedPlayers: firebase.firestore.FieldValue.arrayUnion(invitedPlayer)
        }).then(() => {
        // console.log("user has been invited successfully");
    })
        .catch(error => {
            console.error("error while inviting a user", error);
        });
};

export const markGridAsPaid = (gridId) => {
    firestore.collection("grids").doc(gridId)
        .update({
            isPaid: true,
        }).then(() => {
            console.log("grid updated");
    }).catch(error => {
        console.error("error while updating a grid", error);
    });
};

export const getGamesByWeek = (season, week, league) => {
    // assert('text' == typeof (season), "`year` should be a number");
    assert('number' == typeof (week), "`week` should be a number");
    const collectionRef = firestore.collection("games");
    const q = collectionRef
        .where("league", "==", league)
        .where("season", "==", season)
        .where("week", "==", week);
    return q.get().then(snapshots => {
        return snapshots.docs.map(gameDoc => {
            const result = gameDoc.data();
            result.id = gameDoc.id;
            return result;
        });
    });
};

export const getAllNFLGamesBySeason = (season = "2023/2024") => {
    return getAllGamesBySeasonForLeague(season, "NFL");
}
export const getAllGamesBySeasonForLeague = (season = "2023/2024", league = "NFL") => {
    // assert('number' == typeof (year), "`year` should be a number");
    const collectionRef = firestore.collection("games");
    const q = collectionRef
        .where("league", "==", league)
        .where("season", "==", season);
    return q.get().then(snapshots => {
        return snapshots.docs.map(gameDoc => gameDoc.data());
    });
};

export const getGameByWeekAndTeams = async (year, week, team1, team2) => {
    assert('number' == typeof (year), "`year` should be a number");
    assert('number' == typeof (week), "`week` should be a number");
    return await firestore.collection("games")
        .where("year", "==", year)
        .where("week", "==", week)
        .where("team1.name", "==", team1)
        .where("team2.name", "==", team2)
        .get()
        .then((snapshot) => {
            if (snapshot.empty) return [];
            return snapshot.docs.map(doc => doc.data());
        })
};

export const updateGame = async (gameId, newValues) => {
    if(!gameId){
        console.error("error updating the game");
        return false;
    }
    // console.log(`updating game ${gameId} with values ${newValues}`);
    // console.log({gameId});
    // console.log({newValues});
    await firestore.collection("games").doc(gameId).update(newValues);
    return true;
};

export const getGameById = async (gameId) => {
    // console.log(`fetching game by id: {gameId}`);
  return await firestore.collection("games").doc(gameId).get().then(game => {
      let gameData = game.data();
      gameData.id = game.id;
      return gameData;
  })
};

//TODO move out of this class
export const getTeams = async () => {
    let teams = [];
    await firestore.collection("teams").get().then(async snapshot => {
        if (!snapshot.empty) {
            snapshot.docs.map(elm => teams.push(elm.data()));
        }
    });
    return teams;
};

export const getFeaturedGrids = async () => {
    let grids = [];
    await firestore.collection("grids")
        .where("featured", "==", true)
        .where("adminUserId", "in", ["3WoH6XdLzkehQRimSmIPzPEcj6F3","uUPNjGzJg1b5ZPtI3g6KdtfTN6C2"])
        .get()
        .then(snapshot => {
            snapshot.docs.forEach(gridDoc => {
                let grid = gridDoc.data();
                grid.id = gridDoc.id;
                grids.push(grid);
            });
        });
    return grids;
};

export const fetchAndUpdateUserGrids = async (userId, updateUserGrids) => {
    const collectionRef = firestore.collection("grids");
    // const query = collectionRef.where("players", "array-contains", userId);
    const query = collectionRef.where("adminUserId", "==", userId);
    return query.onSnapshot(async snapshot => {
            const grids = snapshot.docs
                .map(doc => {
                    const result = doc.data();
                    result.id = doc.id;
                    return result;
                });
            updateUserGrids(grids);
        });
};

export const updateUserDetails = (currentUser, updateData) => {
    if(!updateData.displayName || updateData.displayName.trim().length===0){
        alert("user name cannot be empty");
        return false;
    }
    try{
        firestore.collection("users").doc(currentUser.id)
            .update(updateData)
            .then(() => {
                // console.log("Update user data successful")
            }).catch(error => {
            throw error;
        });
        return true;
    }catch (e) {
        return false;
    }
};

//TODO delete
// export const listAvailableProducts = () => {
//     console.log("listing available products");
//     firestore.collection('products')
//         .where('active', '==', true)
//         .get()
//         .then(function (querySnapshot) {
//             querySnapshot.forEach(async function (doc) {
//                 console.log(doc.id, ' => ', doc.data());
//                 const priceSnap = await doc.ref.collection('prices').get();
//                 priceSnap.docs.forEach((doc) => {
//                     console.log(doc.id, ' => ', doc.data());
//                 });
//             });
//         });
// };

// TODO get stripe keys from environment variables
// TODO DON'T FORGET TO CHANGE IN FIREBASE FUNCTIONS AS WELL
const stripeGridProductId = "price_1M1GjRL5O0WbJ7ifh5XyBDOH";
// const testStripeGridProductId = "price_1M1HqqL5O0WbJ7ifxh0tEfqU";
// const testPromotionCode = "promo_1M1IGnL5O0WbJ7ifwdbGkYF3";
//const earlyBirdPromotionCode = "promo_1Nq7wdL5O0WbJ7if7QkGDnQp";
const earlyBirdPromotionCode = "promo_1OWORrL5O0WbJ7ifAq7rkE1j";
// const devPromotionCode = "promo_1OWOQQL5O0WbJ7ifkKmAmHFU"

export const createCheckoutSessionForGridAndNavigateToUrl = async (userId, gridId, setIsLoading) => {
    // console.log(`createCheckoutSessionForGridAndNavigateToUrl with userId ${userId} , gridId: ${gridId}`);
    const docRef = await firestore
        .collection('users')
        .doc(userId)
        .collection("checkout_sessions")
        .add({
            mode: "payment",
            price: stripeGridProductId, // One-time price created in Stripe
            success_url: `${window.location.origin}/grid/${gridId}`,
            cancel_url: `${window.location.origin}/grid/${gridId}`,
            allow_promotion_codes: true,
            promotion_code: earlyBirdPromotionCode,
            metadata: {
                gridId: gridId,
            },
        });

    //TODO - in the page calling this method useEffect to load data before payment button is clicked

    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
        const {error, url, created} = snap.data();
        let dateCreated = created ? created.toDate() : null;
        if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            // alert(`An error occured: ${error.message}`);
            setIsLoading(false);
            alert(`Could not complete the checkout due to an unexpected error - please reach out to contact@boomhand.com for assistance.
                \nerror message: ${error.message}`);
        }
        if (url) {
            // console.log(`updating grid ${gridId} with stripeCheckoutUrl: ${url} stripeSessionCreationDate: ${dateCreated}`);
            firestore.collection("grid").doc(gridId).update({
                stripeCheckoutUrl: url,
                stripeSessionCreationDate: dateCreated,
            });
            setIsLoading(false);
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
        }
    });
};

export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
googleAuthProvider.setCustomParameters({prompt: 'select_account'});

export const signInWithGoogle = () => auth.signInWithPopup(googleAuthProvider);

export default firebase;
