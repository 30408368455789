import React from 'react';

import './contact-us-page.styles.scss';
import ContactUs from "../../component/contact-us/contact-us.component";

const ContactUsPage = () => {

    return (<div>
        <ContactUs />
    </div>);
};

export default ContactUsPage;

