import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import FormInput from "../../component/form-input/form-input.component";
import Snackbar from "@material-ui/core/Snackbar";
import CustomButton from "../../component/custom-button/custom-button.component";
import MuiAlert from "@material-ui/lab/Alert";
import {getGameById, updateGame} from "../../firebase/firebase.utils";
import ScoreBoard from "../../component/score-board/score-board.component";
import {getTeamLogo} from "../../utils";

const GamePage = ({match}) => {
    const [snackbarAlertOpen, setSnackbarAlertOpen] = useState(false);
    const [formSubmitMessages, setFormSubmitMessages] = useState({
        successMessage: null,
        errorMessage: null,
    });
    const [game, setGame] = useState();

    const [ newGameValues, setNewGameValues ] = useState({
        rowTeamScores: "",
        columnTeamScores: "",
    });

    useEffect(() => {
        if(!game){
            getGameById(match.params.gameId).then(game =>{
                setGame(game);
                let rowTeamScoresFromGame = (game.rowTeamScores)?game.rowTeamScores.join(','):"";
                let columnTeamScoresFromGame = (game.columnTeamScores)?game.columnTeamScores.join(','):"";
                setNewGameValues({
                    ...newGameValues,
                    rowTeamScores: rowTeamScoresFromGame,
                    columnTeamScores: columnTeamScoresFromGame,
                    day: game.day,
                    month: game.month,
                    hour: game.hour,
                    minute: game.minute,
                })
            })
        }
    }, [match.params.gameId, game, newGameValues]);

    const handleUpdateGame = async () => {
        let rowTeamScoresArr = newGameValues.rowTeamScores.includes(",") ? newGameValues.rowTeamScores.split(",") : newGameValues.rowTeamScores.trim().split(" ").filter((scoreStr) => scoreStr !== '');
        let columnTeamScoresArr = newGameValues.columnTeamScores.includes(",") ? newGameValues.columnTeamScores.split(",") : newGameValues.columnTeamScores.trim().split(" ").filter((scoreStr) => scoreStr !== '');
        let rowTeamScores = rowTeamScoresArr.map(scoreStr => parseInt(scoreStr));
        let columnTeamScores = columnTeamScoresArr.map(scoreStr => parseInt(scoreStr));
        let newValues = {}
        let scoresChanged = false;
        if(rowTeamScores.length > 0 && !isNaN(rowTeamScores[0]) && columnTeamScores.length > 0 && !isNaN(columnTeamScores[0])){
            newValues = {...newValues, rowTeamScores: rowTeamScores, columnTeamScores: columnTeamScores};
            scoresChanged = true;
        }
        let startTimeChanged = false;
        if(newGameValues.month !== game.month){
            newValues = {...newValues, month: newGameValues.month,};
            startTimeChanged = true;
        }
        if(newGameValues.day !== game.day){
            newValues = {...newValues, day: newGameValues.day,};
            startTimeChanged = true;
        }
        if(newGameValues.hour !== game.hour){
            newValues = {...newValues, hour: newGameValues.hour,};
            startTimeChanged = true;
        }
        if(newGameValues.minute !== game.minute){
            newValues = {...newValues, minute: newGameValues.minute,};
            startTimeChanged = true;
        }
        if(startTimeChanged){
            let startTime = new Date(game.year, newGameValues.month-1, newGameValues.day, newGameValues.hour, newGameValues.minute, 0);
            newValues = {...newValues, startTime: startTime};
            // newValues = {...newValues, startTime: new Date(startTime.getTime() + (startTime.getTimezoneOffset()*60*1000))}
        }
        if(scoresChanged || startTimeChanged){
            await updateGame(game.id, newValues);
        }
        setSnackbarAlertOpen(true);
    };

    const handleSnackbarAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarAlertOpen(false);
        setFormSubmitMessages({
            errorMessage: null,
            successMessage: null
        });
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        if(["day", "hour", "minute", "week"].includes(name)){
            setNewGameValues({ ...newGameValues, [name]: parseInt(value)});
        }else{
            setNewGameValues({ ...newGameValues, [name]: value});
        }
    };

    return (<div>
        {game
            ? (<div className="create-grid-form">
                <h2>Update the game</h2>
                <div >
                    <form onSubmit={()=>{}}>
                    {/*<form onSubmit={handleUpdateGame()}>*/}
                        <FormInput label="season" type="text" disabled={true} name="season" value={newGameValues.season} />
                        <FormInput label="Team 1 (visitors):" value={game.team1.fullName} disabled={true}/>
                        <FormInput label="Team 2 (hosts):" value={game.team2.fullName} disabled={true}/>
                        <FormInput label="month" type="number" name="month" value={newGameValues.month} handleChange={handleChange}/>
                        <FormInput label="day" type="number" name="day" value={newGameValues.day} handleChange={handleChange}/>
                        <FormInput label="hour" type="number" name="hour" value={newGameValues.hour} handleChange={handleChange}/>
                        <FormInput label="minute" type="number" name="minute" value={newGameValues.minute} handleChange={handleChange}/>
                        <FormInput label="Row Team Scores" type="text" name="rowTeamScores" value={newGameValues.rowTeamScores} handleChange={handleChange}/>
                        <FormInput label="Column Team Scores" type="text" name="columnTeamScores" value={newGameValues.columnTeamScores} handleChange={handleChange}/>
                        {/*<FormInput label="amazonWatchLink" type="text" name="amazonWatchLink" value={newGameValues.amazonWatchLink} handleChange={handleChange}/>*/}
                        <div className="buttons">
                            <div className="form-submit-message">
                                <span className="form-submit-error-message">{formSubmitMessages.errorMessage}</span>
                                <span className="form-submit-success-message">{formSubmitMessages.successMessage}</span>
                            </div>
                            <Snackbar open={snackbarAlertOpen} autoHideDuration={6000} onClose={handleSnackbarAlertClose}>
                                <Alert onClose={handleSnackbarAlertClose} severity={`${formSubmitMessages.errorMessage ? 'error' : 'success'}`}>
                                    {formSubmitMessages.errorMessage ? formSubmitMessages.errorMessage : formSubmitMessages.successMessage}
                                </Alert>
                            </Snackbar>
                            {/*<CustomButton type="submit" disabled={false}>*/}
                            {/*    Update Game*/}
                            {/*</CustomButton>*/}
                        </div>
                    </form>
                    <div>
                        <img alt={game.team1.fullName} height={40} width={40} src={`${getTeamLogo(game.team1.fullName, game.league)}`}/>
                        <img alt={game.team2.fullName} height={40} width={40} src={`${getTeamLogo(game.team2.fullName, game.league)}`}/>
                    </div>
                    {(game.rowTeamScores && game.columnTeamScores) ? <ScoreBoard rowTeamName={game.team1.name} rowTeamScores={game.rowTeamScores} columnTeamName={game.team2.name} columnTeamScores={game.columnTeamScores}/> : null}
                    <CustomButton onClick={() => handleUpdateGame()}>
                        Update the Game
                    </CustomButton>
                    {/*<Button color="inherit" style={{'background-color': 'lightslategrey'}} onClick={() => {createGame().then(r => console.log("ok"));}}>Create Game</Button>*/}
                </div>
            </div>)
            : <span>NO GAME</span>}
    </div>);
};

export default withRouter(GamePage);
