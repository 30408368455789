import React, {useEffect, useState} from "react";
import {createStructuredSelector} from "reselect";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import FormInput from "../../component/form-input/form-input.component";
import CustomButton from "../../component/custom-button/custom-button.component";
import {selectCurrentUser} from "../../redux/user/user.selector";
import {firestore, getAllGamesBySeasonForLeague} from "../../firebase/firebase.utils";
import {selectCurrentGame} from "../../redux/game/game.selectors";
import FormSelect from "../../component/form-select/form-select.component";
import {
    getCurrentNFLSeasonStr,
    getCurrentNFLYear,
    getCurrentWeek,
    getNumberOfWeeks,
    getWeekName,
    range
} from "../../utils";
import {MAX_NUMBER_OF_PICKS_PER_USER} from "../../redux/grid/grid.utils";
import TagManager from 'react-gtm-module'
function getGameName(game) {
    return `${game.team1.name} at ${game.team2.name}`;
}

function getGameNameWithDate(game) {
    if(game){
        let gameDate = game.startTime ? game.startTime.toDate() : null;
        return getGameName(game) + `${gameDate ? (" - " + gameDate.toDateString()): ""}`;
    }else{
        return "";
    }
}

const tagManagerArgs = {
    dataLayer: {
        userId: '001',
        userProject: 'project',
        page: 'create-grid'
    },
    dataLayerName: 'PageDataLayer'
}

const CreateGrid = ({currentUser, currentGame, history}) => {
    TagManager.dataLayer(tagManagerArgs);

    let league = 'NFL';
    let [selectedGame, setSelectedGame] = useState(currentGame);
    if(selectedGame){
        league = selectedGame.league;
    }

    const currentWeek = getCurrentWeek(league);

    let [ availableWeeks, setAvailableWeeks ] = useState([]);
    useEffect(() => {
        let filteredWeeks = range(getNumberOfWeeks(league)).filter(week => week >= currentWeek);
        setAvailableWeeks(filteredWeeks);
    }, [league, currentWeek]);

    const [ newGridValues, setNewGridValues ] = useState({
        name: 'Team A vs. Team B',
        password: '',
        contactEmail: '',
        costPerSquare: 10,
        rowTeamName: 'Team A',
        columnTeamName: 'Team B',
        year: getCurrentNFLYear(),
        week: currentWeek,
        gameId: null,
        gameName: '',
        league: ''
    });

    let [allGames, setAllGames] = useState([]);

    useEffect(() => {
        async function fetchAllGamesData(){
            let result = await getAllGamesBySeasonForLeague(getCurrentNFLSeasonStr(), league);
            setAllGames(result);
        }
        fetchAllGamesData().catch((error) => console.error("error while fetching games", error));
    },[]);

    useEffect(() => {
        let newValues = {};
        if(currentUser){
            newValues = {...newValues, contactEmail: currentUser.email};
        }
        if(selectedGame){
            let gameName = getGameNameWithDate(selectedGame);
            let gameId = selectedGame.id;//TODO does selectedGame include an id?
            let ownerStr = (currentUser.displayName !== null && currentUser.displayName.trim().length>0) ? currentUser.displayName + "'s" : "My";
            newValues = {...newValues,
                rowTeamName: selectedGame.team1 ? selectedGame.team1.name : "",
                columnTeamName: selectedGame.team2 ? selectedGame.team2.name : "",
                name: ownerStr + " Grid Pool for " +gameName,
                year: selectedGame.year,
                week: selectedGame.week,
                gameName: gameName,
                gameId: gameId,
                league: league ? league : '',
            };
        }
        console.log("setting new grid values");
        setNewGridValues({...newGridValues, ...newValues});
        console.log("setting new grid values - done" );
    }, [currentUser, selectedGame, currentGame]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setNewGridValues({ ...newGridValues, [name]: value});
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const gridsRef = firestore.collection("grids");

        //create a new grid newGridValues on top of the default values (to handle missing data)
        gridsRef.add({
            adminUserId: currentUser.id,
            adminUserEmail: currentUser.email,
            costPerSquare: 10,
            maxPicksPerUser: MAX_NUMBER_OF_PICKS_PER_USER,
            dateCreated: new Date(),
            isStarted: false,
            isOver: false,
            picks: {},
            players: [currentUser.email],
            invitedPlayers: [],
            rowNumbers: [],
            columnNumbers: [],
            rowTeamName: "",
            columnTeamName: "",
            rowTeamScores: [],
            columnTeamScores: [],
            gameName: "Football Game",
            showSquareNumbers: false,
            league: '',
            ...newGridValues, // overrides default values with user input
        }).then(docRef => {
            // console.log("grid created with id: ", docRef.id);
            history.push(`/grid/${docRef.id}`);
        }).catch(error => {
            console.error("Error adding document: ", error);
        });

        setNewGridValues({name: '', password: ''});
    };

    const handleSelectWeek = (event) => {
        // eslint-disable-next-line no-unused-vars
        const { name, value } = event.target;
        let newWeek = parseInt(value);
        setNewGridValues({ ...newGridValues,
            week: newWeek,
            name: '',
            gameName: '',
            rowTeamName: '',
            columnTeamName: '',
        });
    };

    const handleSelectGame = (event) => {
        // eslint-disable-next-line no-unused-vars
        const { name, value } = event.target;
        let gamesWithSameNameAsSelected = allGames.filter(game => getGameNameWithDate(game) === value);
        if(gamesWithSameNameAsSelected.length === 0){
            let newRowTeamName = '';
            let newColumnTeamName = '';
            setNewGridValues({ ...newGridValues,
                name: value,
                gameName: value,
                rowTeamName: newRowTeamName,
                columnTeamName: newColumnTeamName,
            });
        }
        gamesWithSameNameAsSelected.forEach(game => {
            // console.log("selecting game:");
            // console.log(game);
            let newRowTeamName = game.team1.name;
            let newColumnTeamName = game.team2.name;
            setNewGridValues({ ...newGridValues,
                name: value,
                gameName: value,
                rowTeamName: newRowTeamName,
                columnTeamName: newColumnTeamName,
            });
        });
    };

    if(!currentUser){
        return (<div>
            <p>You have to log in first</p>
        </div>)
    }

    return (<div className="create-grid-form">
            <h2>Create a new grid</h2>
            <form onSubmit={handleSubmit}>
                <FormSelect label="Week" value={newGridValues.week} handleChange={handleSelectWeek} options={availableWeeks.map(week => {return {value: week, name: getWeekName(week, league)}})} />
                <FormSelect label="Game" value={newGridValues.gameName} disabled={false} handleChange={handleSelectGame} options={[{name: '', week: newGridValues.week}, ...allGames].filter(game => newGridValues.week === game.week).map(game => {return (game.name !== '') ? {value: getGameNameWithDate(game), name: getGameName(game)} : {value: '', name: ''}})} />
                <FormInput label="grid name" type="text" name="name" value={newGridValues.name} handleChange={handleChange}/>
                <FormInput label="game watched" type="text" name="game" value={newGridValues.gameName} disabled={true} handleChange={handleChange}/>
                <FormInput label="Team A" type="text" name="team1" value={newGridValues.rowTeamName} handleChange={handleChange}/>
                <FormInput label="Team B" type="text" name="team2" value={newGridValues.columnTeamName} handleChange={handleChange}/>
                <FormInput label="contact email" type="text" name="contactEmail" value={newGridValues.contactEmail} handleChange={handleChange}/>
                <FormInput label="cost per square ($)" type="number" name="costPerSquare" value={newGridValues.costPerSquare} handleChange={handleChange}/>

                {/*<FormInput label="password" type="text" name="password" value={newGridValues.password} handleChange={handleChange}/>*/}
                <div className="buttons">
                    <CustomButton id={"create-grid-button"} type="submit">
                        Create
                    </CustomButton>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    currentGame: selectCurrentGame,
});

export default withRouter(connect(mapStateToProps)(CreateGrid));
