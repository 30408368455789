import React, {useEffect, useState} from "react";
import SquareTable from "../../component/square-table/square-table.component";
import {selectGrid, selectGridPlayers, selectInvitedPlayers} from "../../redux/grid/grid.selectors";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {fetchAndUpdateGridById} from "../../firebase/firebase.utils";
import {markAllPlayerSquaresPaidStatus, updateGrid, updateGridPlayerName} from "../../redux/grid/grid.actions";
import ScoreBoard from "../../component/score-board/score-board.component";
import SquareWinners from "../../component/square-winners/square-winners.component";
import GridControlBoard from "../../component/grid-control-board/grid-control-board.component";
import {selectCurrentUser} from "../../redux/user/user.selector";
import {deduplicateUsers, userIsGridAdmin} from "../../redux/user/user.utils";
import {withRouter} from "react-router";
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import GroupIcon from '@material-ui/icons/Group';
import {
    getNumberOfPaidPicksByUser,
    getNumberOfPicksByUser,
    getPlayersFromGrid,
    isEmailInvitedOrPlayer
} from "../../redux/grid/grid.utils";
import GridPayment from "../../component/grid-payment/grid-payment.component";
import {containsSpecialCharacters} from "../../utils";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import UsersIcon from '@mui/icons-material/People'
import RulesIcon from '@mui/icons-material/Gavel'
import GridIcon from '@mui/icons-material/GridOn'
import ShowAllIcon from '@mui/icons-material/Preview'
import WinnersIcon from '@mui/icons-material/EmojiEvents'
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {SpinnerContainer, SpinnerOverlay} from "../../component/with-spinner/with-spinner.styles";
import Button from "@material-ui/core/Button";

const GridPage = ({updateGrid, match, grid, invitedPlayers, currentUser, updateScore, history, changeUserSquaresPaidStatus, updateGridPlayerName}) => {

    const [players, setPlayers] = useState([]);
    const [allPlayers, setAllPlayers] = useState([]);
    const isAdmin = userIsGridAdmin(currentUser, grid);
    const [isEditingNameOnGrid, setIsEditingNameOnGrid] = useState(false);
    const [playerNameOnGrid, setPlayerNameOnGrid] = useState("");
    const [playerNameOnGridInput, setPlayerNameOnGridInput] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const [value, setValue] = useState(2);

    useEffect(() => {
            return fetchAndUpdateGridById(match.params.gridId, updateGrid);
    }, [match.params.gridId, updateGrid]);

    useEffect(()=>{
        if(grid && currentUser && players){
            const playersWithCurrentUserEmail = players.filter(player => player.email === currentUser.email);
            if(playersWithCurrentUserEmail.length===0){
                // console.log(`No player with the same email found ${currentUser.email}`);
            }else{
                // console.log('playersWithCurrentUserEmail: ');
                // console.log(playersWithCurrentUserEmail);
                setPlayerNameOnGrid(playersWithCurrentUserEmail[0].displayName);
                setPlayerNameOnGridInput(playersWithCurrentUserEmail[0].displayName);
            }
        }
    }, [grid,currentUser, players]);

    useEffect(() => {
        if(grid){
            let playersFromGrid = getPlayersFromGrid(grid);
            setPlayers((playersFromGrid));
            setIsLoading(false);
        }

    }, [grid]);

    useEffect(() => {
        if(grid){
            setAllPlayers(deduplicateUsers([...players, ...invitedPlayers]));
        }
    }, [grid, players, invitedPlayers]);


    const handleDiplayNameInputChange = (event) => {
        const {name, value} = event.target;
        setPlayerNameOnGridInput(value)
    };

    const updateNameOnGrid = (player, newName) => {
        newName = newName.trim();
        if(newName.length === 0){
            setPlayerNameOnGridInput(playerNameOnGrid);
            alert("This field cannot be empty");
            return;
        }
        if(newName.length > 33){
            setPlayerNameOnGridInput(playerNameOnGrid);
            alert("Too long, try using a shorter text");
            return;
        }
        if(containsSpecialCharacters(newName)){
            setPlayerNameOnGridInput(playerNameOnGrid);
            alert("Cannot contain special characters");
            return;
        }
        updateGridPlayerName(grid, player, newName);
    };

    function markAllPlayerPicksAsPaidStatus(player, paidStatus){
        changeUserSquaresPaidStatus(grid.id, player, currentUser, paidStatus);
    }

    function isCurrentUser(player) {
        return currentUser && currentUser.email === player.email;
    }

    function getEditNameIconButton() {
        return <IconButton aria-label="edit" onClick={() => {
            setIsEditingNameOnGrid(true)
        }}>
            <EditIcon/>
        </IconButton>;
    }

    function getConfirmCancelNameEditIconButtons(player) {
        return <div><IconButton aria-label="done" onClick={() => {
            let playersWithSameEmailOrIdInGrid = players.filter(pl => pl.email === player.email || pl.id === player.id);
            let playerNameInGridOrDefault = playersWithSameEmailOrIdInGrid.length > 0 ? playersWithSameEmailOrIdInGrid[0].displayName : player.displayName ;
            if(playerNameOnGridInput !== playerNameInGridOrDefault){
                updateNameOnGrid(player, playerNameOnGridInput);
            }else{
                // console.log("not updating the user name");
            }
            setIsEditingNameOnGrid(false);
        }}>
            <DoneIcon/>
        </IconButton>
            <IconButton aria-label="cancel" onClick={() => {
                setPlayerNameOnGridInput(playerNameOnGrid);
                setIsEditingNameOnGrid(false);
            }}>
                <CancelIcon/>
            </IconButton>
        </div>
    }

    function getEditNameOnGridInputField() {
        return <input type={"text"} value={playerNameOnGridInput}
                      onChange={(event) => handleDiplayNameInputChange(event)}/>;
    }

    function getGridnameEditingIcons(player) {
        return isCurrentUser(player) ? (isEditingNameOnGrid ? getConfirmCancelNameEditIconButtons(player) : getEditNameIconButton()) : null;
    }

    function getPlayerTableRow(player) {
        let numberOfPaidPicksByUser = getNumberOfPaidPicksByUser(grid, player);
        let numberOfPicksByUser = getNumberOfPicksByUser(grid, player);
        let costPerSquare = grid.costPerSquare;
        let isAllPaidByPlayer = (numberOfPicksByUser === numberOfPaidPicksByUser);
        return <tr key={`td-player-name-${player.email}`}>
            <td>{(isEditingNameOnGrid && isCurrentUser(player)) ? getEditNameOnGridInputField() : player.displayName} {getGridnameEditingIcons(player)}</td>
            <td>{numberOfPicksByUser} squares</td>
            <td>${numberOfPicksByUser * costPerSquare}</td>
            <td>${numberOfPaidPicksByUser * costPerSquare}</td>
            <td>{(numberOfPaidPicksByUser === numberOfPicksByUser) ? 'PAID' : 'TBD'}</td>
            {(isAdmin)?(<td><button onClick={() => markAllPlayerPicksAsPaidStatus(player, !isAllPaidByPlayer)}>Mark as {isAllPaidByPlayer ? 'unpaid' : 'paid'}</button></td>): null}
        </tr>;
    }

    function getGridPaymentSection() {
        return <div>
            {/*<div>*/}
            {/*    {*/}
            {/*        (currentUser && !grid.isStarted)*/}
            {/*            ? (*/}
            {/*                <div><strong>{getNumberOfPicksByUser(grid, currentUser)}</strong> squares selected.*/}
            {/*                </div>)*/}
            {/*            : null*/}
            {/*    }*/}
            {/*    {*/}
            {/*        (currentUser && !grid.isStarted)*/}
            {/*            ? (*/}
            {/*                <div>Total: <strong>${getNumberOfPicksByUser(grid, currentUser) * grid.costPerSquare}</strong>*/}
            {/*                </div>)*/}
            {/*            : null*/}
            {/*    }*/}
            {/*</div>*/}

            {/*<div>*/}
            {/*    {*/}
            {/*        (currentUser && !grid.isStarted)*/}
            {/*            ? (<div>Get in touch with the Grid owner to make a payment.</div>)*/}
            {/*            : null*/}
            {/*    }*/}
            {/*</div>*/}

            <GridPayment grid={grid} gridId={grid.id}/>
        </div>;
    }

    function editGridPlayersButton() {
        return (isAdmin)
            ? (
                <IconButton aria-label="players" onClick={() => history.push(`${match.url}/players`)}>
                    <GroupIcon/>
                </IconButton>
            )
            : null;
    }

    function editGridButton() {
        return (isAdmin)
            ? (
                <IconButton aria-label="edit" onClick={() => history.push(`${match.url}/edit`)}>
                    <EditIcon/>
                </IconButton>
            )
            : null;
    }

    function getGridContent() {
        return <div>
            {(value === 0 || value === 2) ? (
                <div>
                <h1>
                    {grid.name}
                    {
                        editGridPlayersButton()
                    }
                    {
                        editGridButton()
                    }

                </h1>
                    <div>
                        {(grid.forCharityName) ? (<div>
                            <span><b>organized by:</b> {grid.adminUserEmail}</span>&nbsp;<span><b>for: </b> <a
                            href={(grid.forCharityLink) ? (grid.forCharityLink) : "#"}>{grid.forCharityName}</a></span>
                        </div>) : null}
                    </div>
                </div>
            ) : null}



            {((value === 0 || value === 2) && grid.isStarted) ? <ScoreBoard rowTeamName={grid.rowTeamName} columnTeamName={grid.columnTeamName}
                                            rowTeamScores={grid.rowTeamScores}
                                            columnTeamScores={grid.columnTeamScores}/> : null}

            {/*{*/}
            {/*    (!grid.isStarted)*/}
            {/*        ? (<div className="grid-details">*/}
            {/*            <div><span*/}
            {/*                className="squares-avail">Available: <strong>{100 - Object.keys(grid.picks).length}</strong> squares</span>*/}
            {/*            </div>*/}
            {/*            <div><span>Max: <strong>10</strong> squares / user</span></div>*/}
            {/*            <div><span>Cost: <strong>${grid.costPerSquare}</strong> / square</span></div>*/}
            {/*        </div>)*/}
            {/*        : null*/}
            {/*}*/}

            {(value === 0 || value === 1) ? getGridPaymentSection() : null}

            {
                (Object.keys(grid.picks).length === 100 && grid.rowNumbers.length !== 10 && grid.columnNumbers.length !== 10)?
                    <h3>{"It's time to randomly pick numbers for the rows & columns ".toUpperCase()}</h3> : null
            }
            {
                (value === 0 || value === 1) ?
                    // ((!grid.isStarted) ?
                        <div className="players-list">
                        <h2>Active players:</h2>

                        <div>
                            <table align='center' cellSpacing='0' cellPadding='2' style={{borderColor: '#C0C0C0'}}
                                   border='1'
                                   className='players-table'>
                                <thead>
                                <tr>
                                    <th>Player</th>
                                    <th>Squares</th>
                                    <th>Total Pledged</th>
                                    <th>Total Paid</th>
                                    <th>Payment Status</th>
                                    {(isAdmin) ? <th>Update</th> : null}
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    players.length !== 0 ?
                                        players.map(player => (getPlayerTableRow(player)))
                                        :
                                        <tr><td>Empty</td></tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    // : null)
                    : null
            }

            {
                // todo change this logic - don't expose adminUserId
                (value === 0 || value === 2) ?
                    ((currentUser && (grid.adminUserId === currentUser.id))
                    ? <GridControlBoard grid={grid} updateScore={updateScore}/>
                    : null)
                    : null
            }

            {
                (value === 0 || value === 3) ?
                    (grid.isStarted ? <SquareWinners grid={grid} players={allPlayers} isGameOver={false}/> : <p>Winners will start appearing when the game begins</p>)
                    : null
            }

            {
                (value === 0 || value === 2) ?
                    <div>
                        {(!grid.isStarted) ? (<div>
                    {
                        Object.keys(grid.picks).length < 100
                            ? (<h3 className="text-danger">Make your selections by picking the boxes below:</h3>)
                            : null
                    }
                </div>) : null }
                        <SquareTable grid={grid}/></div>
                    : null
            }

            {(value === 4) ?
                <div>
                    <h2>Rules:</h2>
                    <h3>What :</h3>
                    <div><p>Football squares is usually game where players bet on a square on a 10x10 grid. The winner is the player whose square matches the scores for each quarter.
                    </p></div>
                    <h3>Picks</h3>
                    <div>
                            <p>Maximum number of squares per person: <b>{grid.maxPicksPerUser}</b></p>
                            <p>Users can choose a square by pressing the 'select' button within the square.</p>

                            <p>The rows & columns numbering is set within 24h of the game starting by the organizer: {grid.adminUserEmail}</p>
                            <p>{grid.rules}</p>
                    </div>
                    <h3>Winning:</h3>
                    <div>
                        <p>The winner is determined by looking at the last number in each teams' score and matching those numbers on the grid.<br/>
                            For example, if the {grid.rowTeamName} defeat the {grid.columnTeamName} 30-27, the winning square would be where the No. 0 on the row axis meets the No. 7 on the column axis</p>
                    </div>

                    {/*<h2>Maximum Players</h2>*/}
                    {/*Maximum number of players allowed in the pool: 100*/}
                </div>
                : null}

            <Box style={{height: '75px'}}></Box>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction label="Show All" icon={<ShowAllIcon />} />
                    <BottomNavigationAction label="Players" icon={<UsersIcon />} />
                    <BottomNavigationAction label="Picks" icon={<GridIcon />} />
                    <BottomNavigationAction label="Winners" icon={<WinnersIcon />} />
                    <BottomNavigationAction label="Rules" icon={<RulesIcon />} />
                </BottomNavigation>
            </Paper>
        </div>;
    }

    function getLoginRequiredDiv() {
        return <div>
                <h1>🔒 This grid is private 🔒</h1>
                <p>You have to log in first</p>
                <Button color="inherit" onClick={() => {
                    history.push("/sign-in");
                }}>Login</Button>
            </div>;
    }

    function getGridAccessDeniedDiv() {
        return <div>
            <h1>🔒 This grid is private 🔒</h1>
            <p>You do
            not have access to this grid. Request access from the owner.</p>
        </div>;
    }

    return (
        (isLoading
                ? (<SpinnerOverlay>
                    <SpinnerContainer/>
                </SpinnerOverlay>)
                :  grid
               ? grid.isPrivate ? <div>
                        {!currentUser ? getLoginRequiredDiv() : (!isEmailInvitedOrPlayer(grid, currentUser.email) ?
                            getGridAccessDeniedDiv() : getGridContent())}

                    </div> : getGridContent()
                :
                    <div>
                        <span>No grid found</span>
                    </div>

        )

        );
};

const mapStateToProps = createStructuredSelector({
    grid:  selectGrid,
    players: selectGridPlayers,
    invitedPlayers: selectInvitedPlayers,
    currentUser: selectCurrentUser,
});

const mapDispatchToProps = dispatch => ({
    updateGrid: grid => dispatch(updateGrid(grid)),
    changeUserSquaresPaidStatus: (gridId, player, user, paidStatus) => dispatch(markAllPlayerSquaresPaidStatus(gridId, player, user, paidStatus)),
    updateGridPlayerName: (grid, player, newPlayerName) => dispatch(updateGridPlayerName(grid, player, newPlayerName)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GridPage));
