import React, {useState} from "react";
import {zeroToNine} from "../../redux/grid/grid.utils";
import CustomButton from "../custom-button/custom-button.component";
import AlertDialog from "../alert-dialog/alert-dialog.component";
import {setSelectedRowAndColumnNumbers} from "../../redux/grid/grid.actions";
import {connect} from "react-redux";

const GridUpdateRowColumnNumbers = ({grid, setSelectedRowAndColumnNumbers}) => {
    const [rowNumbers, setRowNumbers] = useState([]);
    const [columnNumbers, setColumnNumbers] = useState([]);
    const [showDialog, setShowDialog] = useState(false);

    return (<div>
        <h3>{"Choose numbers manually".toUpperCase()}</h3>
        <div>
            <span>Row numbers:</span>
            {zeroToNine.map(num => {
                return <button onClick={()=>{setRowNumbers([...rowNumbers, num])}} disabled={rowNumbers.includes(num)}>{num}</button>
            }) }
        </div>
        <div>
            <span>Column numbers:</span>
            {zeroToNine.map(num => {
                return <button onClick={()=>{setColumnNumbers([...columnNumbers, num])}} disabled={columnNumbers.includes(num)}>{num}</button>
            }) }
        </div>
        <table>
            <tb>
                <tr>
                    {zeroToNine.map(num => {
                        return <td>{(rowNumbers.length>num)?rowNumbers[num]:'-'}</td>
                    })}
                </tr>
                <tr>
                    {zeroToNine.map(num => {
                        return <td>{(columnNumbers.length>num)?columnNumbers[num]:'-'}</td>
                    })}
                </tr>
            </tb>
        </table>
        <AlertDialog
            title="EMPTY squares"
            description="There are still some EMPTY squares in the your grid. Do you want to start the game anyway?"
            isOpen={showDialog}
            onContinue={() => {setSelectedRowAndColumnNumbers(grid, rowNumbers, columnNumbers); setShowDialog(false);}}
            onDiscard={() => setShowDialog(false)}
        />
        <div>
            <button onClick={()=>{setRowNumbers([])}} disabled={rowNumbers.length===0}>Clear rows</button>
            <button onClick={()=>{setColumnNumbers([])}} disabled={columnNumbers.length===0}>Clear columns</button>
        </div>
        {(rowNumbers.length!==10 || columnNumbers.length!==10)?null: <CustomButton onClick={()=>{
            let notAllSquaresSelected = Object.keys(grid.picks).length !== 100;
            notAllSquaresSelected ? setShowDialog(true) : setSelectedRowAndColumnNumbers(grid, rowNumbers, columnNumbers)}}>Choose Selected Numbers</CustomButton>}
    </div>)
};

const mapDispatchToProps = dispatch => ({
    setSelectedRowAndColumnNumbers: (grid, rowNumbers, columnNumbers) => dispatch(setSelectedRowAndColumnNumbers(grid, rowNumbers, columnNumbers))
});

export default connect(null, mapDispatchToProps)(GridUpdateRowColumnNumbers);