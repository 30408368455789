import GameActionTypes from "./game.types";

export const updateCurrentGame = game => ({
    type: GameActionTypes.UPDATE_CURRENT_GAME,
    payload: game,
});

export const updateHomePageGrids= grids => ({
    type: GameActionTypes.UPDATE_HOME_PAGE_GRIDS,
    payload: grids,
});

export const updateHomePageGames= games => ({
    type: GameActionTypes.UPDATE_HOME_PAGE_GAMES,
    payload: games,
});

