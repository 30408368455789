import React from 'react';
import ScoreControl from "./score-control/score-control.component";
import GameControl from "./game-control.component.jsx/game-control.component";

const GridControlBoard = ({grid, startGame, startNextQuarter}) => {

    return (<div className="grid-control-board">
        {
            grid.isStarted && !grid.isOver
            ? (<ScoreControl grid={grid}/>)
            : null
        }
        <GameControl grid={grid} startGame={startGame} startNextQuarter={startNextQuarter}/>
    </div>);
};

export default GridControlBoard;