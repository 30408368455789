import React from "react";
import "./toggle.css";

const Toggle = ({ toggle, handleToggleChange, label }) => {
    return (
        <>
            {label ? <div className="t1"> {label} </div> : null}
            {/*<div className="t1">Turn {toggle ? 'off' : 'on'} </div>*/}
            <div className='toggle-container' onClick={handleToggleChange}>
                <div className={`toggle-btn ${!toggle ? "disable" : ""}`}>
                    {toggle ? "ON" : "OFF"}
                </div>
                <div><span className="emo">{toggle ? "🔒" : " ️"}</span></div>
            </div>

        </>

    );
};

export default Toggle;