import React, {useEffect, useState} from "react";
import {firestore} from "../../firebase/firebase.utils";
import {connect} from "react-redux";
import {updateHomePageGrids} from "../../redux/game/game.actions";

import './home.styles.scss';
import WeekGames from "../../component/week-games/week-games.component";
import {getCurrentNFLYear, getCurrentWeek, getNumberOfWeeks, getWeekName, range} from "../../utils";
import FormSelect from "../../component/form-select/form-select.component";

const HomePage = ({updateHomePageGrids}) => {
    const currentNFLYear = getCurrentNFLYear();
    const [year, setYear] = useState(currentNFLYear);
    const [league, setLeague] = useState("NCAAF");
    const currentNFLWeek = getCurrentWeek(league);
    const [week, setWeek] = useState(currentNFLWeek);

    useEffect(() => {
         firestore.collection("grids")
            .limit(10)
            .get()
            .then(gridSnapshot => {
                const grids = [];
                gridSnapshot.forEach(grid => {
                    grids.push({...grid.data(), id: grid.id});
                });
                updateHomePageGrids(grids);
            }).catch(error => {
                console.error("An error occurred while fetching the grids", error);
            });
    }, [updateHomePageGrids]);

    const handleSelectWeek = (event) => {
        // eslint-disable-next-line no-unused-vars
        const { name, value } = event.target;
        let newWeek = parseInt(value);
        setWeek(newWeek);
    };

    const handleSelectYear = (event) => {
        // eslint-disable-next-line no-unused-vars
        const { name, value } = event.target;
        let newYear = parseInt(value);
        console.log("New Year Selected: " + newYear);
        setYear(newYear);
    };

    const handleSelectLeague = (event) => {
        const {name, value} = event.target;
        setLeague(value);
        let newWeek = getCurrentWeek(value);
        setWeek(newWeek);
    }

    return (
            <div className="home-page">
                <div className="filters-bar">
                    <div className="filters-bar-element">
                        <FormSelect style={{maxWidth: "100px"}} value={league} handleChange={handleSelectLeague} options={[{value: "NFL", name: "🏈 NFL"}, {value: "NCAAF", name: "🏈 NCAAF - College Football"}]}/>
                    </div>
                    <div className="filters-bar-element">
                        <FormSelect style={{maxWidth: "100px"}} value={year} handleChange={handleSelectYear} options={[{value: 2022, name: '2022'}, {value: 2023, name: '2023'}, {value: 2024, name: '2024'}]}/>
                    </div>
                    <div className="filters-bar-element">
                        {/*<FormSelect style={{maxWidth: "100px"}} value={"NFL"} handleChange={()=>{}} options={["NFL"].map(league => {return {name: league, value: league}})}/>*/}
                        <FormSelect style={{maxWidth: "220px"}} value={week} handleChange={handleSelectWeek} options={range(getNumberOfWeeks(league)).map(week => {return {value: week, name: getWeekName(week, league)}})} />
                    </div>
                </div>

                <WeekGames year={year} week={week} league={league}/>
            </div>
    )
};

const mapDispatchToProps = dispatch => ({
    updateHomePageGrids: grids => dispatch(updateHomePageGrids(grids)),
});

export default connect(null, mapDispatchToProps)(HomePage);
