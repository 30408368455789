import React, {useEffect, useState} from "react";
import PlayerOverview from "../../component/player-overview/player-overview.component";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {selectGrid, selectGridPlayers, selectInvitedPlayers} from "../../redux/grid/grid.selectors";
import {selectCurrentUser} from "../../redux/user/user.selector";
import {addInvitedPlayerToGrid, fetchAndUpdateGridById} from "../../firebase/firebase.utils";
import {updateGrid} from "../../redux/grid/grid.actions";
import FormInput from "../../component/form-input/form-input.component";
import CustomButton from "../../component/custom-button/custom-button.component";

import './grid-players.styles.scss';
import NavigationBackButton from "../../component/navigation-back-button/navigation-back-button.component";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GridPlayersPage = ({grid, currentUser, players, match, history, updateGrid, invitedPlayers}) => {

    useEffect(() => {
        return fetchAndUpdateGridById(match.params.gridId, updateGrid);
    }, [match.params.gridId, updateGrid]);

    const [inviteParams, setInviteParams] = useState({
        invitedEmail: '',
        invitedName: '',
    });

    const [formSubmitMessages, setFormSubmitMessages] = useState({
        successMessage: null,
        errorMessage: null,
    });

    const [snackbarAlertOpen, setSnackbarAlertOpen] = useState(false);

    const handleSnackbarAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarAlertOpen(false);
        setFormSubmitMessages({
            errorMessage: null,
            successMessage: null
        });
    };

    const handleChange = event => {
        const {name, value} = event.target;
        setInviteParams({...inviteParams, [name]: value});
        setFormSubmitMessages({
            errorMessage: null,
            successMessage: null
        });
    };


    const handleSubmit = event => {
        event.preventDefault();
        //todo better form validation
        if(inviteParams.invitedEmail.length < 5 || !inviteParams.invitedEmail.includes('@') || !inviteParams.invitedEmail.includes('.')){
            alert("A valid email is required");
            return;
        }
        if(inviteParams.invitedName.length === 0){
            alert("Please enter a name");
            return;
        }
        if([...players,...invitedPlayers]
            .map(player => player.email)
            .filter(email => email !== undefined)
            .map(email => email.toLowerCase())
            .filter(email => email === inviteParams.invitedEmail.toLowerCase()).length > 0){
            alert("this email has already been invited");
            return;
        }
        if(inviteParams.invitedEmail.length < 3){
            alert("The name field is required");
            return;
        }
        //invitePlayer(inviteParams.invitedEmail);
        addInvitedPlayerToGrid(grid.id, {
            displayName: inviteParams.invitedName,
            email: inviteParams.invitedEmail,
        });
        setInviteParams({...inviteParams, invitedEmail: '', invitedName: ''});
    };

    //todo refactor into an HOC
    if(!grid){
        return <div>Loading</div>
    }

    if(!currentUser || currentUser.email !== grid.adminUserEmail){
        history.push(match.url.split("/players")[0]);
    }

    return (<div className="grid-players">
        <NavigationBackButton toLocation={`${match.url.split("/players")[0]}`} label="return to grid"/>
        <div className="players-list">
            <h2>Active players:</h2>
            {
                grid
                    ? (<div>
                        <div>
                            {
                                players.length !== 0 ?
                                players.map(player => (<PlayerOverview key={player.email} player={player} grid={grid} />))
                                    :
                                    <span>Empty</span>
                            }
                        </div>
                        <div>
                            <h2>Invited players:</h2>
                            {
                                invitedPlayers.length !== 0 ?
                                invitedPlayers
                                    .filter(player => !(players.map(pl => pl.email).includes(player.email)))
                                    .map(player => (<div><PlayerOverview key={player.email} player={player} grid={grid} showEmailInviteButton={true}/></div>))
                                    : <span>Empty</span>
                            }
                        </div>
                    </div>)
                    : null
            }
        </div>
        {
            grid && !grid.isStarted
                ?(<div className="invite-players">
                    <h1>Invite a new player:</h1>
                    <form onSubmit={handleSubmit}>
                        <FormInput label="name" type="text" name="invitedName" value={inviteParams.invitedName} handleChange={handleChange}/>
                        <FormInput label="email" type="text" name="invitedEmail" value={inviteParams.invitedEmail} handleChange={handleChange}/>
                        <div className="buttons">
                            <div className="form-submit-message">
                                <span className="form-submit-error-message">{formSubmitMessages.errorMessage}</span>
                                <span className="form-submit-success-message">{formSubmitMessages.successMessage}</span>
                            </div>
                            <Snackbar open={snackbarAlertOpen} autoHideDuration={6000} onClose={handleSnackbarAlertClose}>
                                <Alert onClose={handleSnackbarAlertClose} severity={`${formSubmitMessages.errorMessage ? 'error' : 'success'}`}>
                                    {formSubmitMessages.errorMessage ? formSubmitMessages.errorMessage : formSubmitMessages.successMessage}
                                </Alert>
                            </Snackbar>
                            <CustomButton type="submit" disabled={grid.isStarted}>
                                INVITE
                            </CustomButton>
                        </div>
                    </form>
                </div>)
                : null
        }
    </div>);
};

const mapStateToProps = createStructuredSelector({
    grid:  selectGrid,
    currentUser: selectCurrentUser,
    players: selectGridPlayers,
    invitedPlayers: selectInvitedPlayers,
});

const mapDispatchToProps = dispatch => ({
    updateGrid: grid => dispatch(updateGrid(grid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GridPlayersPage);