import React, {useEffect, useState} from "react";
import FormInput from "../../component/form-input/form-input.component";
import CustomButton from "../../component/custom-button/custom-button.component";
import {firestore} from "../../firebase/firebase.utils";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {selectGrid} from "../../redux/grid/grid.selectors";
import {selectCurrentUser} from "../../redux/user/user.selector";

import './grid-edit.styles.scss';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import NavigationBackButton from "../../component/navigation-back-button/navigation-back-button.component";
import Toggle from "../../component/toggle-button/toggle.component";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GridEditPage = ({grid, match, currentUser, history}) => {

    const [newGridValues, setNewGridValues] = useState({
        name: '',
        gameName: '',
        costPerSquare: '',
        maxPicksPerUser: '',
        contactEmail: '',
        rowTeamName : '',
        columnTeamName: '',
        showSquareNumbers: true,
        isPrivate: false,
    });

    useEffect(() => {
        if(grid){
            setNewGridValues(newGridValues => ({
                ...newGridValues,
                name: grid.name,
                gameName: grid.gameName,
                costPerSquare: grid.costPerSquare,
                maxPicksPerUser: grid.maxPicksPerUser,
                contactEmail: grid.adminUserEmail,
                rowTeamName: grid.rowTeamName,
                columnTeamName: grid.columnTeamName,
                showSquareNumbers: grid.showSquareNumbers ? grid.showSquareNumbers : false,
                isPrivate: grid.isPrivate ? grid.isPrivate : false,
            }));// https://www.emgoto.com/react-functional-updates/
        }
    }, [grid]);

    const [formSubmitMessages, setFormSubmitMessages] = useState({
        successMessage: null,
        errorMessage: null,
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setNewGridValues({ ...newGridValues, [name]: value});
        setFormSubmitMessages({
            errorMessage: null,
            successMessage: null
        });
    };

    const handleToggleChange = () => {
        // const { name, value } = event.target;
        let isGridPrivate = !newGridValues.isPrivate;
        setNewGridValues({ ...newGridValues, isPrivate: isGridPrivate});
        console.log(`name: isPrivate - value: ${isGridPrivate}`);
        updateGridInDb({isPrivate: isGridPrivate});
        // setFormSubmitMessages({
        //     errorMessage: null,
        //     successMessage: null
        // });
    };

    // const handleSwitchChange = name => event => {
    //     setNewGridValues({ ...newGridValues, [name]: event.target.checked });
    //     setFormSubmitMessages({
    //         errorMessage: null,
    //         successMessage: null
    //     });
    // };

    const [snackbarAlertOpen, setSnackbarAlertOpen] = useState(false);

    const handleSnackbarAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarAlertOpen(false);
        setFormSubmitMessages({
            errorMessage: null,
            successMessage: null
        });
    };

    function updateGridInDb(gridValuesToUpdate) {
        console.log(`updating grid with values ${gridValuesToUpdate}`);
        firestore.collection("grids").doc(grid.id)
            .update(gridValuesToUpdate)
            .then(() => {
                setFormSubmitMessages({
                    errorMessage: null,
                    successMessage: "update successful"
                });
                setSnackbarAlertOpen(true);
                setTimeout(() => {
                    history.goBack();
                }, 555);
            }).catch(error => {
            console.error("an error occurred while updating the grid", error);
            setFormSubmitMessages({
                errorMessage: "an error has occurred, please try again",
                successMessage: null,
            });
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if(!newGridValues.name || newGridValues.name.trim().length === 0){
            setFormSubmitMessages({
                errorMessage: "grid name cannot be empty",
                successMessage: null
            });
            setSnackbarAlertOpen(true);
            setNewGridValues({...newGridValues, name: grid.name});
            return ;
        }
        setNewGridValues({...newGridValues, name: newGridValues.name.trim()});
        updateGridInDb(newGridValues);
    };

    //todo refactor into HOC
    if(!grid){
        return <div>Loading</div>
    }

    if(!currentUser || currentUser.email !== grid.adminUserEmail){
        history.goBack();
        // history.push(match.url.split("/edit")[0]);
    }

    return (<div className="edit-grid-form">
        <NavigationBackButton toLocation={`${match.url.split("/edit")[0]}`} label={"return to grid"}/>
        <h2>Grid settings</h2>
        <form onSubmit={handleSubmit} >
            <FormInput label="grid name" type="text" name="name" value={newGridValues.name} disabled={grid.isStarted} handleChange={handleChange}/>
            <FormInput label="game watched" type="text" name="gameName" value={newGridValues.gameName} disabled={true} handleChange={handleChange} diabled={grid.isStarted}/>
            <FormInput label="contact email" type="text" name="contactEmail" value={newGridValues.contactEmail} handleChange={handleChange} disabled={grid.isStarted}/>
            <FormInput label="cost per square ($)" type="number" name="costPerSquare" value={newGridValues.costPerSquare} handleChange={handleChange} disabled={grid.isStarted}/>
            <FormInput label="max squares per users" type="number" name="maxPicksPerUser" value={newGridValues.maxPicksPerUser} handleChange={handleChange} disabled={grid.isStarted}/>
            <FormInput label="Team 1 name" type="text" name="rowTeamName" value={newGridValues.rowTeamName} handleChange={handleChange} disabled={grid.isStarted}/>
            <FormInput label="Team 2 name" type="text" name="columnTeamName" value={newGridValues.columnTeamName} handleChange={handleChange} disabled={grid.isStarted}/>


            {/*<div className="t1">Turn {newGridValues.isPrivate ? 'off' : 'on'} the dark mode</div>*/}

            {/*<div className='toggle-container' onClick={handleToggleChange}>*/}
            {/*    <div className={`toggle-btn ${!newGridValues.isPrivate ? "disable" : ""}`}>*/}
            {/*        {newGridValues.isPrivate ? "ON" : "OFF"}*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<span className="emo">{newGridValues.isPrivate ? "🦉" : "🌤️"}</span>*/}

            <center>
                <Toggle label="Private grid" toggle={newGridValues.isPrivate} handleToggleChange={handleToggleChange} />
            </center>
            <br/>
            {/*<span>If Grid Is Started</span>*/}

            <FormInput label="grid started" type="text" name="gridStarted" value={grid.isStarted} disabled={grid.isStarted}/>
            <FormInput label="row numbers" type="text" name="rowNumbers" value={grid.rowNumbers} disabled={grid.isStarted}/>
            <FormInput label="column numbers" type="text" name="columnNumbers" value={grid.columnNumbers} disabled={grid.isStarted}/>
            {/*<Switch*/}
            {/*    label="Show Square numbers"*/}
            {/*    checked={newGridValues.showSquareNumbers}*/}
            {/*    onChange={handleSwitchChange("showSquareNumbers")}*/}
            {/*    value="showSquareNumbers"*/}
            {/*    color="primary"*/}
            {/*    inputProps={{ 'aria-label': 'primary checkbox' }}*/}
            {/*    disabled={grid.isStarted}*/}
            {/*/>*/}
            {/*<FormInput label="password" type="text" name="password" value={newGridValues.password} handleChange={handleChange} disabled={grid.isStarted}/>*/}


            <div className="buttons">
                <div className="form-submit-message">
                    <span className="form-submit-error-message">{formSubmitMessages.errorMessage}</span>
                    <span className="form-submit-success-message">{formSubmitMessages.successMessage}</span>
                </div>
                <Snackbar open={snackbarAlertOpen} autoHideDuration={6000} onClose={handleSnackbarAlertClose}>
                    <Alert onClose={handleSnackbarAlertClose} severity={`${formSubmitMessages.errorMessage ? 'error' : 'success'}`}>
                        {formSubmitMessages.errorMessage ? formSubmitMessages.errorMessage : formSubmitMessages.successMessage}
                    </Alert>
                </Snackbar>
                <center>
                    <CustomButton type="submit" disabled={grid.isStarted}>
                        Update
                    </CustomButton>
                </center>

            </div>
        </form>
    </div>);
};

const mapStateToProps = createStructuredSelector({
    grid:  selectGrid,
    currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(GridEditPage);
