import React from 'react'
import {connect} from 'react-redux';
import {createStructuredSelector} from "reselect";
import './header.styles.scss'
import {selectCurrentUser} from "../../redux/user/user.selector";
import {signOutStart} from "../../redux/user/user.actions";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {withRouter} from "react-router";
import {GiAmericanFootballHelmet} from "react-icons/gi";
// import {ReactComponent as Logo} from "../../assets/crown.svg";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        mr: 2,
        display: { xs: 'none', md: 'flex' },
        fontFamily: 'monospace',
        fontWeight: 700,
        letterSpacing: '.3rem',
        color: 'inherit',
        textDecoration: 'none',
    },
}));

const Header = ({currentUser, signOutStart, history}) => {
    const classes = useStyles();

        return (
        <div className="header">
            <AppBar position="static">
                <Toolbar>
                    {/*<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">*/}
                    {/*    <MenuIcon />*/}
                    {/*</IconButton>*/}
                    <GiAmericanFootballHelmet id="website-brand-icon"
                                              onClick={() => { history.push("/")}}
                                              size="33px"
                                              style={{margin: "0px 10px 0px 0px"}}
                                              // color={"#000000"}
                    />
                    <Typography id="website-brand-text" variant="h6" onClick={() => { history.push("/")}} className={classes.title}>
                        Football Squares
                        {/*Football Squares - [by OuiPool.com]*/}
                    </Typography>

                    {currentUser ?
                        (
                            <div className="navbar-items">
                                <Button color="inherit" onClick={() => { history.push("/my-grids")}}>My grids</Button>
                                {/*<Button color="inherit" onClick={() => history.push("/create-grid")}>Create grid</Button>*/}
                                <Button color="inherit" onClick={() => { history.push("/pricing")}}>Pricing</Button>
                                <Button color="inherit" onClick={() => { history.push("/account")}}>My account</Button>
                                <Button color="inherit" onClick={() => signOutStart()}>
                                    SIGN OUT ({currentUser.displayName})
                                </Button>
                            </div>

                        ) : (
                            <div className={"navbar-items"}>
                                <Button color="inherit" onClick={() => { history.push("/pricing")}}>Pricing</Button>
                                <Button color="inherit" onClick={() => {history.push("/sign-in");}}>Login</Button>
                            </div>
                            // <Link className="option" to="/sign-in">
                            //     SIGN IN
                            // </Link>
                        )}
                </Toolbar>
            </AppBar>
        </div>
    )
};

const mapStateToProps = createStructuredSelector(
    {
        currentUser: selectCurrentUser,
    }
);

const mapDispatchToProps = dispatch =>({
    signOutStart: () => dispatch(signOutStart()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
