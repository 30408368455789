const UserActionTypes = {
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    GOOGLE_SIGN_IN_START: 'GOOGLE_SIGN_IN_START',
    GOOGLE_SIGN_IN_SUCCESS: 'GOOGLE_SIGN_IN_SUCCESS',
    GOOGLE_SIGN_IN_FAILURE: 'GOOGLE_SIGN_IN_FAILURE',
    EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
    SIGN_OUT_START: 'SIGN_OUT_START',
    SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
    SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
    SIGN_UP_START: 'SIGN_UP_START',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
    SEND_RESET_PASSWORD_EMAIL: 'SEND_RESET_PASSWORD_EMAIL',
    SEND_RESET_PASSWORD_EMAIL_SUCCESS: 'SEND_RESET_PASSWORD_EMAIL_SUCCESS',
    SEND_RESET_PASSWORD_EMAIL_FAILURE: 'SEND_RESET_PASSWORD_EMAIL_FAILURE',
    CHECK_USER_SESSION: 'CHECK_USER_SESSION',
    UPDATE_USER_DETAILS: 'UPDATE_USER_DETAILS',
    UPDATE_USER_DETAILS_SUCCESS: 'UPDATE_USER_DETAILS_SUCCESS',
    UPDATE_USER_DETAILS_FAILURE: 'UPDATE_USER_DETAILS_FAILURE',
};

export default UserActionTypes;