import CustomButton from "../custom-button/custom-button.component";
import {createCheckoutSessionForGridAndNavigateToUrl} from "../../firebase/firebase.utils";
import React, {useEffect, useState} from "react";
import {userIsGridAdmin} from "../../redux/user/user.utils";
import {createStructuredSelector} from "reselect";
import {selectCurrentUser} from "../../redux/user/user.selector";
import {connect} from "react-redux";
import GridLoader from "react-spinners/GridLoader";
import {Link} from "react-router-dom";
import {getNumberOfPlayersFromGridPicks, MAX_FREE_PLAYERS} from "../../redux/grid/grid.utils";


const GridPayment = ({grid, gridId, currentUser}) => {
    const isAdmin = userIsGridAdmin(currentUser, grid);
    const [isLoading, setIsLoading] = useState(false);
    const [numberOfPlayers, setNumberOfPlayers] = useState(0);
    const [stripeCheckoutUrl, setStripeCheckoutUrl] = useState();

    function hoursSinceDate(stripeSessionCreationDate) {
        const dateNow = new Date();
        const diff = dateNow.getTime() - stripeSessionCreationDate.getTime();
        return diff / (1000 * 60 * 60)
    }

    useEffect(() => {
        let numberOfPlayersFromGridPicks = getNumberOfPlayersFromGridPicks(grid);
        setNumberOfPlayers(numberOfPlayersFromGridPicks);
        // console.log(`numberOfPlayersFromGridPicks: ${numberOfPlayersFromGridPicks}`);

        if(grid.stripeSessionCreationDate){
            const stripeSessionCreationDate = grid.stripeSessionCreationDate.toDate();
            // console.log(`stripeSessionCreationDate: ${stripeSessionCreationDate}`);
            // console.log(`stripeCheckoutUrl: ${grid.stripeCheckoutUrl}`);
            // console.log(`stripeCheckoutUrl: ${stripeSessionCreationDate}`);
            let hoursSinceStripeCheckoutDate = hoursSinceDate(stripeSessionCreationDate);
            // console.log(`hoursSinceStripeCheckoutDate: ${hoursSinceStripeCheckoutDate}`);
            if(grid.stripeCheckoutUrl && hoursSinceStripeCheckoutDate < 24){
                setStripeCheckoutUrl(grid.stripeCheckoutUrl);
            }
        }

    }, [grid]);


    const onPurchaseNow = () => {
        setIsLoading(true);
        if(stripeCheckoutUrl){
            // console.log(`stripeCheckoutUrl already exists: ${stripeCheckoutUrl}`);
            window.location.assign(stripeCheckoutUrl);
        }else{
            // console.log('no stripeCheckoutUrl, creating a new checkout session');
            createCheckoutSessionForGridAndNavigateToUrl(currentUser.id, gridId, setIsLoading)
                .catch(error => {console.error("error while checking out", error);setIsLoading(false);});
        }
    };

    return (<>
        {/*Grid Payment Section*/
            (isAdmin && !grid.isStarted) ?
                <div style={{"textAlign": "-webkit-center"}}>
                    <br/>
                    {
                        (grid.isPaid) ? <span><strong>GRID IS PAID</strong></span> : <div>
                            {isLoading
                                ? <div>
                                    <div><span>loading...</span></div>
                                    <GridLoader
                                    color={"#000000"}
                                    loading={isLoading}
                                    size={11}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"/>
                                </div>
                                :(numberOfPlayers < MAX_FREE_PLAYERS)
                                    ? <div>This grids is free to play for up to {MAX_FREE_PLAYERS} players. <br/>You can <Link to={"#"} onClick={() => onPurchaseNow()}>unlock this grid</Link> using the link to allow up to 100 players. <br/>All sales are final.<br/><br/>({numberOfPlayers}/{MAX_FREE_PLAYERS} players)</div>
                                    :<div><CustomButton disabled={isLoading} onClick={() => onPurchaseNow()}>Purchase now</CustomButton> <span>You have reached the max number of free players: {MAX_FREE_PLAYERS}/{MAX_FREE_PLAYERS}. You can purchase this grid to unlock this grid and the the paid features. All sales are final.</span></div>}
                        </div>
                    }
                </div>
                : null
        }
    </>);
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});


export default connect(mapStateToProps)(GridPayment);
