import {
    newNFLSeasonStartMonth, weeklyEndDatesNCAAF2022,
    weeklyEndDatesNCAAF2023, weeklyEndDatesNCAAF2024,
    weeklyEndDatesNFL2022,
    weeklyEndDatesNFL2023, weeklyEndDatesNFL2024
} from "./constants";

export function range(size, startAt = 0) {
    return [...Array(size).keys()].map(i => i + startAt);
}


export function getNumberOfWeeks(league){
    if(league === "NFL"){
        return 23;
    }else if(league === "NCAAF"){
        return 17
    }
    return 23;
}

export function getWeekName(week, league) {
    if(league === "NFL"){
        return getNFLWeekName(week);
    } else if(league === "NCAAF"){
        return getNCAAFWeekName(week);
    }
    return getNFLWeekName(week);
}

export function getNFLWeekName(week) {
    switch (week) {
        case 19:
            return `Week ${week} - Wild Card weekend`;
        case 20:
            return `Week ${week} - Divisional Playoffs`;
        case 21:
            return `Week ${week} - Conference Championships`;
        case 22:
            return `Week ${week} - Super Bowl`;
        default:
            return `Week ${week}`
    }
}

export function getNCAAFWeekName(week) {
    switch (week) {
        case 15:
            return `Week ${week} - Bowls`;
        case 16:
            return `CFP Championship - Final`;
        default:
            return `Week ${week}`
    }
}

function getWeeklyEndDates(year, league) {
    if(league === "NCAAF"){
        if(year < 2023){
            return weeklyEndDatesNCAAF2022;
        } else if(year == 2023){
            return weeklyEndDatesNCAAF2023;
        }else if(year >= 2024){
            return weeklyEndDatesNCAAF2024;
        }
    }
    // if(league === "NFL"){
        if(year < 2023){
            return weeklyEndDatesNFL2022;
        } else if(year == 2023){
            return weeklyEndDatesNFL2023;
        } else if(year >= 2024){
            return weeklyEndDatesNFL2024;
        }
    // }
}

export function getCurrentNFLYear() {
    const today = new Date();
    if(today.getMonth() < newNFLSeasonStartMonth) {
        return today.getFullYear() - 1;
    }
    return today.getFullYear();
}

export function getSeasonStringFromYear(currentYear) {
    return currentYear + "/" + (currentYear + 1);
}

export function getCurrentNFLSeasonStr(){
    let currentNFLYear = getCurrentNFLYear();
    return getSeasonStringFromYear(currentNFLYear); // e.g "2022/2023
}

/** Needs to be updated yearly
 *
 * @returns {number} the current week nuber in NFL season
 */
export function getCurrentNFLWeek() {
    const today = new Date();
    const weeklyEndDates2023 = getWeeklyEndDates(today.getFullYear(), "NFL");
    for(let i = 1; i<23; i++){
        let weeklyEndDates2023Element = weeklyEndDates2023[i];
        if(today < weeklyEndDates2023Element){
            return i;
        }
    }
    return 22;
}

export function getCurrentNCAAFWeek() {
    const today = new Date();
    const weeklyEndDatesThisYear = getWeeklyEndDates(today.getFullYear(), "NCAAF");
    for(let i = 1; i<23; i++){
        let weeklyEndDates2023Element = weeklyEndDatesThisYear[i];
        if(today < weeklyEndDates2023Element){
            return i;
        }
    }
    return 16;
}

export function getCurrentWeek(league) {
    if(league === "NFL"){
        return getCurrentNFLWeek();
    }else if (league === "NCAAF"){
        return getCurrentNCAAFWeek();
    }
    return getCurrentNFLWeek();
}

// String utils

export function isAlphanumericText(text) {
    let regExp = /^[0-9a-zA-Z]+$/;
    return regExp.test(text);
}

export function containsSpecialCharacters(text)
{
    let spCharsRegExp = /[!#$%^+\-=\[\]{};':"\\|,<>\/?]+/;
    // let spCharsRegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    return spCharsRegExp.test(text);
}

export function getTeamLogo(teamName, league){
    if(league === "NFL"){
        return getNFLTeamLogo(teamName);
    }else if(league === "NCAAF"){
        return getNCAAFTeamLogo(teamName);
    }
}
export function getNFLTeamLogo(teamName){
    return `/images/nfl-logos/medium/${getNFLTeamLogoName(teamName)}`;
}

export function getNCAAFTeamLogo(teamName){
    return `/images/ncaaf-logos/${getNCAAFTeamLogoName(teamName)}`;
}

export function getNFLTeamLogoName(teamName){
    if(teamName.toLowerCase().includes("49ers")){
        return "28.png";
    }else if(teamName.toLowerCase().includes("bears")){
        return "20.png";
    }else if(teamName.toLowerCase().includes("bengals")){
        return "6.png";
    }else if(teamName.toLowerCase().includes("bills")){
        return "1.png";
    }else if(teamName.toLowerCase().includes("broncos")){
        return "10.png";
    }else if(teamName.toLowerCase().includes("browns")){
        return "7.png";
    }else if(teamName.toLowerCase().includes("buccaneers")){
        return "24.png";
    }else if(teamName.toLowerCase().includes("cardinals")){
        return "18.png";
    }else if(teamName.toLowerCase().includes("chargers")){
        return "13.png";
    }else if(teamName.toLowerCase().includes("chiefs")){
        return "11.png";
    }else if(teamName.toLowerCase().includes("colts")){
        return "2.png";
    }else if(teamName.toLowerCase().includes("commanders")){
        return "19.png";
    }else if(teamName.toLowerCase().includes("cowboys")){
        return "15.png";
    }else if(teamName.toLowerCase().includes("dolphins")){
        return "3.png";
    }else if(teamName.toLowerCase().includes("eagles")){
        return "17.png";
    }else if(teamName.toLowerCase().includes("falcons")){
        return "25.png";
    }else if(teamName.toLowerCase().includes("giants")){
        return "16.png";
    }else if(teamName.toLowerCase().includes("jaguars")){
        return "30.png";
    }else if(teamName.toLowerCase().includes("jets")){
        return "5.png";
    }else if(teamName.toLowerCase().includes("lions")){
        return "21.png";
    }else if(teamName.toLowerCase().includes("packers")){
        return "22.png";
    }else if(teamName.toLowerCase().includes("panthers")){
        return "29.png";
    }else if(teamName.toLowerCase().includes("patriots")){
        return "4.png";
    }else if(teamName.toLowerCase().includes("raiders")){
        return "12.png";
    }else if(teamName.toLowerCase().includes("rams")){
        return "26.png";
    }else if(teamName.toLowerCase().includes("ravens")){
        return "31.png";
    }else if(teamName.toLowerCase().includes("saints")){
        return "27.png";
    }else if(teamName.toLowerCase().includes("seahawks")){
        return "14.png";
    }else if(teamName.toLowerCase().includes("steelers")){
        return "9.png";
    }else if(teamName.toLowerCase().includes("texans")){
        return "32.png";
    }else if(teamName.toLowerCase().includes("titans")){
        return "8.png";
    }else if(teamName.toLowerCase().includes("vikings")){
        return "23.png";
    }
}

export function getNCAAFTeamLogoName(teamName){
    console.log(`getNCAAFTeamLogoName for Team: ${teamName}`);
    let lowerCaseName = teamName.toLowerCase();
    if(lowerCaseName.includes("washington")){//huskies
        return "washington.png";
    }else if(lowerCaseName.includes("razorbacks") || lowerCaseName.includes("arkansas")){
        return "arkansas.png";
    }else if(lowerCaseName.includes("broncos") || lowerCaseName.includes("western michigan")){
        return "western-michigan.png";
    }else if(lowerCaseName.includes("wolverines") || lowerCaseName.includes("michigan")){
        return "michigan.png";
    }else if(lowerCaseName.includes("lsu")){
        return "lsu.png";
    }else if(lowerCaseName.includes("missouri")){
        return "missouri.png";
    }else if(lowerCaseName.includes("badgers") || lowerCaseName.includes("wisconsin")){
        return "wisconsin.png";
    }else if(lowerCaseName.includes("cyclones")|| lowerCaseName.includes("iowa state")){
        return "iowa-state.png";
    }else if(lowerCaseName.includes("hawkeyes")|| lowerCaseName.includes("iowa")){
        return "iowa.png";
    }else if(lowerCaseName.includes("ducks")|| lowerCaseName.includes("oregon")){
        return "oregon.png";
    }else if(lowerCaseName.includes("flames") || lowerCaseName.includes("liberty")){
        return "liberty.png";
    }else if(lowerCaseName.includes("crimson") || lowerCaseName.includes("alabama")){
        return "alabama.png";
    }else if(lowerCaseName.includes("longhorn") || lowerCaseName.includes("longhorn")){
        return "texas.png";
    }else if(lowerCaseName.includes("yellow jackets") || lowerCaseName.includes("georgia tech")){
        return "georgia-tech.png";
    }else if(lowerCaseName.includes("zips") || lowerCaseName.includes("akron")){
        return "akron.png";
    }else if(lowerCaseName.includes("buckeyes") || lowerCaseName.includes("ohio state")){
        return "ohio-state.png";
    }else if(lowerCaseName.includes("nittany lions") || lowerCaseName.includes("penn state")){
        return "penn-state.png";
    }else if(lowerCaseName.includes("lindenwoods") || lowerCaseName.includes("lions")){
        return "lindenwoods.png";
    }else if(lowerCaseName.includes("kansas state")){
        return "kansas-state.png";
    }else if(lowerCaseName.includes("jayhawks") || lowerCaseName.includes("kansas")){
        return "kansas.png";
    }else if(lowerCaseName.includes("racers") || lowerCaseName.includes("murray state")){
        return "murray-state.png";
    }else if(lowerCaseName.includes("thunderbirds") || lowerCaseName.includes("southern utah")){
        return "southern-utah.png";
    }else if(lowerCaseName.includes("jackrabbits") || lowerCaseName.includes("south dakota state")){
        return "south-dakota-state.png";
    }else if(lowerCaseName.includes("utes") || lowerCaseName.includes("utah")){
        return "utah.png";
    }else if(lowerCaseName.includes("catamounts") || lowerCaseName.includes("western carolina")){
        return "western-carolina.png";
    }else if(lowerCaseName.includes("wolfpack") || lowerCaseName.includes("nc state")){
        return "nc-state.png";
    }else if(lowerCaseName.includes("mountaineers") || lowerCaseName.includes("west virginia")){
        return "west-virginia.png";
    }else if(lowerCaseName.includes("rams") || lowerCaseName.includes("colorado state")){
        return "colorado-state.png";
    }else if(lowerCaseName.includes("seminoles") || lowerCaseName.includes("florida state")){
        return "florida-state.png";
    }else if(lowerCaseName.includes("rattlers") || lowerCaseName.includes("florida a&m")){
        return "florida-am.png";
    }else if(lowerCaseName.includes("south florida")){//bulls
        return "south-florida.png";
    }else if(lowerCaseName.includes("gators") || lowerCaseName.includes("florida")){
        return "florida.png";
    }else if(lowerCaseName.includes("cowboys") || lowerCaseName.includes("oklahoma state")){
        return "oklahoma-state.png";
    }else if(lowerCaseName.includes("sooners") || lowerCaseName.includes("oklahoma")){
        return "oklahoma.png";
    }else if(lowerCaseName.includes("owls") || lowerCaseName.includes("temple")){
        return "temple.png";
    }else if(lowerCaseName.includes("trojans") || lowerCaseName.includes("usc")){
        return "usc.png";
    }else if(lowerCaseName.includes("lumberjacks") || lowerCaseName.includes("northern arizona")){
        return "northern-arizona.png";
    }else if(lowerCaseName.includes("wildcats") || lowerCaseName.includes("arizona")){
        return "arizona.png";
    }else if(lowerCaseName.includes("lobos") || lowerCaseName.includes("new mexico")){
        return "new-mexico.png";
    }else if(lowerCaseName.includes("boston college")){//eagles
        return "boston-college.png";
    }else if(lowerCaseName.includes("hilltoppers") || lowerCaseName.includes("western kentucky")){
        return "western-kentucky.png";
    }else if(lowerCaseName.includes("fresno state")){
        return "fresno-state.png";
    }else if(lowerCaseName.includes("skyhawks") || lowerCaseName.includes("ut martin")){
        return "ut-martin.png";
    }else if(lowerCaseName.includes("rebels") || lowerCaseName.includes("ole miss")){
        return "ole-miss.png";
    }else if(lowerCaseName.includes("paladins") || lowerCaseName.includes("furman")){
        return "furman.png";
    }else if(lowerCaseName.includes("aggies") || lowerCaseName.includes("texas a&m")){
        return "texas-am.png";
    }else if(lowerCaseName.includes("irish") || lowerCaseName.includes("notre dame")){
        return "notre-dame.png";
    }else if(lowerCaseName.includes("georgia")){
        return "georgia.png";
    }else if(lowerCaseName.includes("clemson")){
        return "clemson.png";
    }else if(lowerCaseName.includes("hurricanes") || lowerCaseName.includes("miami")){
        return "miami.png";
    }else if(lowerCaseName.includes("falcons") || lowerCaseName.includes("bowling green")){
        return "bowling-green.png";
    }else if(lowerCaseName.includes("broncos") || lowerCaseName.includes("boise state")){
        return "boise-state.png";
    }else if(lowerCaseName.includes("cougars") || lowerCaseName.includes("houston")){
        return "houston.png";
    }else if(lowerCaseName.includes("colonels") || lowerCaseName.includes("nicholls")){
        return "nicholls.png";
    }else if(lowerCaseName.includes("northern illinois")){//huskies
        return "northern-illinois.png";
    }else if(lowerCaseName.includes("redbirds") || lowerCaseName.includes("illinois state")){
        return "illinois-state.png";
    }else if(lowerCaseName.includes("fighting illini") || lowerCaseName.includes("illinois")){
        return "illinois.png";
    }else if(lowerCaseName.includes("buffalo")){//bulls
        return "buffalo.png";
    }else if(lowerCaseName.includes("golden eagles") || lowerCaseName.includes("tennessee tech")){
        return "tennessee-tech.png";
    }else if(lowerCaseName.includes("blue raiders") || lowerCaseName.includes("middle tennessee")){
        return "middle-tennessee.png";
    }else if(lowerCaseName.includes("volunteers")|| lowerCaseName.includes("tennessee")){
        return "tennessee.png";
    }else if(lowerCaseName.includes("gamecocks") || lowerCaseName.includes("jacksonville state")){
        return "jacksonville-state.png";
    }else if(lowerCaseName.includes("cardinals") || lowerCaseName.includes("louisville")){
        return "louisville.png";
    }else if(lowerCaseName.includes("bears") || lowerCaseName.includes("baylor")){
        return "baylor.png";
    }else if(lowerCaseName.includes("orange") || lowerCaseName.includes("syracuse")){
        return "syracuse.png";
    }else if(lowerCaseName.includes("green wave") || lowerCaseName.includes("tulane")){
        return "tulane.png";
    }else if(lowerCaseName.includes("vandals") || lowerCaseName.includes("idaho")){
        return "idaho.png";
    }
}

