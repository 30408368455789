import React, {useState} from 'react'

import FormInput from "../form-input/form-input.component";
import CustomButton from "../custom-button/custom-button.component";

import './contact-us.styles.scss'
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {selectCurrentUser} from "../../redux/user/user.selector";

const ContactUs = ({currentUser,}) => {

    const [contactFormDetails, setContactFormDetails] = useState({
        displayName: '',
        email: '',
        subject: '',
        message: ''
    });

    const { displayName, email, subject, message } = contactFormDetails;

    const handleSubmit = async (event) => {
        event.preventDefault();

        if(message.trim().length === 0){
            alert("The message cannot be empty required ");
            return;
        }
        if(subject.trim().length === 0){
            alert("The subject cannot be empty required ");
            return;
        }
        window.location.href = `mailto:contact@ouipool.com?subject=${subject}&body=${message}`
    };

    const handleChange = (event) => {
        const {name, value} = event.target;

        setContactFormDetails({
            ...contactFormDetails,
            [name]: value
        })
    };

    return (
        <div className="contact-us">
            <h2 className="title">Contact us</h2>
            <span>by filling in this form - we'll get back to you asap</span>
            <div className="contact-us-form">
                <form onSubmit={handleSubmit}>
                    <FormInput
                        type="text"
                        name="displayName"
                        label="Full Name"
                        value={displayName}
                        onChange={handleChange}
                        required
                    />
                    <FormInput
                        type="email"
                        name="email"
                        label="Email"
                        value={email}
                        onChange={handleChange}
                        required
                    />
                    <FormInput
                        type={"text"}
                        name={"subject"}
                        label="Subject"
                        value={subject}
                        onChange={handleChange}
                        required
                    />
                    <FormInput
                        type={"text"}
                        name={"message"}
                        label="Message"
                        value={message}
                        onChange={handleChange}
                        required
                    />

                    <div className="buttons">
                        <CustomButton type="submit">
                            Contact Us
                        </CustomButton>
                    </div>

                </form>
            </div>

        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});


export default connect(mapStateToProps)(ContactUs);

