import React from "react";
import {connect} from "react-redux";
import {updateScore} from "../../../redux/grid/grid.actions";
import Button from '@material-ui/core/Button';

import './score-control.styles.scss';

const ScoreControl = ({grid, updateScore}) => {

    return (<div className="score-control">
        <h3>Score Control</h3>
        <div className="score-control-board">
            <div >
                <h3>{grid.rowTeamName}</h3>
                <div className="row-team-score-buttons">
                    <Button variant="contained" color="primary" onClick={()=>{updateScore(grid, 6, 0)}}>+6</Button>
                    <Button variant="contained" color="primary" onClick={()=>{updateScore(grid, 3, 0)}}>+3</Button>
                    <Button variant="contained" color="primary" onClick={()=>{updateScore(grid, 2, 0)}}>+2</Button>
                    <Button variant="contained" color="primary" onClick={()=>{updateScore(grid, 1, 0)}}>+1</Button>
                    <Button variant="contained" color="secondary" onClick={()=>{updateScore(grid, -1, 0)}}>-1</Button>
                </div>
            </div>

            <div>
                <div className="column-team-score-buttons">
                    <h3>{grid.columnTeamName}</h3>
                    <Button variant="contained" color="primary" onClick={()=>{updateScore(grid, 0, 6)}}>+6</Button>
                    <Button variant="contained" color="primary" onClick={()=>{updateScore(grid, 0, 3)}}>+3</Button>
                    <Button variant="contained" color="primary" onClick={()=>{updateScore(grid, 0, 2)}}>+2</Button>
                    <Button variant="contained" color="primary" onClick={()=>{updateScore(grid, 0, 1)}}>+1</Button>
                    <Button variant="contained" color="secondary" onClick={()=>{updateScore(grid, 0, -1)}}>-1</Button>
                </div>
            </div>
        </div>

    </div>)
};

const mapDispatchToProps = dispatch => ({
    updateScore: (grid, rowTeamScoreDelta, columnTeamScoreDelta) => dispatch(updateScore(grid, rowTeamScoreDelta, columnTeamScoreDelta)),
});

export default connect(null, mapDispatchToProps)(ScoreControl);