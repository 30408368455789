import React from "react";

import './player-overview.styles.scss';
import {getGridGameName, getNumberOfPicksByUser} from "../../redux/grid/grid.utils";


const PlayerOverview = ({player, grid, showEmailInviteButton = false}) => {
    let sendEmailLink = <a href={`mailto:${player.email}?subject=You're invited to to my football pool for the ${getGridGameName(grid)} game&body=I'm inviting you to join my pool "${grid.name}", here's the link to join: https://football.ouipool.com/grid/${grid.id}`}>Send Email</a>;
    return (
    <div className="player-overview">
        <h4>{player.displayName}</h4>(<span>{player.email}</span>)
        <div>Number of picks: {getNumberOfPicksByUser(grid, player)}</div>
        <div>{(showEmailInviteButton)? <button onClick={() => {}}>{sendEmailLink}</button>:null}</div>
    </div>
)};

export default PlayerOverview;