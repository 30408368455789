import React from "react";

import './grid-overview.styles.scss'
import {withRouter} from "react-router";

const GridOverview = ({grid, history}) => {
    return (
        <div className="grid-overview" style={{cursor: "pointer"}} onClick={() => history.push(`/grid/${grid.id}`)}>
            <h2>{grid.name ? grid.name : grid.id}</h2>
            {(grid.name !== grid.gameName)? <h4>{grid.gameName}</h4> : null }
                <div>
                    <span>{Object.keys(grid.picks).length}% complete</span>
                    <div>
                        <b>Organized by: </b>
                        <span>{grid.adminUserEmail}</span>
                    </div>
                </div>
        </div>)
};

export default withRouter(GridOverview);