
export const getUserByEmail = (users, email) => {
    if(!email) return null;
    if(!users) return null;
    return users.filter(user => user.email === email)[0];
};

export const getUserFromPicks = (users, picks, squareId) => {
    const pick = picks[squareId];
    const email = pick ? pick.email : null;
    return getUserByEmail(users, email);
};


export const getIsPaidFromPicks = (picks, squareId) => {
    const pick = picks[squareId];
    return pick ? pick.isPaid : false;
};

export const deduplicateUsers = users => {
    const emails = [];
    const dedupedUsers = [];
    users.map(user => {
        if(!emails.includes(user.email)){
            emails.push(user.email);
            dedupedUsers.push(user);
        }
        return user;
    });
    return dedupedUsers;
};

export const mergeUsers = (activeUsers, invitedUsers) => {
    const emails = activeUsers.map(user => user.email);
    const mergedUsers = [...activeUsers];
    invitedUsers.foreach(invitedUser => {
        if(!emails.includes(invitedUser.email)){
            emails.push(invitedUser.email);
            mergedUsers.push(invitedUser);
        }
    });
    return mergedUsers;
};

export const userIsGridAdmin = (user, grid) => {
    if(!user || !grid) return false;
    return user && (grid.adminUserEmail === user.email);
};

export const isSuperAdmin = (currentUser) => {
    if(currentUser && currentUser.email){
        return ["oubenal.mohcine@gmail.com", "mohcine.oubenal@gmail.com", "mohcine@boomhand.com"].includes(currentUser.email.toLowerCase());
    }
    return false;
    // return currentUser.email.includes("oubenal");
};