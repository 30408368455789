import React from 'react';

import './sign-in-page.styles.scss';
import {Link} from "react-router-dom";
import SignIn from "../../component/sign-in/sign-in.component";

const SignInPage = () => {

    return (<div>
        <SignIn />
        <div>
            <p>Don't have an account? <Link to="/sign-up">sign up here</Link>.</p>
            <p>Having trouble signing in? <Link to="/reset-password">reset your password</Link>.</p>
        </div>
    </div>);
};

export default SignInPage;

