import React from 'react';
import Square from "../square/square.component";
import {connect} from "react-redux";
import {markGridSquarePaidStatus, removeGridSquare, selectGridSquare} from "../../redux/grid/grid.actions";
import {withRouter} from "react-router";
import {getPlayersFromGrid, zeroToNine} from "../../redux/grid/grid.utils";
import {createStructuredSelector} from "reselect";
import {selectCurrentUser} from "../../redux/user/user.selector";
import {selectInvitedPlayers} from "../../redux/grid/grid.selectors";
import {deduplicateUsers, getIsPaidFromPicks, getUserFromPicks, userIsGridAdmin} from "../../redux/user/user.utils";
import './square-table.styles.scss';
import {getTeamLogo} from "../../utils";

const SquareTable = ({grid, currentUser, selectSquare, removeSquare, history, invitedPlayers, changeSquarePaidStatus}) => {

    const isAdmin = userIsGridAdmin(currentUser, grid);
    if(!grid){
        return (<div><b>No Grid Found</b></div>)
    }

    let { rowNumbers, columnNumbers } = grid;
    const { picks } = grid;
    if(!rowNumbers || rowNumbers.length < 10){
        rowNumbers = ['x','x','x','x','x','x','x','x','x','x'];
    }
    if(!columnNumbers || columnNumbers.length < 10){
        columnNumbers = ['x','x','x','x','x','x','x','x','x','x'];
    }
    return (
    <div className='square-table-container' style={{clear: 'both'}}>
        <table align='center' cellSpacing='0' cellPadding='2' style={{borderColor: '#C0C0C0'}} border='1'
               className='square-table'>
            <thead>
            <tr>
                <th colSpan='2' rowSpan='2'><span>Square picks</span>
                </th>
                {/*//TODO replace "NFL" with league variable*/}
                <th colSpan='10' id='square-table-th-teamRight'><div><img alt={`${grid.columnTeamName} logo`} height={40} width={40} src={`${getTeamLogo(grid.columnTeamName, grid.league ? grid.league : "NFL")}`}/></div>{grid.columnTeamName}</th>
            </tr>
            <tr>
                {
                    zeroToNine.map(column => <th key={`column-${column+1}`}>{columnNumbers[column]}</th>)
                }
            </tr>
            </thead>
            <tbody>
            <tr>
                <th rowSpan='11' id='square-table-th-teamLeft'>
                    {/*//TODO replace "NFL" with league variable*/}
                    <div><div><img alt={`${grid.rowTeamName} logo`} height={40} width={40} src={`${getTeamLogo(grid.rowTeamName, grid.league ? grid.league : "NFL")}`}/></div>{grid.rowTeamName}</div>
                </th>
            </tr>
            {

                zeroToNine.map(row => {
                    return (
                        <tr key={`row${row+1}`}>
                            <th>{rowNumbers[row]}</th>
                            {
                                zeroToNine.map(column => {

                                    let squareId = `${row}${column}`;
                                    // allPlayers contains all players with squares + the ones that have been invited but without any squares selected
                                    // let allPlayers = [...invitedPlayers, ...players.filter(player => !invitedPlayers.map(pl => pl.email).includes(player.email))];
                                    let allPlayers = deduplicateUsers([...getPlayersFromGrid(grid), ...invitedPlayers]);
                                    const userForSquare = getUserFromPicks(allPlayers, picks, squareId);
                                    const isPaid = getIsPaidFromPicks(picks, squareId);
                                    return (
                                        <td key={`td-square-${row}${column}`}>
                                                <Square
                                                    key={`square-${row}${column}`}
                                                    order={squareId}
                                                    userInSquare={userForSquare}
                                                    players={allPlayers}
                                                    isAdmin={isAdmin}
                                                    gameIsStarted={grid.isStarted}
                                                    isPaid={isPaid}
                                                    showSquareNumbers = {grid.showSquareNumbers}
                                                    showRemoveButton={currentUser && (grid.adminUserId === currentUser.id || ( userForSquare && userForSquare.email === currentUser.email))}
                                                    selectSquareHandler={() => {
                                                        if(currentUser){
                                                            selectSquare(grid.id, squareId, currentUser)
                                                        }else{
                                                            history.push("/sign-in");
                                                        }
                                                    }}
                                                    selectSquare = {(user) => selectSquare(grid.id, squareId, user)}
                                                    removeSquareHandler={() => {removeSquare(grid.id, squareId, currentUser)}}
                                                    changeSquarePaidStatusHandler={() => {changeSquarePaidStatus(grid.id, squareId, currentUser, !isPaid)}}
                                                />
                                        </td>);})
                            }
                        </tr>
                    );
                })
            }


            </tbody>
        </table>

    </div>
)};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    invitedPlayers: selectInvitedPlayers,
});


const mapDispatchToProps = dispatch => ({
    // selectSquare: (grid, squareId, user) => dispatch(selectSquare(grid, squareId, user))
    selectSquare: (gridId, squareId, user) => dispatch(selectGridSquare(gridId, squareId, user)),
    removeSquare: (gridId, squareId, user) => dispatch(removeGridSquare(gridId, squareId, user)),
    changeSquarePaidStatus: (gridId, squareId, user, paidStatus) => dispatch(markGridSquarePaidStatus(gridId, squareId, user, paidStatus)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SquareTable));
