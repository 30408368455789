import React, {useEffect, useState} from "react";
import MyGrids from "../../component/my-grids/my-grids.components";
import {createStructuredSelector} from "reselect";
import {selectCurrentUser} from "../../redux/user/user.selector";
import {connect} from "react-redux";
import {selectUserGrids} from "../../redux/grid/grid.selectors";
import {fetchAndUpdateUserGrids} from "../../firebase/firebase.utils";
import {updateUserGrids} from "../../redux/grid/grid.actions";
import {getCurrentNFLYear, getNumberOfWeeks, getWeekName, range} from "../../utils";
import FormSelect from "../../component/form-select/form-select.component";

const MyGridsPage = ({currentUser, userGrids, updateUserGrids}) => {
    // const currentNFLWeek = getCurrentNFLWeek();
    const [week, setWeek] = useState(0);//useState(currentNFLWeek);
    const currentNFLYear = getCurrentNFLYear();
    const [year, setYear] = useState(currentNFLYear);
    const [league, setLeague] = useState("NFL");

    useEffect(() => {
        const getUserGrids = async () => {
            if(currentUser){
                await fetchAndUpdateUserGrids(currentUser.id, updateUserGrids);
            }
        };
        getUserGrids().catch(error => console.error("error while getting user grids", error));

    }, [currentUser, updateUserGrids]);

    const handleSelectWeek = (event) => {
        // eslint-disable-next-line no-unused-vars
        const { name, value } = event.target;
        let newWeek = parseInt(value);
        setWeek(newWeek);
    };

    const handleSelectYear = (event) => {
        // eslint-disable-next-line no-unused-vars
        const { name, value } = event.target;
        let newYear = parseInt(value);
        setYear(newYear);
    };

    // const handleSelectLeague = (event) => {
    //     const {name, value} = event.target;
    //     setLeague(value);
    //     let newWeek = getCurrentWeek(value);
    //     setWeek(newWeek);
    // }

    return (<div>
        <div>
            {/*//TODO implement filter by grid */}
            {/*<FormSelect style={{maxWidth: "100px"}} value={league} handleChange={handleSelectLeague} options={[{value: "NFL", name: "NFL"}, {value: "NCAAF", name: "NCAAF - College Football"}]}/>*/}
            <FormSelect style={{maxWidth: "100px"}} value={year} handleChange={handleSelectYear} options={[{value: 2022, name: '2022'}, {value: 2023, name: '2023'}]}/>
            <FormSelect style={{maxWidth: "150px"}} value={week} handleChange={handleSelectWeek} options={range(getNumberOfWeeks(league)).map(week => {return {value: week, name: (week!==0) ? getWeekName(week, league) : 'Show ALL'}})} />
        </div>
        {/*//TODO filter by season*/}
        <MyGrids grids={userGrids.filter(grid => (week ===0 || grid.week === week) && (grid.year === year || grid.year === year + 1))} user={currentUser}/>
    </div>);
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    userGrids: selectUserGrids,
});

const mapDispatchToProps = (dispatch) => ({
    updateUserGrids: userGrids => dispatch(updateUserGrids(userGrids))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyGridsPage);
