import {createSelector} from "reselect";

const selectGridReducer = state => state.grid; // todo rename to game?

export const selectGrid = createSelector(
    [selectGridReducer],
    grid => grid.grid
);

export const selectGridPlayers = createSelector(
    [selectGridReducer],
    grid => grid.players
);

export const selectInvitedPlayers = createSelector(
    [selectGrid],
    grid => grid ? (grid.invitedPlayers ? grid.invitedPlayers : []) : []
);

export const selectUserGrids = createSelector(
    [selectGridReducer],
    grid => grid.userGrids
);

export const selectFeaturedGrids = createSelector(
    [selectGridReducer],
    grid => grid.featuredGrids
);

export const selectGridIsLoaded = createSelector(
    [selectGrid],
    grid => !!grid,
);

