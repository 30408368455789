import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// let history = createBrowserHistory();

export default function AlertDialog({title = "Are you sure",
                                        description,
                                        isOpen,
                                        onContinue,
                                        onDiscard
                                    }) {
    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={onDiscard}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDiscard} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onContinue} color="primary" autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
