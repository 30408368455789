import { takeLatest, put, call, all } from 'redux-saga/effects'
import UserActionTypes from "./user.types";
import {
    auth,
    createUserProfileDocument,
    firestore,
    getCurrentUser,
    googleAuthProvider
} from "../../firebase/firebase.utils";
import {
    sendResetPasswordEmailFailure,
    sendResetPasswordEmailSuccess,
    signInFailure,
    signInSuccess,
    signOutFailure,
    signOutSuccess,
    signUpFailure,
    signUpSuccess, updateUserDetailsFailure, updateUserDetailsSuccess
} from "./user.actions";

export function* getSnapshotFromUserAuth(userAuth, additionalData){
    try{
        const userRef = yield call(createUserProfileDocument, userAuth, additionalData);
        const userSnapshot = yield userRef.get();
        yield put(signInSuccess({
            id: userSnapshot.id,
            ...userSnapshot.data()
        }));
    }catch(error){
        yield put(signInFailure(error));
    }
}

export function* signInWithGoogle(){
    try{
        const { user } = yield auth.signInWithPopup(googleAuthProvider);
        yield getSnapshotFromUserAuth(user);
    }catch(error){
        yield put(signInFailure(error));
    }
}

export function* onGoogleSignInStart() {
    yield takeLatest(UserActionTypes.GOOGLE_SIGN_IN_START, signInWithGoogle);
}

export function* signInWithEmail({payload: userCredentials}){
    const { email, password} = userCredentials;
    try{
        const { user } = yield auth.signInWithEmailAndPassword(email, password);
        yield getSnapshotFromUserAuth(user);
    }catch(error){
        yield put(signInFailure(error));
    }
}

export function* onEmailSignInStart() {
    yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail)
}

export function* sendResetPasswordEmail({payload: userEmail}){
    try{
        console.log("sending password reset email for " + userEmail);
        yield auth.sendPasswordResetEmail(userEmail);
        yield put(sendResetPasswordEmailSuccess())
    }catch(error){
        yield put(sendResetPasswordEmailFailure(error));
    }
}

export function* onSendResetPasswordEmail() {
    yield takeLatest(UserActionTypes.SEND_RESET_PASSWORD_EMAIL, sendResetPasswordEmail)
}

export function* isUserAuthenticated() {
    try{
        const userAuth = yield getCurrentUser();
        if(!userAuth) return;
        yield getSnapshotFromUserAuth(userAuth)
    }catch(error){
        yield put(signInFailure(error))
    }
}

export function* onCheckUserSession() {
    yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* signOut() {
    try{
        yield auth.signOut();
        yield put(signOutSuccess())
    }catch(error){
        yield put(signOutFailure(error))
    }
}

export function* onSignOutStart() {
    yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

export function* signUp({payload: {email, password, displayName}}) {
    try{
        const { user } = yield auth.createUserWithEmailAndPassword(email, password);
        yield put(signUpSuccess({user, additionalData: {displayName}}))
    }catch(error){
        alert(error.message);
        yield put(signUpFailure(error));
    }
}

export function* onSignUpStart() {
    yield takeLatest(UserActionTypes.SIGN_UP_START, signUp);
}

export function* signInAfterSignUp({payload: {user, additionalData}}){
    yield getSnapshotFromUserAuth(user, additionalData);
}

export function* onSignUpSuccess() {
    yield takeLatest(UserActionTypes.SIGN_UP_SUCCESS, signInAfterSignUp);
}

export function* updateUserDetails({payload: {currentUser, updateData}}) {
    try{
        if(!updateData.displayName || !updateData.displayName.trim()){
            alert("user name cannot be empty");
            throw new Error("user name cannot be empty");
        }
        yield firestore.collection("users").doc(currentUser.id)
            .update(updateData)
            .catch(error => {throw error;});
        yield put(updateUserDetailsSuccess())
    }catch (error) {
        yield put(updateUserDetailsFailure(error.message));
    }
}

export function* onUpdateUserDetails(){
    yield takeLatest(UserActionTypes.UPDATE_USER_DETAILS, updateUserDetails);
}

export function* userSagas(){
    yield all([
        call(onGoogleSignInStart),
        call(onEmailSignInStart),
        call(onCheckUserSession),
        call(onSignOutStart),
        call(onSignUpStart),
        call(onSignUpSuccess),
        call(onUpdateUserDetails),
        call(onSendResetPasswordEmail),
    ])
}