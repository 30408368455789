import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React from "react";
import {withRouter} from "react-router";

const NavigationBackButton = ({toLocation, label = null, history}) => {
    return (<div>
        <IconButton aria-label="edit" onClick={() => history.push(toLocation)}>
            <ArrowBackIosIcon />
        </IconButton>
        {
            label
            ? <span>{label}</span>
            : null
        }
    </div>);
};

export default withRouter(NavigationBackButton);