import React from 'react';

import './sign-up-page.styles.scss';
import SignUp from "../../component/sign-up/sign-up.component";
import {Link} from "react-router-dom";

const SignUpPage = () => {

    return (<div>
        <SignUp />
        <div>
            Already have an account? <Link to="/sign-in">sign in here</Link>.
        </div>
    </div>);
};

export default SignUpPage;

