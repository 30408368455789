import React, {useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {GiAmericanFootballHelmet} from "react-icons/gi";

import './game-overview.styles.scss'
import Button from "@material-ui/core/Button";
import {createStructuredSelector} from "reselect";
import {updateCurrentGame} from "../../redux/game/game.actions";
import {connect} from "react-redux";
import ScoreBoard from "../score-board/score-board.component";
import {selectCurrentUser} from "../../redux/user/user.selector";
import {selectFeaturedGrids, selectUserGrids} from "../../redux/grid/grid.selectors";
import {fetchAndUpdateUserGrids, getFeaturedGrids} from "../../firebase/firebase.utils";
import {updateFeaturedGrids, updateUserGrids} from "../../redux/grid/grid.actions";
import {isSuperAdmin} from "../../redux/user/user.utils";
import {getTeamLogo, getWeekName} from "../../utils";
import {isGamePassedOrFinished} from "../../redux/game/game.utils";

function sameText(s1, s2) {
    if(s1 !== s2 && (s1 === null || s2 === null)) return false;
    return s1.trim().toLowerCase() === s2.trim().toLowerCase();
}

const GameOverview = ({game, userGrids, featuredGrids, currentUser, updateCurrentGame, updateUserGrids, updateFeaturedGrids, history,}) => {
    let date = game.startTime.toDate();
    const [team1ColorIndex, setTeam1ColorIndex] = useState(1);
    const [team2ColorIndex, setTeam2ColorIndex] = useState(0);
    const [isCollapsed, setIsCollapsed] = useState(isGamePassedOrFinished(game));


    async function startCreateGridFromGame() {
        await updateCurrentGame(game);
        history.push("/create-grid");
    }

    function gameHasStarted() {
        let gameStartTime = game.startTime.toDate().getTime();
        let timeNow = new Date().getTime();
        return gameStartTime < timeNow;
    }

    function gameHasNotStarted() {
        return !gameHasStarted();
    }

    useEffect(() => {
        if(userGrids.length === 0){
            const getUserGrids = async () => {
                if(currentUser){
                    await fetchAndUpdateUserGrids(currentUser.id, updateUserGrids);
                }
            };
            getUserGrids().catch((error) => console.error("error while getting user grids", error));
        }

    }, [currentUser, updateUserGrids, userGrids.length]);

    useEffect(() => {
        if(featuredGrids.length === 0){
            const fetchFeaturedGridsData = async () => {
                // console.log('fetchFeaturedGridsData ');
                return await getFeaturedGrids();
            };
            fetchFeaturedGridsData().then(grids => {if(grids.length>0) updateFeaturedGrids(grids)}).catch((error) => console.error("error while getting featured grids", error));
        }

    }, [featuredGrids.length, updateFeaturedGrids]);

    let userGridsOnSameGame = userGrids.filter(grid => grid.week === game.week && grid.year === game.year && sameText(grid.rowTeamName, game.team1.name));
    let featuredGridsOnSameGame = featuredGrids.filter(grid => grid.week === game.week && grid.year === game.year && sameText(grid.rowTeamName, game.team1.name));

    function createGridButton(buttonTitle = "Create grid", buttonColor='lightslategrey') {
        return <Button color="inherit" style={{'background-color': buttonColor, 'margin': '5px 0px 5px 0px'}} onClick={() => {
            startCreateGridFromGame().catch(error => console.error("error while creating a grid from a game", error));
        }}>{buttonTitle}</Button>;
    }

    async function handleUpdateGame() {
        await updateCurrentGame(game);
        history.push(`/game/${game.id}`);
    }

    function updateGameAdminButton(){
        if(isSuperAdmin(currentUser)){
            return <Button color="inherit" style={{'background-color': 'gold'}} onClick={() => handleUpdateGame()}>update</Button>
        }else{
            return null;
        }
    }

    function getUserGridsForGame() {
        return <>{(userGridsOnSameGame.length > 0) ? <div><strong>My grids:</strong>{userGridsOnSameGame.map((grid) => <li><Link
            to={`/grid/${grid.id}`}>{grid.name ? grid.name : grid.id}</Link></li>)}</div> : null}</>;
    }

    function getFeaturedOuiPoolGridsForGame() {
        return <>{(featuredGridsOnSameGame.length > 0) ? <div><strong>Featured:</strong>{featuredGridsOnSameGame.map((grid) => <li><Link
            to={`/grid/${grid.id}`}>{grid.name ? grid.name : grid.id}</Link></li>)}</div>: null}</>;
    }

    function getCreateGridForGameButton() {
        return <>{gameHasNotStarted() ? (userGridsOnSameGame.length > 0 ? createGridButton( "Create another Grid", 'lightgrey') : createGridButton()): null}</>;
    }

    function getLocationAndTimeDetailsDiv() {
        return <div>
            <span>at <strong>{game.stadium}</strong></span>
            <div>
                <span>{date.toDateString()} - {date.toLocaleTimeString('en-US', {timeZone: 'America/New_York'})}</span>
            </div>
            <div>{getWeekName(game.week)}</div>
        </div>;
    }

    return (<div className="game-overview">
        <div style={{float: "right"}}><button onClick={() => setIsCollapsed(!isCollapsed)}>{isCollapsed ? '⌄' : '⌃' }</button></div>
        <div>
            {!isCollapsed && <GiAmericanFootballHelmet className="icon"
                                               size="50px"
                                               color={game.team1.colors[team1ColorIndex]}
                                               cursor="pointer"
                                               onClick={() => {
                                                   setTeam1ColorIndex((prevColorIndex) => (prevColorIndex + 1) % game.team1.colors.length);
                                               }}/>}
            {!isCollapsed && <GiAmericanFootballHelmet
                className="icon"
                style={{transform: "scaleX(-1)"}}
                size="50px"
                color={game.team2.colors[team2ColorIndex]}
                cursor="pointer"
                onClick={() => {
                    setTeam2ColorIndex((prevColorIndex) => (prevColorIndex + 1) % game.team2.colors.length);
                }}/>}

            <div>
                <img alt={`${game.team1.fullName} logo`} height={40} width={40} src={`${getTeamLogo(game.team1.fullName, game.league)}`}/>
                <img alt={`${game.team2.fullName} logo`} height={40} width={40} src={`${getTeamLogo(game.team2.fullName, game.league)}`}/>
            </div>

            {<h3>{game.team1.name} @ {game.team2.name}</h3>
            }

            {/*<h3><Link to={`/nfl/2022/week/${game.week}/${game.team1.name}/${game.team2.name}`}>{game.team1.name} vs. {game.team2.name}</Link></h3>*/}
        </div>
        {(game.rowTeamScores && game.columnTeamScores) ?
            <ScoreBoard showFullScoreOnly={isCollapsed} rowTeamName={game.team1.name} rowTeamScores={game.rowTeamScores}
                        columnTeamName={game.team2.name} columnTeamScores={game.columnTeamScores}/> : null}
        {!isCollapsed && getLocationAndTimeDetailsDiv()}

        <br/>
        {

            <div>
                <div>{getUserGridsForGame()}
                    {getFeaturedOuiPoolGridsForGame()}</div>
                <div>{!isCollapsed && getCreateGridForGameButton()}</div>
            </div>
        }
        {updateGameAdminButton()}
        {/*<div>*/}
        {/*    {gameHasStarted() ? null : (<Button color="inherit" style={{'background-color': 'lightslategrey'}} onClick={() => {*/}
        {/*        startCreateGridFromGame().then(r => console.log("ok"));*/}
        {/*    }}>Create grid</Button>)}*/}
        {/*</div>*/}
    </div>)
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    userGrids: selectUserGrids,
    featuredGrids: selectFeaturedGrids,
});

const mapDispatchToProps = dispatch => ({
    updateCurrentGame: game => dispatch(updateCurrentGame(game)),
    updateUserGrids: grids => dispatch(updateUserGrids(grids)),
    updateFeaturedGrids: grids => dispatch(updateFeaturedGrids(grids)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GameOverview));
