import {all, call, put, takeLatest} from 'redux-saga/effects';
import {firestore, getGridById, updateGrid as updateGridInDb} from "../../firebase/firebase.utils";
import GridActionTypes from "./grid.types";
import {
    endGameFailure,
    endGameSuccess,
    fetchGridFailure,
    fetchGridSuccess,
    markAllPlayerSquaresPaidStatusFailure,
    markAllPlayerSquaresPaidStatusSuccess,
    markGridSquarePaidFailure,
    markGridSquarePaidSuccess,
    removeGridSquareFailure,
    removeGridSquareSuccess,
    selectGridSquareFailure,
    selectGridSquareSuccess,
    selectRandomRowAndColumnNumbersFailure,
    selectRandomRowAndColumnNumbersSuccess,
    setGridScoresFailure,
    setGridScoresSuccess,
    setSelectedRowAndColumnNumbersFailure,
    setSelectedRowAndColumnNumbersSuccess,
    startFetchGrid,
    startGameFailure,
    startGameSuccess,
    startNextQuarterFailure,
    startNextQuarterSuccess,
    updateGridPlayerNameFailure,
    updateGridPlayerNameSuccess,
    updateScoreFailure,
    updateScoreSuccess
} from "./grid.actions";
import {
    addSquareSelectionToGrid,
    getAllPicksByUser,
    getNumberOfPicksByUser,
    getPicksWithUpdatedPlayerName,
    getPlayersFromGrid, MAX_FREE_PLAYERS, MAX_NUMBER_OF_PICKS_PER_USER,
    removeSquareSelectionFromGrid,
    shuffleArray,
    updateSquarePaidStatusInGrid,
    zeroToNine
} from "./grid.utils";
import {userIsGridAdmin} from "../user/user.utils";

// fetching the grid from the db
export function* fetchGridStart({payload: gridId}){
    try{
        const grid = yield getGridById(gridId);
        yield put(fetchGridSuccess(grid));
    }catch(error){
        yield put(fetchGridFailure(error.message))
    }
}

export function* onFetchGridStart(){
    yield takeLatest(GridActionTypes.FETCH_GRID_START, fetchGridStart);
}



// user selects a square from the grid
export function* selectGridSquare({payload: {gridId, squareId, user}}){
    function containsUser(gridPlayers) {
        return gridPlayers.filter(player => player.id === user.id || player.email === user.email).length>0;
    }
    try{
        const gridRef = yield firestore.collection('grids').doc(gridId);
        const gridSnapshot = yield gridRef.get();
        if(!gridSnapshot.exists){
            //todo handle
            console.error("grid does not exist");
        }
        const grid = gridSnapshot.data();
        const numberOfPicksByUser = getNumberOfPicksByUser(grid, user);
        let maxNumberOfPicksPerUser = (grid.maxPicksPerUser) ? grid.maxPicksPerUser : MAX_NUMBER_OF_PICKS_PER_USER;
        if(numberOfPicksByUser>=maxNumberOfPicksPerUser){
            alert("max number of picks reached");
            return;
        }
        let gridPlayers = getPlayersFromGrid(grid).map(player => ({
            id: player.id ? player.id : null,
            displayName: player.displayName,
            email: player.email,
        }));
        let numberOfPlayersInGrid = gridPlayers.length;
        if(!grid.isPaid && numberOfPlayersInGrid>=MAX_FREE_PLAYERS && !gridPlayers.map(player => player.email).includes(user.email)){
            alert("Maximum number of free players reached: \n\n" + (userIsGridAdmin(user, grid) ? "You can can unlock this grid by making a purchase." : "Contact the admin and ask them to unlock the paid features."));
            return;
        }
        const newPicks = yield addSquareSelectionToGrid(grid, squareId, user);
        let gridUpdate = {
            picks: newPicks,
        };
        //TODO delete grid.players? and delete this section?

        if(!containsUser(gridPlayers)) {
            let newPlayerInGrid = {'email': user.email, 'displayName': user.displayName};
            if(user.id){
                newPlayerInGrid.id = user.id;
            }
            gridPlayers.push(newPlayerInGrid);
        }
        gridUpdate.players = gridPlayers;
        //END Section to delete
        yield gridRef.update({...gridUpdate})
            .catch(error => {
                console.error("Error updating document: ", error);
                throw error;
            });
        yield put(selectGridSquareSuccess());
        // yield put(updateGridPlayers(gridPlayers));
    }catch(error){
        yield put(selectGridSquareFailure(error.message))
    }
}

export function* onUserSelectsGridSquare(){
    yield takeLatest(GridActionTypes.USER_SELECTS_GRID_SQUARE, selectGridSquare);
}

export function* updateGridPlayerName({payload: {grid, player, newPlayerName}}){
    try{
        const newPicks = getPicksWithUpdatedPlayerName(grid, player, newPlayerName);
        yield updateGridInDb(grid.id, {picks: newPicks});
        yield put(updateGridPlayerNameSuccess(newPicks));
    }catch(error){
        yield put(updateGridPlayerNameFailure(error.message));
    }
}

export function* onUpdateGridPlayerName(){
    yield takeLatest(GridActionTypes.UPDATE_GRID_PLAYER_NAME, updateGridPlayerName)
}

export function* removeGridSquare({payload: {gridId, squareId, user}}){
    try{
        // console.log(`removing squareId=${squareId} by user email=${user.email} from gridId=${gridId}`);
        const gridRef = yield firestore.collection('grids').doc(gridId);
        // const gridSnapshot = yield gridRef.get();
        // if(!gridSnapshot.exists){
        //
        // }
        const gridSnapshot = yield gridRef.get();
        if(!gridSnapshot.exists){
            //todo handle
            console.error("no grid found to update");
        }
        const grid = gridSnapshot.data();
        const square = grid.picks[squareId];
        if(grid.adminUserEmail !== user.email && square.email !== user.email){
            console.error("you don't have permissions to remove this square");
            yield put(removeGridSquareFailure("The user doesn't have permission to remove the square"));
            return;
        }
        if(!square){
            console.error("trying to remove a square that doesn't exist");
            yield put(removeGridSquareFailure("cannot remove a square that doesn't exist"));
            return;
        }

        const newPicks = yield removeSquareSelectionFromGrid(grid, squareId);
        yield gridRef.update({
            picks: newPicks,
        })
        .then(() => {
        })
        .catch(error => {
            console.error("Error updating document: ", error);
            throw error;
        });
        // const newGrid = yield gridRef.get();
        yield put(removeGridSquareSuccess());
    }catch(error){
        yield put(removeGridSquareFailure(error.message));
    }
}

export function* onUserRemovesGridSquare(){
    yield takeLatest(GridActionTypes.USER_REMOVES_GRID_SQUARE, removeGridSquare);
}

export function* markGridSquarePaidStatus({payload: {gridId, squareId, user, paidStatus}}){
    try{
        const gridRef = yield firestore.collection('grids').doc(gridId);
        const snapshot = yield gridRef.get();
        const grid = yield snapshot.data();
        const square = grid.picks[squareId];
        if(grid.adminUserEmail !== user.email){
            console.error("you don't have permissions to remove this square");
            yield put(markGridSquarePaidFailure("The user doesn't have permission to remove the square"));
            return;
        }
        if(!square){
            console.error("trying to update a square that doesn't exist");
            yield put(markGridSquarePaidFailure("cannot update a square that doesn't exist"));
            return;
        }

        const newPicks = yield updateSquarePaidStatusInGrid(grid, [squareId], paidStatus);
        yield gridRef.update({
                picks: newPicks,
            })
            .catch(error => {
                console.error("an error occurred while updating the square", error);
                throw error;
            });
        yield put(markGridSquarePaidSuccess())
    }catch(error){
        yield put(markGridSquarePaidFailure(error.message));
    }
}

export function* onMarkGridSquarePaidStatus(){
    yield takeLatest(GridActionTypes.CHANGE_GRID_SQUARE_PAID_STATUS, markGridSquarePaidStatus);
}

export function* markAllPlayerSquaresPaidStatus({payload: {gridId, player, user, paidStatus}}){
    try{
        const gridRef = yield firestore.collection('grids').doc(gridId);
        const snapshot = yield gridRef.get();
        const grid = yield snapshot.data();
        const picks = getAllPicksByUser(grid, player) ;
        if(grid.adminUserEmail !== user.email){
            console.error("you don't have permissions to update the grid");
            yield put(markAllPlayerSquaresPaidStatusFailure("The user doesn't have permission to update the square"));
            return;
        }
        if(!picks ){
            console.error("trying to update user squares when there are none");
            yield put(markAllPlayerSquaresPaidStatusFailure("cannot update user squares that doesn't exist"));
            return;
        }
        const newPicks = yield updateSquarePaidStatusInGrid(grid, picks.map(pick => pick.id), paidStatus);
        yield gridRef.update({
            picks: newPicks,
        })
            .catch(error => {
                console.error("an error occurred while updating the user squares", error);
                throw error;
            });
        yield put(markAllPlayerSquaresPaidStatusSuccess())
    }catch(error){
        yield put(markAllPlayerSquaresPaidStatusFailure(error.message));
    }
}

export function* onMarkAllPlayerSquaresPaidStatus(){
    yield takeLatest(GridActionTypes.CHANGE_ALL_PLAYER_SQUARES_PAID_STATUS, markAllPlayerSquaresPaidStatus);
}

export function* setGridScores({payload: {gridId, rowTeamScores, columnTeamScores}}){
    const newRowTeamScores = [...rowTeamScores];
    const newColumnTeamScores = [...columnTeamScores];
    try{
        //todo add data checks before updating
        yield firestore.collection("grids").doc(gridId).update({
            rowTeamScores: newRowTeamScores,
            columnTeamScores: newColumnTeamScores
        }).catch(error =>{
            throw error;
        });
        yield put(setGridScoresSuccess());
        yield put(startFetchGrid(gridId));
    }catch(error){
        yield put(setGridScoresFailure(error.message))
    }
}
export function* onSetGridScores(){
    yield takeLatest(GridActionTypes.SET_GRID_SCORES, setGridScores);
}

export function* updateScore({payload: {gridId, rowTeamScores, columnTeamScores, rowTeamScoreDelta, columnTeamScoreDelta}}){
    const newRowTeamScores = [...rowTeamScores];
    const newColumnTeamScores = [...columnTeamScores];
    try{
        //todo check if game is over before updating
        newRowTeamScores[rowTeamScores.length-1] = rowTeamScores[rowTeamScores.length-1] + rowTeamScoreDelta;
        newColumnTeamScores[columnTeamScores.length-1] = columnTeamScores[columnTeamScores.length-1] + columnTeamScoreDelta;
        if(newRowTeamScores[rowTeamScores.length-1] < 0 || newColumnTeamScores[columnTeamScores.length-1] < 0 ){
            throw new Error('improper score update cancelled')
        }
        yield firestore.collection("grids").doc(gridId).update({
            rowTeamScores: newRowTeamScores,
            columnTeamScores: newColumnTeamScores
        }).catch(error =>{
            throw error;
        });
        yield put(updateScoreSuccess()); //todo not needed?
    }catch(error){
        yield put(updateScoreFailure(error.message))
    }
}
export function* onUpdateScore(){
    yield takeLatest(GridActionTypes.UPDATE_SCORE, updateScore);
}

export function* startGame({payload: grid}){
    try{
        if(grid.isStarted){
            alert("game is already started");
            throw new Error("game is already started");
        }
        yield firestore.collection("grids").doc(grid.id)
            .update({
                isStarted: true,
                rowTeamScores: [0],
                columnTeamScores: [0],
            }).catch(error => {
                throw error;
            });
        yield put(startGameSuccess())
    }catch(error) {
        yield put(startGameFailure(error.message))
    }
}

export function* onStartGame(){
    yield takeLatest(GridActionTypes.START_GAME, startGame);
}

export function* endGame({payload: grid}){
    try{
        if(!grid.isStarted){
            alert("game has not yet been started");
            throw new Error("game is has not yet been started");
        }
        if(grid.isOver){
            alert("game is already over");
            throw new Error("game is already over");
        }
        yield firestore.collection("grids").doc(grid.id)
            .update({
                isOver: true,
            }).catch(error => {
                throw error;
            });
        yield put(endGameSuccess())
    }catch(error) {
        yield put(endGameFailure(error.message))
    }
}

export function* onEndGame(){
    yield takeLatest(GridActionTypes.END_GAME, endGame);
}

export function* startNextQuarter({payload: grid}){
    try{
        const rowTeamTotalGameScore = grid.rowTeamScores.reduce((totalScore, score) => totalScore + score, 0);
        const columnTeamTotalGameScore = grid.columnTeamScores.reduce((totalScore, score) => totalScore + score, 0);
        let isNotDrawScore = rowTeamTotalGameScore !== columnTeamTotalGameScore;
        if(grid.rowTeamScores.length >= 4 && isNotDrawScore){
            // eslint-disable-next-line no-throw-literal
            throw {message: "Cannot start the next quarter: the final quarter was reached."}
        }
        const newRowTeamScores = [...grid.rowTeamScores, 0];
        const newColumnTeamScores = [...grid.columnTeamScores, 0];
        yield firestore.collection("grids").doc(grid.id)
            .update({
                rowTeamScores: newRowTeamScores,
                columnTeamScores: newColumnTeamScores
            }).catch(error => {
                throw error;
            });
        yield put(startNextQuarterSuccess());
    }catch(error){
        yield put(startNextQuarterFailure(error.message));
    }
}

export function* onStartNextQuarter(){
    yield takeLatest(GridActionTypes.START_NEXT_QUARTER, startNextQuarter);
}

export function* selectRandomRowAndColumnNumbers({payload: grid}){
    try{
        if(grid.rowNumbers.length === 10 && grid.columnNumbers === 10){
            let errorMessage = "row and column numbers have already been selected for this grid";
            // eslint-disable-next-line no-throw-literal
            alert(errorMessage);
            // eslint-disable-next-line no-throw-literal
            throw {message: errorMessage};
        }
        const newRowNumbers = shuffleArray([...zeroToNine]);
        const newColumnNumbers = shuffleArray([...zeroToNine]);
        firestore.collection("grids").doc(grid.id)
            .update({
                rowNumbers: newRowNumbers,
                columnNumbers: newColumnNumbers,
            }).catch(error => {
                throw error
            });
        yield put(selectRandomRowAndColumnNumbersSuccess())
    }catch(error){
        yield put(selectRandomRowAndColumnNumbersFailure(error.message))
    }
}

export function* onSelectRandomRowAndColumnNumbers(){
    yield takeLatest(GridActionTypes.SELECT_RANDOM_ROW_AND_COLUMN_NUMBERS, selectRandomRowAndColumnNumbers);
}

export function* setSelectedRowAndColumnNumbers({payload: {grid, rowNumbers, columnNumbers}}){
    try{
        if(grid.rowNumbers.length === 10 && grid.columnNumbers === 10){
            // eslint-disable-next-line no-throw-literal
            throw {message: "row and column numbers have already been selected for this grid"};
        }
        if(rowNumbers.length !== 10 || columnNumbers.length !== 10){
            // eslint-disable-next-line no-throw-literal
            throw {message: "incorrect number of entries for row or column numbers selected for this grid"};
        }
        for(let i=0; i<10; i++){
            let columnNum = Number.parseInt(columnNumbers[i]);
            let rowNum = Number.parseInt(rowNumbers[i]);
            if((rowNum > 9 || rowNum < 0) || (columnNum > 9 || columnNum < 0) ){
                // eslint-disable-next-line no-throw-literal
                throw {message: "incorrect value in row or column numbers selected for this grid."};
            }
        }
        firestore.collection("grids").doc(grid.id)
            .update({
                rowNumbers: rowNumbers,
                columnNumbers: columnNumbers,
            }).catch(error => {
            throw error
        });
        yield put(setSelectedRowAndColumnNumbersSuccess())
    }catch(error){
        yield put(setSelectedRowAndColumnNumbersFailure(error.message))
    }
}

export function* onSetSelectedRowAndColumnNumbers(){
    yield takeLatest(GridActionTypes.SET_SELECTED_ROW_AND_COLUMN_NUMBERS, setSelectedRowAndColumnNumbers);
}

export function* gridSagas(){
    yield all([
        call(onFetchGridStart),
        call(onUserSelectsGridSquare),
        call(onUserRemovesGridSquare),
        call(onMarkGridSquarePaidStatus),
        call(onMarkAllPlayerSquaresPaidStatus),
        call(onSetGridScores),
        call(onUpdateGridPlayerName),
        call(onUpdateScore),
        call(onStartGame),
        call(onStartNextQuarter),
        call(onSelectRandomRowAndColumnNumbers),
        call(onSetSelectedRowAndColumnNumbers),
        call(onEndGame),
    ])
}