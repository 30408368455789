import React, {useState} from 'react';
import {
    endGame,
    generateRandomRowAndColumnNumbers,
    setGridScores,
    startGame,
    startNextQuarter
} from "../../../redux/grid/grid.actions";
import {connect} from "react-redux";
import CustomButton from "../../custom-button/custom-button.component";

import './game-control.styles.scss';
import {Link} from "react-router-dom";
import AlertDialog from "../../alert-dialog/alert-dialog.component";
import GridUpdateRowColumnNumbers from "../../grid-update-row-column-numbers/grid-update-row-column-numbers.component";
import {userIsGridAdmin} from "../../../redux/user/user.utils";
import {createStructuredSelector} from "reselect";
import {selectCurrentUser} from "../../../redux/user/user.selector";
import {getGameById} from "../../../firebase/firebase.utils";

const GameControl = ({grid, currentUser, startGame, endGame, startNextQuarter, generateRandomRowAndColumnNumbers, setGridScores}) => {
    const [showEmptySquaresDialog, setShowEmptySquaresDialog] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [displayNumberOfSelectedSquares, setDisplayNumberOfSelectedSquares] = useState(true);

    // const InvitePlayersButton = () => {
    //     if(grid.isStarted){
    //         return null;
    //     }
    //     if(Object.keys(grid.picks).length === 100){
    //         return null;
    //     }
    //     return <div><Link to={`/grid/${grid.id}/players`}> <CustomButton >Invite Players</CustomButton></Link></div>;
    // };

    const GenerateRandomNumbersButton = () => {
        if(grid.rowNumbers.length === 10 || grid.columnNumbers.length === 10){
            // column & row numbers have already been picked
            return null;
        }
        if(Object.keys(grid.picks).length !== 100 && displayNumberOfSelectedSquares){
            // not all squares have been picked
            return <div>
                <p>You can also choose the squares on behalf of the other players. (start <Link to={`/grid/${grid.id}/players`}>inviting players</Link> if you haven't already)  </p>
                <AlertDialog
                    title="Empty squares"
                    description="There are still some EMPTY squares in the your grid. Do you want to start the game anyway?"
                    isOpen={showEmptySquaresDialog}
                    onContinue={() => setDisplayNumberOfSelectedSquares(false)}
                    onDiscard={() => setShowEmptySquaresDialog(false)}
                />
                <CustomButton onClick={() => setShowEmptySquaresDialog(true)}>Start</CustomButton>
            </div>;
        }
        return (<div className="square-numbers-control">
            {/*<p>It's time to pick the row and column numbers.</p>*/}
            <AlertDialog
                title="UNPAID squares"
                description="There are still some squares marked as UNPAID. Which means that you have not collected the full amount required for this grid. Do you want to continue anyway?"
                isOpen={showDialog}
                onContinue={() => {generateRandomRowAndColumnNumbers(grid); setShowDialog(false);}}
                onDiscard={() => setShowDialog(false)}
            />
            <CustomButton onClick={() => setShowDialog(true)}>Generate Random Numbers</CustomButton>
            <span>OR</span>
            <GridUpdateRowColumnNumbers grid={grid}/>
            <p>Enter Numbers Manually in <Link to={`/grid/${grid.id}/edit`}>grid settings</Link></p>
        </div>);
    };

    const StartGameButton = () => {
        if(grid.rowNumbers.length !== 10 || grid.columnNumbers.length !== 10){
            return null;
        }
        if(grid.isStarted){
            return null;
        }
        return <CustomButton onClick={() => startGame(grid)}>Start Game</CustomButton>
    };

    const StartNextQuarterButton = () => {
        if(!grid.isStarted){
            return null;
        }
        if(quarter <= 4){
            return <CustomButton onClick={() => {startNextQuarter(grid)}}>
                Start Quarter {quarter}
            </CustomButton>
        }
        if(grid.isOver){
            return null;
        }
        const rowTeamTotalGameScore = grid.rowTeamScores.reduce((totalScore, score) => totalScore + score, 0);
        const columnTeamTotalGameScore = grid.columnTeamScores.reduce((totalScore, score) => totalScore + score, 0);

        if(rowTeamTotalGameScore === columnTeamTotalGameScore){
            return (<div>
                <CustomButton onClick={() => {endGame(grid)}}>
                    End Game
                </CustomButton>
                <CustomButton onClick={() => {startNextQuarter(grid)}}>
                    Start Overtime
                </CustomButton>
            </div>)
        }
        return (<CustomButton onClick={() => {endGame(grid)}}>
            End Game
        </CustomButton>)
    };

    const updateGridScoresFromGameResults = () => {
        // console.log(`start updating scores for game ${grid.gameId}`);
        if(grid.gameId){
            getGameById(grid.gameId).then(game => {
                if(game && game.rowTeamScores && game.rowTeamScores.length>0 && game.columnTeamScores && game.columnTeamScores.length>0){
                    // console.log(`updating score for game ${grid.gameId} rowTeamScores ${game.rowTeamScores}`);
                    // console.log(game.rowTeamScores);
                    // console.log(game.columnTeamScores);
                    setGridScores(grid, game.rowTeamScores, game.columnTeamScores);
                }
            });
        }
    };

    const quarter = Math.min(grid.rowTeamScores.length + 1, 5);
    return (<div className="grid-control">
            {/*<InvitePlayersButton />*/}
            <GenerateRandomNumbersButton/>
            <StartGameButton/>
            <StartNextQuarterButton/>
            {
                grid.isOver
                ? <span>The game has finished</span>
                : null
            }
            {
                userIsGridAdmin(currentUser, grid) && grid.isStarted && !grid.isOver
                    ? <div>
                        <span>Do you want to update the scores using the final results? </span>
                        <div>
                            <button onClick={() => updateGridScoresFromGameResults()}>Update from live scores</button>
                        </div>
                    </div>
                    : null
            }
        </div>

    );
};

const mapDispatchToProps = dispatch => ({
    startGame: grid => dispatch(startGame(grid)),
    startNextQuarter: grid => dispatch(startNextQuarter(grid)),
    endGame: grid => dispatch(endGame(grid)),
    generateRandomRowAndColumnNumbers: grid => dispatch(generateRandomRowAndColumnNumbers(grid)),
    setGridScores: (grid, rowTeamScoreDelta, columnTeamScoreDelta) => dispatch(setGridScores(grid, rowTeamScoreDelta, columnTeamScoreDelta)),
});


const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(GameControl);
