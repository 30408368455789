import React, {useEffect, useState} from "react";
import {createStructuredSelector} from "reselect";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import FormInput from "../../component/form-input/form-input.component";
import {selectCurrentUser} from "../../redux/user/user.selector";
import {firestore} from "../../firebase/firebase.utils";
import FormSelect from "../../component/form-select/form-select.component";
import Snackbar from "@material-ui/core/Snackbar";
import CustomButton from "../../component/custom-button/custom-button.component";
import MuiAlert from "@material-ui/lab/Alert";
import {isSuperAdmin} from "../../redux/user/user.utils";
import {getCurrentNFLSeasonStr} from "../../utils";

const CreateGame = ({currentUser}) => {

    const [teams, setTeams] = useState([]);
    const [team1, setTeam1] = useState("");
    const [team2, setTeam2] = useState("");
    const [league, setLeague] = useState("NFL");

    const [snackbarAlertOpen, setSnackbarAlertOpen] = useState(false);
    const [formSubmitMessages, setFormSubmitMessages] = useState({
        successMessage: null,
        errorMessage: null,
    });

    useEffect(() => {
        //TODO move to firebase.utils.js
            firestore.collection("teams").where("league", "==", league).onSnapshot(async snapshot => {
                // if (!snapshot.empty && league != null) {
                //     snapshot.docs
                //         .map(elm => elm.data())
                //         .filter(teamData => {
                //             return teamData.league === league;
                //         })
                //         .map(teamData => teams.push(teamData));
                // }
                if (!snapshot.empty && league != null) {
                   await setTeams(snapshot.docs.map(elm => elm.data()));
                    console.log(`teams: ${teams}`);
                }
            });
    }, [league]);

    const [ newGameValues, setNewGameValues ] = useState({
        team1: {},
        team2: {},
        day: 10,
        month: 9,
        hour: 10,
        minute: 0,
        year: 2023,
        season: getCurrentNFLSeasonStr(),
        league: league,
        week: 1,
        stadium: '',
        amazonWatchLink: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        if(["year", "month", "day", "hour", "minute", "week"].includes(name)){
            setNewGameValues({ ...newGameValues, [name]: parseInt(value)});
        }else{
            setNewGameValues({ ...newGameValues, [name]: value});
        }
    };

    const handleSelectLeague = (event) => {
        const {name, value} = event.target;
        setLeague(value);
    }

    const handleSelectTeam1 = (event) => {
        // eslint-disable-next-line no-unused-vars
        const { name, value } = event.target;
        teams.filter(team => team.name === value).forEach(team => {
           setTeam1(team.name);
        });
    };

    const handleSelectTeam2 = (event) => {
        // eslint-disable-next-line no-unused-vars
        const { name, value } = event.target;
        teams.filter(team => team.name === value).forEach(team => {
            setTeam2(team.name);
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if(team1.trim() === "" || team2.trim().length === 0){
            setFormSubmitMessages({
                errorMessage: "Please select a team cannot be empty",
                successMessage: null
            });
            setSnackbarAlertOpen(true);
            return ;
        }
        // setNewGridValues({...newGridValues, name: newGridValues.name.trim()});

        createGame().then(() => {
            setFormSubmitMessages({
                errorMessage: null,
                successMessage: "game created"
            });
            setSnackbarAlertOpen(true);
        }).catch(error => {
            console.error("an error occurred while creating a game", error);
            setFormSubmitMessages({
                errorMessage: "an error has occurred, please try again",
                successMessage: null,
            });
            setSnackbarAlertOpen(true);
        });
    };

    const createGame = async () => {
        if(team1 === "" && team2 === ""){
            alert("Please select the 2 teams of this game");
            return;
        }
        if(team1 === ""){
            alert("Please select the visitor team");
            return;
        }
        if(team2 === ""){
            alert("Please select the host team");
            return;
        }
        if(team1 === team2){
            alert("Please select different teams");
            return;
        }
        let firstTeamArr = teams.filter(team => team.name === team1);
        let firstTeam = firstTeamArr.length > 0 ? firstTeamArr[0] : {} ;
        let secondTeamArr = teams.filter(team => team.name === team2);
        let secondTeam = secondTeamArr.length > 0 ? secondTeamArr[0] : {} ;
        let startTime = new Date(newGameValues.year, newGameValues.month-1, newGameValues.day, newGameValues.hour, newGameValues.minute, 0);
        let game = {
            ...newGameValues,
            team1: firstTeam,
            team2: secondTeam,
            city: secondTeam.city,
            stadium: secondTeam.stadium,
            sport: 'American Football',
            league: league,
            week: parseInt(newGameValues.week),
            //the month is 0-indexed
            startTime: startTime,
            // startTime: new Date(startTime.getTime() + (startTime.getTimezoneOffset()*60*1000)),
        };
        const gameRef = firestore.collection("games");
        return gameRef.add(game)
            // .then(docRef => {
            // console.log("game created with id: ", docRef.id);
            // console.log(docRef);
            // })
            .catch(error => {
            console.error("Error adding document: ", error);
        });
    };

    const handleSnackbarAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarAlertOpen(false);
        setFormSubmitMessages({
            errorMessage: null,
            successMessage: null
        });
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    if(!currentUser){
        return (<div>
            <p>You have to log in first</p>
        </div>)
    }

    if(!isSuperAdmin(currentUser)){
        return (<div>
            <p>Unauthorized</p>
        </div>)
    }

    let leaguesForSelect = [{name: "NFL", fullName: "NFL"},{name: "NCAAF", fullName: "NCAAF - College Football"}];
    let teamsForSelect = [{name: "", fullName: "-----------"}, ...teams.sort((t1,t2) => {
        const name1 = t1.name.toUpperCase();
        const name2 = t2.name.toUpperCase();

        if (name1 < name2) {
            return -1;
        }
        if (name1 > name2) {
            return 1;
        }
        return 0;
    })];
    return (<div className="create-grid-form">
            <h2>Create a new game</h2>
            <div >
                <form onSubmit={handleSubmit}>
                    <FormInput label="season" type="text" disabled={true} name="season" value={newGameValues.season} />
                    <FormSelect label="League:" value={league} handleChange={handleSelectLeague} options={leaguesForSelect.map(league => {return {value: league.name, name: league.fullName}})} />
                    <FormInput label="week" type="number" name="week" value={newGameValues.week} handleChange={handleChange}/>
                    <FormSelect label="Team 1 (visitors):" value={team1} handleChange={handleSelectTeam1} options={teamsForSelect.map(team => {return {value: team.name, name: team.name}})} />
                    <FormSelect label="Team 2 (hosts):" value={team2} handleChange={handleSelectTeam2} options={teamsForSelect.map(team => {return {value: team.name, name: team.name}})} />
                    <FormInput label="year" type="number" name="year" value={newGameValues.year} handleChange={handleChange}/>
                    <FormInput label="month" type="number" name="month" value={newGameValues.month} handleChange={handleChange}/>
                    <FormInput label="day" type="number" name="day" value={newGameValues.day} handleChange={handleChange}/>
                    <FormInput label="hour" type="number" name="hour" value={newGameValues.hour} handleChange={handleChange}/>
                    <FormInput label="minute" type="number" name="minute" value={newGameValues.minute} handleChange={handleChange}/>
                    <FormInput label="amazonWatchLink" type="text" name="amazonWatchLink" value={newGameValues.amazonWatchLink} handleChange={handleChange}/>
                    <div className="buttons">
                        <div className="form-submit-message">
                            <span className="form-submit-error-message">{formSubmitMessages.errorMessage}</span>
                            <span className="form-submit-success-message">{formSubmitMessages.successMessage}</span>
                        </div>
                        <Snackbar open={snackbarAlertOpen} autoHideDuration={6000} onClose={handleSnackbarAlertClose}>
                            <Alert onClose={handleSnackbarAlertClose} severity={`${formSubmitMessages.errorMessage ? 'error' : 'success'}`}>
                                {formSubmitMessages.errorMessage ? formSubmitMessages.errorMessage : formSubmitMessages.successMessage}
                            </Alert>
                        </Snackbar>
                        <CustomButton type="submit" disabled={false}>
                            Create Game
                        </CustomButton>
                    </div>
                </form>
                {/*<Button color="inherit" style={{'background-color': 'lightslategrey'}} onClick={() => {createGame().then(r => console.log("ok"));}}>Create Game</Button>*/}
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

export default withRouter(connect(mapStateToProps)(CreateGame));
