// new season starting from June
export const newNFLSeasonStartMonth = 6;

//TODO update dates for 2024
export const weeklyEndDatesNFL2024 = [new Date(),
    new Date("2024-09-12"),// week 1
    new Date("2024-09-19"),// week 2
    new Date("2024-09-26"),// week 3
    new Date("2024-10-03"),// week 4
    new Date("2024-10-10"),// week 5
    new Date("2024-10-17"),// week 6
    new Date("2024-10-24"),// week 7
    new Date("2024-10-31"),// week 8
    new Date("2024-11-07"),// week 9
    new Date("2024-11-14"),// week 10
    new Date("2024-11-21"),// week 11
    new Date("2024-11-28"),// week 12
    new Date("2024-12-05"),// week 13
    new Date("2024-12-12"),// week 14
    new Date("2024-12-20"),// week 15
    new Date("2024-12-26"),// week 16
    new Date("2025-01-01"),// week 17
    new Date("2025-01-9"),// week 18
    new Date("2025-01-16"),// Wild Card week
    new Date("2025-01-23"),// Divisional Round week
    new Date("2025-01-30"),// Conference Championships week
    new Date("2025-02-13"),// SuperBowl week
];
//TODO update dates for 2024
export const weeklyEndDatesNCAAF2024 = [new Date(),
    new Date("2024-09-05"),// week 1
    new Date("2024-09-10"),// week 2
    new Date("2024-09-17"),// week 3
    new Date("2024-09-24"),// week 4
    new Date("2024-10-01"),// week 5
    new Date("2024-10-08"),// week 6
    new Date("2024-10-15"),// week 7
    new Date("2024-10-22"),// week 8
    new Date("2024-10-29"),// week 9
    new Date("2024-11-05"),// week 10
    new Date("2024-11-23"),// week 11
    new Date("2024-11-19"),// week 12
    new Date("2024-11-26"),// week 13
    new Date("2024-12-03"),// week 14
    new Date("2024-12-10"),// week 15
    new Date("2025-01-09"),// Bowls week
];

export const weeklyEndDatesNFL2023 = [new Date(),
    new Date("2023-09-12"),// week 1
    new Date("2023-09-19"),// week 2
    new Date("2023-09-26"),// week 3
    new Date("2023-10-03"),// week 4
    new Date("2023-10-10"),// week 5
    new Date("2023-10-17"),// week 6
    new Date("2023-10-24"),// week 7
    new Date("2023-10-31"),// week 8
    new Date("2023-11-07"),// week 9
    new Date("2023-11-14"),// week 10
    new Date("2023-11-21"),// week 11
    new Date("2023-11-28"),// week 12
    new Date("2023-12-05"),// week 13
    new Date("2023-12-12"),// week 14
    new Date("2023-12-20"),// week 15
    new Date("2023-12-26"),// week 16
    new Date("2024-01-01"),// week 17
    new Date("2024-01-9"),// week 18
    new Date("2024-01-16"),// Wild Card week
    new Date("2024-01-23"),// Divisional Round week
    new Date("2024-01-30"),// Conference Championships week
    new Date("2024-02-13"),// SuperBowl week
];
export const weeklyEndDatesNCAAF2023 = [new Date(),
    new Date("2023-09-04"),// week 1
    new Date("2023-09-10"),// week 2
    new Date("2023-09-17"),// week 3
    new Date("2023-09-24"),// week 4
    new Date("2023-10-01"),// week 5
    new Date("2023-10-08"),// week 6
    new Date("2023-10-15"),// week 7
    new Date("2023-10-22"),// week 8
    new Date("2023-10-29"),// week 9
    new Date("2023-11-05"),// week 10
    new Date("2023-11-23"),// week 11
    new Date("2023-11-19"),// week 12
    new Date("2023-11-26"),// week 13
    new Date("2023-12-03"),// week 14
    new Date("2023-12-10"),// week 15
    new Date("2024-01-09"),// Bowls week
];

export const weeklyEndDatesNFL2022 = [new Date(),
    new Date("2023-09-11"),// week 1
    new Date("2023-09-18"),// week 2
    new Date("2023-09-25"),// week 3
    new Date("2023-10-02"),// week 4
    new Date("2023-10-09"),// week 5
    new Date("2023-10-16"),// week 6
    new Date("2023-10-23"),// week 7
    new Date("2023-10-30"),// week 8
    new Date("2023-11-06"),// week 9
    new Date("2023-11-14"),// week 10
    new Date("2023-11-21"),// week 11
    new Date("2023-11-28"),// week 12
    new Date("2023-12-05"),// week 13
    new Date("2023-12-12"),// week 14
    new Date("2023-12-19"),// week 15
    new Date("2023-12-26"),// week 16
    new Date("2024-01-03"),// week 17
    new Date("2024-01-09"),// week 18
    new Date("2024-01-16"),// Wild Card week
    new Date("2024-01-23"),// Divisional Round week
    new Date("2024-01-30"),// Conference Championships week
    new Date("2024-02-12"),// SuperBowl week
];

// TODO update to correct dates
export const weeklyEndDatesNCAAF2022 = [new Date(),
    new Date("2023-09-04"),// week 1
    new Date("2023-09-10"),// week 2
    new Date("2023-09-17"),// week 3
    new Date("2023-09-24"),// week 4
    new Date("2023-10-01"),// week 5
    new Date("2023-10-08"),// week 6
    new Date("2023-10-15"),// week 7
    new Date("2023-10-22"),// week 8
    new Date("2023-10-29"),// week 9
    new Date("2023-11-05"),// week 10
    new Date("2023-11-23"),// week 11
    new Date("2023-11-19"),// week 12
    new Date("2023-11-26"),// week 13
    new Date("2023-12-03"),// week 14
    new Date("2023-12-10"),// week 15
    new Date("2024-01-09"),// Bowls week
];
