import GameActionTypes from "./game.types";

const INITIAL_STATE = {
    currentGame: null,
    homePageGrids: [],
    homePageGames: [],
};

const gameReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GameActionTypes.UPDATE_CURRENT_GAME:
            return {
                ...state,
                currentGame: action.payload,
            };
        case GameActionTypes.UPDATE_HOME_PAGE_GRIDS:
            return {
                ...state,
                homePageGrids: action.payload,
            };
        case GameActionTypes.UPDATE_HOME_PAGE_GAMES:
            return {
                ...state,
                homePageGames: action.payload,
            };
        default:
            return state;
    }
};

export default gameReducer;