import React, {useEffect, useState} from "react";
import {getGamesByWeek} from "../../firebase/firebase.utils";
import {createStructuredSelector} from "reselect";
import {connect} from "react-redux";
import {selectHomePageGames} from "../../redux/game/game.selectors";
import GameOverview from "../game-overview/game-overview.component";
import './week-games.styles.scss';
import {getNFLWeekName} from "../../utils";
import {getGameStartTimeComparator} from "../../redux/game/game.utils";
import {SpinnerContainer, SpinnerOverlay} from "../with-spinner/with-spinner.styles";

const WeekGames = ({week, year, league}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [games, setGames] = useState([]);
    const [searchField, setSearchField] = useState("");

    useEffect( () => {
            async function fetchData(){
                let nflGamesByWeek = await getGamesByWeek(year+'/'+(year+1), week, league);// season: '2022/2023'
                nflGamesByWeek.sort(getGameStartTimeComparator());
                setGames(nflGamesByWeek);
                setIsLoading(false);
            }
            fetchData();
        }
        , [year, week, league]);

    function isGameIncludesTeamWithSearchedName(game) {
        return game.team1.fullName.toLowerCase().includes(searchField) || game.team2.fullName.toLowerCase().includes(searchField);
    }

    const filteredGames = games.filter(game => (isGameIncludesTeamWithSearchedName(game)));

    return (<div className="games-list">
        <h2>{getNFLWeekName(week)}'s Games:</h2>
            <div className="group" style={{width: '150px', margin: '0px'}}>
                <input className="form-input" placeholder="Search team" onChange={(event => {
                    setSearchField(event.target.value.toLowerCase());
                })}  />
            </div>
        {(isLoading
        ? (<SpinnerOverlay>
        <SpinnerContainer/>
    </SpinnerOverlay>)
        : (<div>
        {
            filteredGames.map(game => <GameOverview key={game.id} game={game} /> )
        }
    </div>))}

    </div>)
};

const mapStateToProps = createStructuredSelector({
    homePageGames: selectHomePageGames,
});

export default connect(mapStateToProps)(WeekGames);
