import React, {useState} from "react";

import './square.styles.scss';
import {createStructuredSelector} from "reselect";
import {selectCurrentUser} from "../../redux/user/user.selector";
import {connect} from "react-redux";
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import {
    getNumberOfPicksByUser,
    getWinningQ1SquareId,
    getWinningQ2SquareId, getWinningQ3SquareId, getWinningQ4SquareId,
    lastDigitOfScore
} from "../../redux/grid/grid.utils";
import {selectGrid} from "../../redux/grid/grid.selectors";

const Square = ({key, order, userInSquare, players, isAdmin, isPaid, showRemoveButton, showSquareNumbers, gameIsStarted,
                    selectSquareHandler, selectSquare, removeSquareHandler, changeSquarePaidStatusHandler, currentUser, grid}) => {


    const RemoveSquareButton = () => {
        if(gameIsStarted || (!isAdmin && !showRemoveButton) || !userInSquare){
            return null
        }
        return <button onClick={() => removeSquareHandler()}> x </button>;
    };

    const SelectSquareButton = () => {
        if(gameIsStarted || userInSquare){
            return null
        }

        // if(isAdmin){
        //     return null;
        // }

        // if(isEmptyForUser() && !showSquareNumbers){
        //     return <Tooltip title="select square"><label className="square-content">{order}</label></Tooltip>
        // }
        return <button onClick={() => selectSquareHandler()}>select</button>;
    };

    const SelectSquareForUserButton = () => {
        const [selectValue, setSelectValue] = useState(' ');

        if(gameIsStarted || !isAdmin || userInSquare){
            return null
        }

        if(!currentUser){
            return null;
        }

        const onSelectChangeHandler = event => {
            const email = event.target.value;
            setSelectValue(event.target.value);
            if(email === '') return;
            selectSquare(players.filter(player => player.email === email)[0]);
        };

        return <Select onChange={onSelectChangeHandler} value={selectValue} id={`select-user-for-square-${order}`}>
            <MenuItem key={`${key}-empty`} value=' '>---</MenuItem>
            {
                players
                    .map(player => <MenuItem key={`${key}-${player.email}`} value={player.email}>{player.displayName} - {player.email} ({getNumberOfPicksByUser(grid, player)}/{grid.maxPicksPerUser})</MenuItem>)
            }
        </Select>;
    };

    const MarkAsPaidButton = () => {
        if(gameIsStarted || !isAdmin || !userInSquare || isPaid){
            return null
        }
        return <button onClick={() => changeSquarePaidStatusHandler()}>mark {isPaid? 'unpaid': 'paid'}</button>;
    };

    const UserInSquareInfo = () => {
        if(!userInSquare){
            return null;
        }
        return (<div>
            <b>{userInSquare.displayName
            ? userInSquare.displayName
            : userInSquare.email}</b>
        </div>)
    };

    const onSquareClick = () => {
        if(isEmptyForUser()){
            selectSquareHandler();
        }
    };

    function isEmptyForUser() {
        return !userInSquare && !isAdmin;
    }

    function inRowOrColumnClassName(squareId) {
        const rowTeamTotalGameScore = grid.rowTeamScores.reduce((totalScore, score) => totalScore + score, 0);
        const columnTeamTotalGameScore = grid.columnTeamScores.reduce((totalScore, score) => totalScore + score, 0);
        let squareRowId = squareId.charAt(0);//TODO verify order. IS this row or column?
        let squareColumnId = squareId.charAt(1);
        let squareRow = grid.rowNumbers[squareRowId];
        let squareColumn = grid.columnNumbers[squareColumnId];
        let lastDigitOfRowScore = parseInt(lastDigitOfScore(rowTeamTotalGameScore));
        let lastDigitOfColumnScore = parseInt(lastDigitOfScore(columnTeamTotalGameScore));
        if(! grid.isStarted) return;
        if(lastDigitOfColumnScore === squareColumn && lastDigitOfRowScore === squareRow){
            return 'inRowAndColumn'
        }
        if(lastDigitOfColumnScore === squareColumn || lastDigitOfRowScore === squareRow){
            return 'inRowOrColumn'
        }
    }

    function isWinnerSquare(squareId){
        return squareId === getWinningQ1SquareId(grid) || squareId === getWinningQ2SquareId(grid) || squareId === getWinningQ3SquareId(grid) || squareId === getWinningQ4SquareId(grid);

    }

    const SquareContent = ({isPaid, showSquareNumbers, showMarkSquarePaidButton, order}) => {
        return (
            <div className={`grid-square ${(isPaid && !grid.isStarted) ? 'paid' : null} ${isEmptyForUser() ? 'emptySquareForUser' : null}  ${inRowOrColumnClassName(order)} ${isWinnerSquare(order)? 'winnerSquare' : null}`} onClick={() => onSquareClick()}>
                <UserInSquareInfo/>
                <SelectSquareForUserButton/>
                <SelectSquareButton/>
                <RemoveSquareButton/>
                {
                    showMarkSquarePaidButton ? <MarkAsPaidButton/> : null
                }

                {
                    showSquareNumbers
                        ? <p>{order}</p>
                        : null
                }
        </div>);
    };

    return (<SquareContent isPaid={isPaid} showSquareNumbers={showSquareNumbers} showMarkSquarePaidButton={false} order={order}/>);
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    grid: selectGrid,
});

export default connect(mapStateToProps)(Square);