import React from "react";
import './score-board.styles.scss';
import {range} from "../../utils";

const ScoreBoard = ({rowTeamName, columnTeamName, rowTeamScores, columnTeamScores, showFullScoreOnly}) => {

    const rowTeamTotalGameScore = rowTeamScores.reduce((totalScore, score) => totalScore + score, 0);
    const columnTeamTotalGameScore = columnTeamScores.reduce((totalScore, score) => totalScore + score, 0);

    const rowTeamTotalScoresByQuarter = [];
    let rowTeamScoreCounter = 0;
    for(let i = 0; i<rowTeamScores.length; i++){
        rowTeamScoreCounter += rowTeamScores[i];
        rowTeamTotalScoresByQuarter[i] = rowTeamScoreCounter;
    }
    const columnTeamTotalScoresByQuarter = [];
    let columnTeamScoreCounter = 0;
    for(let i = 0; i<rowTeamScores.length; i++){
        columnTeamScoreCounter += columnTeamScores[i];
        columnTeamTotalScoresByQuarter[i] = columnTeamScoreCounter;
    }

    let numberOfGamePeriodsFromScores = Math.min(rowTeamTotalScoresByQuarter.length, columnTeamTotalScoresByQuarter.length);
    const NUMB_PERIODS_IN_REGULAR_GAME = 4;// 4 QUARTERS
    let numberOfGamePeriods = Math.max(NUMB_PERIODS_IN_REGULAR_GAME, numberOfGamePeriodsFromScores);
    return (<div>
        {/*{showFullScoreOnly ? null : <h2>Scoreboard:</h2>}*/}
           <table align='center' cellSpacing='0' cellPadding='2' style={{borderColor: '#C0C0C0'}} border='1'>
               <thead>
               <tr>
                   <th id="score-board-th-team-Left">
                       <span>{rowTeamName} </span>
                   </th>
                   <th className="th-score">
                       <span>{rowTeamScores.length > 0 ? rowTeamTotalGameScore : '0'}</span>
                   </th>
                   <th className="th-score">
                       <span>{columnTeamScores.length > 0 ? columnTeamTotalGameScore : '0'}</span>
                   </th>
                   <th id="score-board-th-team-Right">
                       <span> {columnTeamName}</span>
                   </th>
               </tr>
               </thead>

               <tbody>
               {
                   showFullScoreOnly ?
                       null
                       :
                   range(numberOfGamePeriods).map(i => {
                       return (
                           <tr>
                               <th>
                                   <span>{i<4?`Q${i+1}`:`OT`}  </span>
                               </th>
                               <td>
                                   <div className="scoreDiv">{rowTeamTotalScoresByQuarter[i]} {(i > 0 && rowTeamScores.length>=i+1) ? <div className="smallText">+{rowTeamScores[i]}</div> : null}</div>
                               </td>
                               <td>
                                   <div className="scoreDiv">{columnTeamTotalScoresByQuarter[i]} {(i > 0 && columnTeamScores.length>=i+1) ? <div className="smallText">+{columnTeamScores[i]}</div> : null}</div>
                               </td>
                               <th>
                                   <span> {i<4?`Q${i+1}`:`OT`}</span>
                               </th>
                           </tr>
                       );
                   })
               }
               </tbody>
           </table>
        <br/>
       </div>)
};

export default ScoreBoard;
