import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import store from "./redux/store";
import {Provider} from "react-redux";

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-MLXS52XV',
    configs: {
        "AW-1002199921": { "groups": "default" },

    },
    vars: { "gtag_id": "AW-1002199921"},
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
   , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
