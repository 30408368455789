import React from "react";
import {
    getWinningQ1SquareId, getWinningQ2SquareId,
    getWinningQ3SquareId,
    getWinningQ4SquareId,
} from "../../redux/grid/grid.utils";
import {getUserFromPicks} from "../../redux/user/user.utils";

import './grid-winners.styles.scss';

const SquareWinners = ({players, grid}) => {
    if (!grid.isStarted) {
        return null;
    }
    // let isGameOver = grid.isOver;
    let columnTeamScores = grid.columnTeamScores;
    let rowTeamScores = grid.rowTeamScores;

    const numberOfQuartersPlayed = Math.min(rowTeamScores.length, columnTeamScores.length);

    const q1Winner = getUserFromPicks(players, grid.picks, getWinningQ1SquareId(grid));
    const q2Winner = getUserFromPicks(players, grid.picks, getWinningQ2SquareId(grid));
    const q3Winner = getUserFromPicks(players, grid.picks, getWinningQ3SquareId(grid));
    const q4Winner = getUserFromPicks(players, grid.picks, getWinningQ4SquareId(grid));

    return (
        (<div className="winners">
            <h2>Winners:</h2>
            <div className="winners-list">
                <table>
                    <tbody>
                    {
                        numberOfQuartersPlayed > 0
                            ? (<tr>
                                <th>Q1:</th>
                                <td><span>{q1Winner ? q1Winner.displayName : null}</span></td>
                            </tr>)
                            : null
                    }
                    {
                        numberOfQuartersPlayed > 1
                            ? (<tr>
                                <th>Q2:</th>
                                <td><span>{q2Winner ? q2Winner.displayName : null}</span></td>
                            </tr>)
                            : null
                    }
                    {
                        numberOfQuartersPlayed > 2
                            ? (<tr>
                                <th>Q3:</th>
                                <td><span>{q3Winner ? q3Winner.displayName : null}</span></td>
                            </tr>)
                            : null
                    }
                    {
                        numberOfQuartersPlayed > 3
                            ? (<tr>
                                <th>Q4:</th>
                                <td><span>{q4Winner ? q4Winner.displayName : null}</span></td>
                            </tr>)
                            : null
                    }
                    </tbody>
                </table>
            </div>
        </div>)
    );
};

export default SquareWinners;