import GridActionTypes from "./grid.types";
import {addSquareSelectionToGrid} from "./grid.utils";

export const updateGrid = (grid) => ({
    type: GridActionTypes.UPDATE_GRID,
    payload: grid
});

// Warning this only update a different reducer field not grid.players - To be deleted
export const updateGridPlayers = players => ({
    type: GridActionTypes.UPDATE_GRID_PLAYERS,
    payload: players
});

export const generateRandomRowAndColumnNumbers = grid => ({
    type: GridActionTypes.SELECT_RANDOM_ROW_AND_COLUMN_NUMBERS,
    payload: grid,
});

export const selectRandomRowAndColumnNumbersSuccess = () => ({
    type: GridActionTypes.SELECT_RANDOM_ROW_AND_COLUMN_NUMBERS_SUCCESS,
});

export const selectRandomRowAndColumnNumbersFailure = (errorMessage) => ({
    type: GridActionTypes.SELECT_RANDOM_ROW_AND_COLUMN_NUMBERS_FAILURE,
    payload: errorMessage,
});

export const setSelectedRowAndColumnNumbers = (grid, rowNumbers, columnNumbers) => ({
    type: GridActionTypes.SET_SELECTED_ROW_AND_COLUMN_NUMBERS,
    payload: {grid, rowNumbers, columnNumbers},
});

export const setSelectedRowAndColumnNumbersSuccess = () => ({
    type: GridActionTypes.SET_SELECTED_ROW_AND_COLUMN_NUMBERS_SUCCESS,
});

export const setSelectedRowAndColumnNumbersFailure = (errorMessage) => ({
    type: GridActionTypes.SET_SELECTED_ROW_AND_COLUMN_NUMBERS_FAILURE,
    payload: errorMessage,
});

export const startGame = (grid) => ({
    type: GridActionTypes.START_GAME,
    payload: grid
});

export const startGameSuccess = () => ({
    type: GridActionTypes.START_GAME_SUCCESS,
});

export const startGameFailure = errorMessage => ({
    type: GridActionTypes.START_GAME_FAILURE,
    payload: errorMessage
});

export const endGame = (grid) => ({
    type: GridActionTypes.END_GAME,
    payload: grid
});

export const endGameSuccess = () => ({
    type: GridActionTypes.END_GAME_SUCCESS,
});

export const endGameFailure = errorMessage => ({
    type: GridActionTypes.END_GAME_FAILURE,
    payload: errorMessage
});

export const startNextQuarter = (grid) => ({
    type: GridActionTypes.START_NEXT_QUARTER,
    payload: grid,
});

export const startNextQuarterSuccess = () => ({
    type: GridActionTypes.START_NEXT_QUARTER_SUCCESS,
});

export const startNextQuarterFailure = errorMessage => ({
    type: GridActionTypes.START_NEXT_QUARTER_FAILURE,
    payload: errorMessage,
});

//TODO remame to incrementScore
export const updateScore = (grid, rowTeamScoreDelta, columnTeamScoreDelta) => ({
    type: GridActionTypes.UPDATE_SCORE,
    payload: {gridId: grid.id, rowTeamScores: grid.rowTeamScores, columnTeamScores: grid.columnTeamScores, rowTeamScoreDelta, columnTeamScoreDelta}
});

export const updateScoreSuccess = () => ({
    type: GridActionTypes.UPDATE_SCORE_SUCCESS,
});

export const updateScoreFailure = errorMessage => ({
    type: GridActionTypes.UPDATE_SCORE_FAILURE,
    payload: errorMessage
});

export const setGridScores = (grid, rowTeamScores, columnTeamScores) => ({
    type: GridActionTypes.SET_GRID_SCORES,
    payload: {gridId: grid.id, rowTeamScores: rowTeamScores, columnTeamScores: columnTeamScores}
});

export const setGridScoresSuccess = () => ({
    type: GridActionTypes.SET_GRID_SCORES_SUCCESS,
});

export const setGridScoresFailure = (errorMessage) => ({
    type: GridActionTypes.SET_GRID_SCORES_FAILURE,
    payload: errorMessage,
});

export const updateUserGrids = (grids) => ({
    type: GridActionTypes.UPDATE_USER_GRIDS,
    payload: grids,
});

export const updateFeaturedGrids = (grids) => ({
    type: GridActionTypes.UPDATE_FEATURED_GRIDS,
    payload: grids,
});

export const selectSquare = (grid, squareId, user) => ({
    type: GridActionTypes.SELECT_SQUARE,
    payload: addSquareSelectionToGrid(grid, squareId, user)
});

export const startFetchGrid = (gridId) => ({
    type: GridActionTypes.FETCH_GRID_START,
    payload: gridId,
});

export const fetchGridSuccess = grid => ({
    type: GridActionTypes.FETCH_GRID_SUCCESS,
    payload: grid
});

export const fetchGridFailure = errorMessage => ({
    type: GridActionTypes.FETCH_GRID_FAILURE,
    payload: errorMessage,
});

export const selectGridSquare = (gridId, squareId, user) => ({
    type: GridActionTypes.USER_SELECTS_GRID_SQUARE,
    payload: {gridId, squareId, user},
});

export const selectGridSquareSuccess = () => ({
    type: GridActionTypes.USER_SELECTS_GRID_SQUARE_SUCCESS,
});

export const selectGridSquareFailure = errorMessage => ({
    type: GridActionTypes.USER_SELECTS_GRID_SQUARE_FAILURE,
    payload: errorMessage,
});

export const removeGridSquare = (gridId, squareId, user) => ({
    type: GridActionTypes.USER_REMOVES_GRID_SQUARE,//todo rename?
    payload: {gridId, squareId, user},
});

export const removeGridSquareSuccess = () => ({
    type: GridActionTypes.USER_REMOVES_GRID_SQUARE_SUCCESS,
});

export const removeGridSquareFailure = errorMessage => ({
    type: GridActionTypes.USER_REMOVES_GRID_SQUARE_FAILURE,
    payload: errorMessage,
});

export const markGridSquarePaidStatus = (gridId, squareId, user, paidStatus) => ({
    type: GridActionTypes.CHANGE_GRID_SQUARE_PAID_STATUS,
    payload: {gridId, squareId, user, paidStatus},
});

export const markGridSquarePaidSuccess = () => ({
    type: GridActionTypes.CHANGE_GRID_SQUARE_PAID_STATUS_SUCCESS,
});

export const markGridSquarePaidFailure = errorMessage => ({
    type: GridActionTypes.CHANGE_GRID_SQUARE_PAID_STATUS_FAILURE,
    payload: errorMessage,
});

export const markAllPlayerSquaresPaidStatus = (gridId, player, user, paidStatus) => ({
    type: GridActionTypes.CHANGE_ALL_PLAYER_SQUARES_PAID_STATUS,
    payload: {gridId, player, user, paidStatus},
});

export const markAllPlayerSquaresPaidStatusSuccess = () => ({
    type: GridActionTypes.CHANGE_ALL_PLAYER_SQUARES_PAID_STATUS_SUCCESS,
});

export const markAllPlayerSquaresPaidStatusFailure = errorMessage => ({
    type: GridActionTypes.CHANGE_ALL_PLAYER_SQUARES_PAID_STATUS_FAILURE,
    payload: errorMessage,
});

export const updateGridPlayerName = (grid, player, newPlayerName) => ({
   type: GridActionTypes.UPDATE_GRID_PLAYER_NAME,
   payload: {grid, player, newPlayerName},
});

export const updateGridPlayerNameSuccess = (picks) => ({
    type: GridActionTypes.UPDATE_GRID_PLAYER_NAME_SUCCESS,
    payload: picks,

});

export const updateGridPlayerNameFailure = errorMessage => ({
    type: GridActionTypes.UPDATE_GRID_PLAYER_NAME_FAILURE,
    payload: errorMessage,
});