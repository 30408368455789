import GridActionTypes from "./grid.types";
import {deduplicateUsers} from "../user/user.utils";

const INITIAL_STATUS = {
    grid: null,
    isFetching: false,
    errorMessage: undefined,
    players: [],//todo: not used, remove?
    userGrids: [],//todo: move to user reducer
    featuredGrids: [],
};

const gridReducer = (state = INITIAL_STATUS, action) => {
    switch (action.type) {
        case GridActionTypes.UPDATE_GRID:
            return {
                ...state,
                grid: action.payload,
                players: [...deduplicateUsers(Object.values(action.payload.picks))],
            };
        // Warning this only update a different reducer field not grid.players - to be deleted
        case GridActionTypes.UPDATE_GRID_PLAYERS:
            return {
                ...state,
                players: [...action.payload]
            };
        case GridActionTypes.UPDATE_GRID_PLAYER_NAME_SUCCESS:
            console.log("*reducer: UPDATE_GRID_PLAYER_NAME_SUCCESS");
            return {
                ...state,
                grid: {...state.grid, picks: action.payload}
            };
        case GridActionTypes.UPDATE_USER_GRIDS:
            return{
                ...state,
                userGrids: action.payload,
            };
        case GridActionTypes.UPDATE_FEATURED_GRIDS:
            return{
                ...state,
                featuredGrids: action.payload,
            };
        case GridActionTypes.SELECT_SQUARE:
            return {
                ...state,
                grid: {...state.grid, picks: action.payload}
            };
        case GridActionTypes.FETCH_GRID_START:
            return {
                ...state,
                isFetching: true,
            };
        case GridActionTypes.FETCH_GRID_SUCCESS:
            return {
                ...state,
                isFetching: false,
                grid: action.payload,
            };
        case GridActionTypes.FETCH_GRID_FAILURE:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload,
            };
        case GridActionTypes.USER_SELECTS_GRID_SQUARE_SUCCESS:
            return state;
        case GridActionTypes.USER_SELECTS_GRID_SQUARE_FAILURE:
            return {
                ...state,
                errorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default gridReducer;