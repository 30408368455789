import React from "react";
import GridOverview from "../grid-overview/grid-overview.component";
import {Link} from "react-router-dom";
import CustomButton from "../custom-button/custom-button.component";

const MyGrids = ({grids, user}) => {

    let userOwnedGrids = (grids) ? grids.filter(grid => !user || user.email === grid.adminUserEmail) : [];

    const createAGridButton = () => {
        if(userOwnedGrids.length > 0){
            return null;
        }
        return <div align='center'><Link to={`/create-grid`}> <CustomButton >Create a grid</CustomButton></Link></div>;
    };

    if(!grids || grids.length === 0){
        return <div>
            No Grids to show (create one)
        </div>
    }else{
        return (<div className="my-grids">
            <div>
                <h2>Your grids</h2>
                {/*<h3>As Owner:</h3>*/}
                <div className="grids-list">
                    {
                        userOwnedGrids.map(grid => (<GridOverview key={grid.id} grid={grid}/>))
                    }
                    {
                        createAGridButton()
                    }
                </div>

            </div>
            {/*<h3>As Player:</h3>*/}
            {/*<div className="grids-list">*/}
            {/*    {*/}
            {/*        grids.filter(grid => !user || user.email !== grid.adminUserEmail).map(grid => (<GridOverview key={grid.id} grid={grid}/>))*/}
            {/*    }*/}
            {/*</div>*/}
        </div>);
    }

};

export default MyGrids;
// export default connect(mapStateToProps)(MyGrids)