import React, {useState} from 'react'

import FormInput from "../form-input/form-input.component";
import CustomButton from "../custom-button/custom-button.component";

import './sign-up.styles.scss'
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {selectCurrentUser} from "../../redux/user/user.selector";
import {googleSignInStart, signUpStart} from "../../redux/user/user.actions";

const SignUp = ({currentUser, signUpStart, googleSignInStart}) => {

    const [userCredentials, setUserCredentials] = useState({
        displayName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const { displayName, email, password, confirmPassword } = userCredentials;

    const handleSubmit = async (event) => {
        event.preventDefault();

        if(password !== confirmPassword){
            alert("The passwords don't match");
            return;
        }
        signUpStart({email, password, displayName});
    };

    const handleChange = (event) => {
        const {name, value} = event.target;

        setUserCredentials({
            ...userCredentials,
            [name]: value
        })
    };

    if(currentUser){
        return (<div>
            <h2>You are logged in as {currentUser.displayName}</h2>
        </div>);
    }

    return (
        <div className="sign-up">
            <h2 className="title">I don't have an account</h2>
            <span>Sign up with your email and password</span>
            <div className="sign-up-form">
                <form onSubmit={handleSubmit}>
                    <FormInput
                        type="text"
                        name="displayName"
                        label="Full Name"
                        value={displayName}
                        onChange={handleChange}
                        required
                    />
                    <FormInput
                        type="email"
                        name="email"
                        label="Email"
                        value={email}
                        onChange={handleChange}
                        required
                    />
                    <FormInput
                        type="password"
                        name="password"
                        label="Password"
                        value={password}
                        onChange={handleChange}
                        required
                    />
                    <FormInput
                        type="password"
                        name="confirmPassword"
                        label="Confirm Password"
                        value={confirmPassword}
                        onChange={handleChange}
                        required
                    />

                    <div className="buttons">
                        <CustomButton type="submit">
                            Sign Up
                        </CustomButton>
                        <div style={{'align-self': 'center'}}>OR</div>
                        <CustomButton type='button' onClick={googleSignInStart} isGoogleSignIn>
                            Sign Up with Google
                        </CustomButton>
                    </div>

                </form>
            </div>

        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

const mapDispatchToProps = dispatch => ({
    signUpStart: ({email, password, displayName}) => dispatch(signUpStart({email, password, displayName})),
    googleSignInStart: () => dispatch(googleSignInStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

