import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import WeekGames from "../../component/week-games/week-games.component";

const WeekGamesPage = ({match}) => {
    // const [games, setGames] = useState([]);
    const [year, setYear] = useState(2022);
    const [week, setWeek] = useState(1);

    useEffect( () => {
            async function fetchData(){
                let year = parseInt(match.params.year);
                let week = parseInt(match.params.week);
                // let nflGamesByWeek = await getNFLGamesByWeek(year,week);

                setYear(year);
                setWeek(week);
                // setGames(nflGamesByWeek);
            }
            fetchData();
        }
        , [match.params]);

    return (<div>
        <h1>{year} NFL Week {week} Games</h1>
        <WeekGames />
        </div>);
};

export default withRouter(WeekGamesPage);