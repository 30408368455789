export const getGameStartTimeComparator = () => {
    return (g1, g2) => {
        if (g1.startTime < g2.startTime) {
            return -1;
        }
        if (g1.startTime > g2.startTime) {
            return 1;
        }
        return 0;
    };
};

export const isGamePassed = (game, numHours = 4) => {
    let gameStartTime = game.startTime.toDate().getTime();
    let timeNow = new Date().getTime();
    return gameStartTime + numHours*60*60*1000 < timeNow;
}

export const isGamePassedOrFinished = (game, numHours) => {
    return game.isFinished || isGamePassed(game, numHours);
}