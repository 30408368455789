import userReducer from "./user/user.reducer";
import {combineReducers} from "redux";
import gridReducer from "./grid/grid.reducers";
import gameReducer from "./game/game.reducers";

const rootReducer = combineReducers({
    user: userReducer,
    grid: gridReducer,
    game: gameReducer,
});

export default rootReducer;