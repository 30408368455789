import {deduplicateUsers} from "../user/user.utils";

export const MAX_FREE_PLAYERS = 5;
export const MAX_NUMBER_OF_PICKS_PER_USER = 20;

export const getGridGameName = (grid) => {
    if(!grid) return "";
    return `${grid.rowTeamName} at ${grid.columnTeamName}`;
};

function getUserPicksForUserFromGrid(grid, user) {
    let picks = grid.picks;
    return Object.keys(picks).map(key => picks[key]).filter(pick => (pick.email === user.email));
}

export const addSquareSelectionToGrid = (grid, squareId, user) => {
    const picks = grid.picks;
    if(picks[squareId]){
        return picks;
    }
    const newPicks = {};
    let userPicks = getUserPicksForUserFromGrid(grid, user);
    let displayName = userPicks.length > 0 ? userPicks[0].displayName : user.displayName;
    newPicks[`${squareId}`] = {
        id: squareId,
        userId: user.id ? user.id : null,
        displayName: displayName,
        email: user.email,
        isPaid: false
    };
    Object.keys(picks).forEach(key => newPicks[key] = picks[key]);
    return newPicks;
};

export const getPicksWithUpdatedPlayerName = (grid, player, newPlayerName) => {
    const picks = grid.picks;
    let newPicks = {};
    if(picks){
        Object.keys(picks).forEach(key => {
            let pick = picks[key];
            if(pick && (pick.email === player.email)){
                let newPick = {};
                Object.assign(newPick, pick);
                newPick.displayName = newPlayerName;
                newPicks[key] = newPick;
            }else{
                newPicks[key] = pick;
            }
        });
        return newPicks;
    }
    return picks;
};

export const getPlayersFromGrid = (grid) => {
    return deduplicateUsers(Object.keys(grid.picks).map(key => grid.picks[key]));
};

export const isEmailInvitedOrPlayer = (grid, userEmail) => {
    return ([...grid.players,...grid.invitedPlayers]
        .map(player => player.email)
        .filter(email => email !== undefined)
        .map(email => email.toLowerCase())
        .filter(email => email === userEmail.toLowerCase()).length > 0)
}

export const removeSquareSelectionFromGrid = (grid, squareId) => {
    const picks = grid.picks;
    // remove element using a reducer
    // return the new picks
    return Object.keys(picks).reduce((object, key) => {
        if (key !== squareId) {
            object[key] = picks[key]
        }
        return object
    }, {});
};

export const updateSquarePaidStatusInGrid = (grid, squareIds, isPaid) => {
    const picks = grid.picks;
    return Object.keys(picks).reduce((object, key) => {
        object[key] = picks[key];
        if (squareIds.includes(key)) {
            object[key].isPaid = isPaid;
        }
        return object
    }, {});
};

export const getNumberOfPaidSquare = (grid) => {
    if(!grid || !grid.picks){
        return 0;
    }
    return Object.values(grid.picks).reduce((numberOfPicks, pick) => {
        return (pick.isPaid) ? numberOfPicks + 1 : numberOfPicks
    }, 0);
};

export const getNumberOfPlayersFromGridPicks = (grid) => {
    if(!grid || !grid.picks){
        return 0;
    }
    let dedupedPlayers = deduplicateUsers(Object.values(grid.picks));
    return dedupedPlayers.length;
};

export const getNumberOfPicks = (grid) => {
    if(!grid || !grid.picks){
        return 0;
    }
    return Object.values(grid.picks).reduce((numberOfPicks, _) => {return numberOfPicks + 1}, 0);
};

export const getNumberOfPicksByUser = (grid, user) => {
    if(!grid || !grid.picks){
        return 0;
    }
    return Object.values(grid.picks).reduce((numberOfPicksByUser, pick) => {
        return pick.email === user.email ? numberOfPicksByUser + 1 : numberOfPicksByUser
    }, 0);
};

export const getNumberOfPaidPicksByUser = (grid, user) => {
    if(!grid || !grid.picks){
        return 0;
    }
    return Object.values(grid.picks).reduce((numberOfPicksByUser, pick) => {
        return (pick.email === user.email && pick.isPaid) ? numberOfPicksByUser + 1 : numberOfPicksByUser
    }, 0);
};

export const getAllPicksByUser = (grid, user) => {
    if(!grid || !grid.picks){
        return 0;
    }
    return Object.values(grid.picks).filter(pick => pick.email === user.email );
};

export const getPaidPicksByUser = (grid, user) => {
    if(!grid || !grid.picks){
        return [];
    }
    return Object.values(grid.picks).filter(pick => pick.email === user.email && pick.isPaid);
};

export const getUnpaidPicksByUser = (grid, user) => {
    if(!grid || !grid.picks){
        return [];
    }
    return Object.values(grid.picks).filter(pick => pick.email === user.email && !pick.isPaid);
};

export const lastDigitOfScore = (score) => {
    return [...`${score}`].pop();
};

export function getWinningQ1SquareId(grid) {
    let indexOfWinningQ1Row = grid.rowNumbers.indexOf(parseInt(lastDigitOfScore(grid.rowTeamScores[0])));
    let indexOfWinningQ1Column = grid.columnNumbers.indexOf(parseInt(lastDigitOfScore(grid.columnTeamScores[0])));
    return `${indexOfWinningQ1Row}${indexOfWinningQ1Column}`;
}

export function getWinningQ2SquareId(grid) {
    let indexOfWinningQ2Row = grid.rowNumbers.indexOf(parseInt(lastDigitOfScore(grid.rowTeamScores[0]+grid.rowTeamScores[1])));
    let indexOfWinningQ2Column = grid.columnNumbers.indexOf(parseInt(lastDigitOfScore(grid.columnTeamScores[0]+grid.columnTeamScores[1])));
    return `${indexOfWinningQ2Row}${indexOfWinningQ2Column}`;
}

export function getWinningQ3SquareId(grid) {
    let indexOfWinningQ3Row = grid.rowNumbers.indexOf(parseInt(lastDigitOfScore(grid.rowTeamScores[0]+grid.rowTeamScores[1]+grid.rowTeamScores[2])));
    let indexOfWinningQ3Column = grid.columnNumbers.indexOf(parseInt(lastDigitOfScore(grid.columnTeamScores[0]+grid.columnTeamScores[1]+grid.columnTeamScores[2])));
    return `${indexOfWinningQ3Row}${indexOfWinningQ3Column}`;
}

export function getWinningQ4SquareId(grid) {
    let indexOfWinningQ4Row = grid.rowNumbers.indexOf(parseInt(lastDigitOfScore(grid.rowTeamScores[0]+grid.rowTeamScores[1]+grid.rowTeamScores[2]+grid.rowTeamScores[3])));
    let indexOfWinningQ4Column = grid.columnNumbers.indexOf(parseInt(lastDigitOfScore(grid.columnTeamScores[0]+grid.columnTeamScores[1]+grid.columnTeamScores[2]+grid.columnTeamScores[3])));

    return `${indexOfWinningQ4Row}${indexOfWinningQ4Column}`;
}

export const oneToTen = [1,2,3,4,5,6,7,8,9,10];
export const zeroToNine = [0,1,2,3,4,5,6,7,8,9];

export const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};
