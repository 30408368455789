import React, {useEffect, useState} from "react";
import FormInput from "../form-input/form-input.component";

import './sign-in.styles.scss';
import CustomButton from "../custom-button/custom-button.component";
import {connect} from "react-redux";
import {emailSignInStart, googleSignInStart} from "../../redux/user/user.actions";
import {createStructuredSelector} from "reselect";
import {selectUserError} from "../../redux/user/user.selector";

const SignIn = ({userError, googleSignInStart, emailSignInStart}) => {

    const [ userCredentials, setUserCredentials ] = useState({
        email: '',
        password: '',
    });

    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if(userError!=null){
            setErrorMessage( "Error: could not login with the credentials provided.");
        }else{
            setErrorMessage('');
        }
    }, [userError]);

    const {email, password} = userCredentials;

    const handleSubmit = async (event) => {
        event.preventDefault();
        emailSignInStart(email, password);
        setUserCredentials({email: '', password: ''})
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserCredentials({ ...userCredentials, [name]: value});
    };

    return (
        <div className="sign-in">
            <h2>I already have an account</h2>
            <span>Sign in using your email and password</span>
            <div className="sign-in-form">
                <form onSubmit={handleSubmit}>
                    <FormInput label="email" type="email" name="email" value={email} handleChange={handleChange}/>
                    <FormInput label="password" type="password" name="password" value={password} handleChange={handleChange}/>

                    {
                        errorMessage.trim().length > 0 ? (<div className={"alert alert-danger"}><span className="closebtn" onClick={() => setErrorMessage('')}>&times;</span>{errorMessage}</div>) : ''
                    }

                    <div className="buttons">
                        <CustomButton type="submit">
                            Sign in
                        </CustomButton>
                        <div style={{'align-self': 'center'}}>OR</div>
                        <CustomButton type='button' onClick={googleSignInStart} isGoogleSignIn>
                            Sign in with Google
                        </CustomButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    userError: selectUserError,
    }
);

const mapDispatchToProps = dispatch => ({
    googleSignInStart: () => dispatch(googleSignInStart()),
    emailSignInStart: (email, password) => dispatch(emailSignInStart({email, password})),
});
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);