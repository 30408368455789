const GridActionTypes = {
    UPDATE_GRID: 'UPDATE_GRID',
    // UPDATE_GRID_SUCCESS: 'UPDATE_GRID_SUCCESS',
    // UPDATE_GRID_FAILURE: 'UPDATE_GRID_FAILURE',
    UPDATE_GRID_PLAYERS: 'UPDATE_GRID_PLAYERS',
    SELECT_RANDOM_ROW_AND_COLUMN_NUMBERS: 'SELECT_RANDOM_ROW_AND_COLUMN_NUMBERS',
    SELECT_RANDOM_ROW_AND_COLUMN_NUMBERS_SUCCESS: 'SELECT_RANDOM_ROW_AND_COLUMN_NUMBERS_SUCCESS',
    SELECT_RANDOM_ROW_AND_COLUMN_NUMBERS_FAILURE: 'SELECT_RANDOM_ROW_AND_COLUMN_NUMBERS_FAILURE',
    SET_SELECTED_ROW_AND_COLUMN_NUMBERS: 'SET_SELECTED_ROW_AND_COLUMN_NUMBERS',
    SET_SELECTED_ROW_AND_COLUMN_NUMBERS_SUCCESS: 'SET_SELECTED_ROW_AND_COLUMN_NUMBERS_SUCCESS',
    SET_SELECTED_ROW_AND_COLUMN_NUMBERS_FAILURE: 'SET_SELECTED_ROW_AND_COLUMN_NUMBERS_FAILURE',
    START_GAME: 'START_GAME',
    START_GAME_SUCCESS: 'START_GAME_SUCCESS',
    START_GAME_FAILURE: 'START_GAME_FAILURE',
    END_GAME: 'END_GAME',
    END_GAME_SUCCESS: 'END_GAME_SUCCESS',
    END_GAME_FAILURE: 'END_GAME_FAILURE',
    START_NEXT_QUARTER: 'START_NEXT_QUARTER',
    START_NEXT_QUARTER_SUCCESS: 'START_NEXT_QUARTER_SUCCESS',
    START_NEXT_QUARTER_FAILURE: 'START_NEXT_QUARTER_FAILURE',
    UPDATE_SCORE: 'UPDATE_SCORE',
    UPDATE_SCORE_SUCCESS: 'UPDATE_SCORE_SUCCESS',
    UPDATE_SCORE_FAILURE: 'UPDATE_SCORE_FAILURE',
    SET_GRID_SCORES: 'SET_GRID_SCORES',
    SET_GRID_SCORES_SUCCESS: 'SET_GRID_SCORES_SUCCESS',
    SET_GRID_SCORES_FAILURE: 'SET_GRID_SCORES_FAILURE',
    UPDATE_USER_GRIDS: 'UPDATE_USER_GRIDS',
    UPDATE_FEATURED_GRIDS: 'UPDATE_FEATURED_GRIDS',
    SELECT_SQUARE: 'SELECT_SQUARE',
    USER_SELECTS_GRID_SQUARE: 'USER_SELECTS_GRID_SQUARE',
    USER_SELECTS_GRID_SQUARE_SUCCESS: 'USER_SELECTS_GRID_SQUARE_SUCCESS',
    USER_SELECTS_GRID_SQUARE_FAILURE: 'USER_SELECTS_GRID_SQUARE_FAILURE',
    USER_REMOVES_GRID_SQUARE: 'USER_REMOVES_GRID_SQUARE',
    USER_REMOVES_GRID_SQUARE_SUCCESS: 'USER_REMOVES_GRID_SQUARE_SUCCESS',
    USER_REMOVES_GRID_SQUARE_FAILURE: 'USER_REMOVES_GRID_SQUARE_FAILURE',
    CHANGE_GRID_SQUARE_PAID_STATUS: 'CHANGE_GRID_SQUARE_PAID_STATUS',
    CHANGE_GRID_SQUARE_PAID_STATUS_SUCCESS: 'CHANGE_GRID_SQUARE_PAID_STATUS_SUCCESS',
    CHANGE_GRID_SQUARE_PAID_STATUS_FAILURE: 'CHANGE_GRID_SQUARE_PAID_STATUS_FAILURE',
    CHANGE_ALL_PLAYER_SQUARES_PAID_STATUS: 'CHANGE_ALL_PLAYER_SQUARES_PAID_STATUS',
    CHANGE_ALL_PLAYER_SQUARES_PAID_STATUS_SUCCESS: 'CHANGE_ALL_PLAYER_SQUARES_PAID_STATUS_SUCCESS',
    CHANGE_ALL_PLAYER_SQUARES_PAID_STATUS_FAILURE: 'CHANGE_ALL_PLAYER_SQUARES_PAID_STATUS_FAILURE',
    FETCH_GRID_START: 'FETCH_GRID_START',
    FETCH_GRID_SUCCESS: 'FETCH_GRID_SUCCESS',
    FETCH_GRID_FAILURE: 'FETCH_GRID_FAILURE',
    UPDATE_GRID_PLAYER_NAME: 'UPDATE_GRID_PLAYER_NAME',
    UPDATE_GRID_PLAYER_NAME_SUCCESS: 'UPDATE_GRID_PLAYER_NAME_SUCCESS',
    UPDATE_GRID_PLAYER_NAME_FAILURE: 'UPDATE_GRID_PLAYER_NAME_FAILURE',
};

export default GridActionTypes;