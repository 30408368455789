import React from "react";

import './form-select.styles.scss';

const FormSelect = ({handleChange, label, options, ...otherProps}) => {

    return (
        <div className="group">
            <select className="form-select" onChange={handleChange} {...otherProps} >\
                {options.map((option) => {return (<option key={option.name} value={option.value}>{option.name}</option>)})}
            </select>
            {
                label ? (<label className='shrink form-select-label'>{label}</label>): null
            }
        </div>
    );
};

export default FormSelect;