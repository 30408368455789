import React from "react";
import {createStructuredSelector} from "reselect";
import {selectCurrentUser} from "../../redux/user/user.selector";
import {connect} from "react-redux";
import AccountSettings from "../../component/account-settings/account-settings.component";

import './my-account.styles.scss';
import TagManager from "react-gtm-module";

const tagManagerArgs = {
    dataLayer: {
        userId: '001',
        userProject: 'project',
        page: 'my-account'
    },
    dataLayerName: 'PageDataLayer'
}
const MyAccountPage = ({currentUser}) => {
    TagManager.dataLayer(tagManagerArgs);

    return (
        <div className="my-account-content">
            <AccountSettings currentUser={currentUser}/>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(MyAccountPage)
